import * as React from "react";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

export default function HorizontalLinearStepper({
  activeStep,
  isStepSkipped,
  steps,
}) {
  const isMobileScreen = window.screen.width < 546;
  return (
    <Box
      style={{ maxWidth: "600px", margin: "1rem auto" }}
      sm={{ width: "40%" }}
    >
      <Stepper
        activeStep={activeStep}
        variant="outlined"
        orientation={isMobileScreen ? "vertical" : "horizontal"}
        style={{ backgroundColor: "#fafafa", borderRadius: "4px" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
