import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { validateSignupForm } from "../../Utils";
import styled from "styled-components";
import CustomButton from "../UI/Button";
import { COLORS } from "../../assets/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import api from "../../services/api";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import configMerged from "../../config";

function SignUp({ submitRegisterForm, isLoading, signupError }) {
  const recaptchaRef = React.createRef();
  const maxDigits = 11;
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    selectedGender: "",
    dob: null,
    showPassword: false,
  });

  let errorsObj = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    dob: "",
    selectedGender: "",
  };

  const [error, setError] = useState(errorsObj);

  React.useEffect(() => {
    if (signupError) {
      recaptchaRef.current.reset();
      setRecaptchaToken("");
    }
  }, [signupError]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!recaptchaToken) {
        toast.info("Please complete the reCAPTCHA challange");
        return;
      }

      const signupErrorObj = validateSignupForm(state);
      setError(signupErrorObj.errorObj);
      if (!signupErrorObj.hasError) {
        const response = await api.post("/auth/verify-recaptcha", {
          token: recaptchaToken,
        });
        if (response.data.success) {
          submitRegisterForm(state);
        } else {
          toast.error("reCAPTCHA verification failed. Please try again.");
          setRecaptchaToken("");
          recaptchaRef.current.reset();
        }
      }
    } catch (e) {
      toast.error("Something went wrong");
      setRecaptchaToken("");
      recaptchaRef.current.reset();
    }
  };

  const handleMobileNum = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= maxDigits) {
      setState({
        ...state,
        mobileNumber: input,
      });
    }
  };
  const handlePasswordVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };
  const handleOnchange = (value) => {
    setRecaptchaToken(value);
  };
  return (
    <MainContainer>
      <LogoContainer>
        <h2 style={{ color: "#3d485d" }}>Create a new account</h2>
      </LogoContainer>
      <FormContainer>
        <form onSubmit={onSubmit}>
          <Box display="flex">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="filled-first-name-input"
                  label="First name"
                  type="text"
                  name="firstName"
                  value={state.firstName}
                  fullWidth
                  autoComplete="current-first-name"
                  variant="outlined"
                  required
                  onChange={(e) =>
                    setState({
                      ...state,
                      firstName: e.target.value,
                    })
                  }
                  helperText={error.firstName}
                  error={error.firstName != ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="filled-last-name-input"
                  label="Last name"
                  type="text"
                  fullWidth
                  value={state.lastName}
                  autoComplete="current-last-name"
                  variant="outlined"
                  required
                  onChange={(e) =>
                    setState({
                      ...state,
                      lastName: e.target.value,
                    })
                  }
                  helperText={error.lastName}
                  error={error.lastName != ""}
                />
              </Grid>
            </Grid>
          </Box>
          <TextField
            label="Mobile number"
            placeholder="03452149821"
            fullWidth
            variant="outlined"
            required
            value={state.mobileNumber}
            inputProps={{ maxLength: maxDigits }}
            onChange={(e) => handleMobileNum(e)}
            helperText={error.mobileNumber}
            error={error.mobileNumber != ""}
          />
          <TextField
            id="filled-last-name-input"
            label="Email"
            required
            type="email"
            fullWidth
            autoComplete="current-last-name"
            variant="outlined"
            onChange={(e) =>
              setState({
                ...state,
                email: e.target.value,
              })
            }
            error={error.email != ""}
            helperText={error.email}
          />
          <TextField
            id="filled-password-input"
            label="Password"
            type={state.showPassword ? "text" : "password"}
            fullWidth
            autoComplete="current-password"
            variant="outlined"
            required
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
            error={error.password != ""}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="filled-password-input"
            label="Confirm password"
            type="password"
            fullWidth
            autoComplete="current-password"
            variant="outlined"
            required
            onChange={(e) =>
              setState({
                ...state,
                confirmPassword: e.target.value,
              })
            }
            helperText={error.confirmPassword}
            error={error.confirmPassword}
          />
          <div className="dobcontainer">
            <DatePicker
              className="datepicker"
              selected={state.dob}
              onChange={(date) =>
                setState({
                  ...state,
                  dob: date,
                })
              }
              placeholderText={"DOB e.g 1994-12-15"}
              isClearable
              showYearDropdown
              useShortMonthInDropdown
            />
            {error.dob && <p>{error.dob}</p>}
          </div>
          <div className="radioContainer">
            <p>Gender:</p>
            <RadioLabel>
              <RadioInput
                type="radio"
                name="gender"
                value="male"
                checked={state.selectedGender === "male"}
                onChange={(e) =>
                  setState({
                    ...state,
                    selectedGender: e.target.value,
                  })
                }
              />
              Male
            </RadioLabel>

            <RadioLabel>
              <RadioInput
                type="radio"
                name="gender"
                value="female"
                checked={state.selectedGender === "female"}
                onChange={(e) =>
                  setState({
                    ...state,
                    selectedGender: e.target.value,
                  })
                }
              />
              Female
            </RadioLabel>
            {error.selectedGender && (
              <p className="error">{error.selectedGender}</p>
            )}
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={configMerged.recaptchaKey}
            onChange={handleOnchange}
          />

          <BtnContainer>
            <CustomButton
              label={isLoading ? "Please wait" : "Register"}
              type="submit"
            />
          </BtnContainer>
          {signupError && signupError.length && (
            <Alert severity="error" style={{ marginTop: "0.5rem" }}>
              {signupError}
            </Alert>
          )}
          <LinkContainer>
            <Link
              to="/login"
              style={{
                fontSize: "1rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
            >
              Already have an account? <span>Sign in</span>
            </Link>
            <p>
              By creating an account you agree to our{" "}
              <Link to="/terms-conditions">Terms & Conditions</Link> and
              <Link to="/privacy-policy"> Privacy policy </Link>
            </p>
          </LinkContainer>
        </form>
      </FormContainer>
    </MainContainer>
  );
}

export default SignUp;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
  p {
    text-align: center;
    font-weight: 200;
    font-size: 14px;
    color: gray;
    text-decoration: none;

    a {
      color: ${COLORS.linkColor};
      cursor: pointer;
    }
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  > form > .MuiTextField-root {
    text-align: center;
    color: white;
    margin-bottom: 18px;
  }
  > form > .MuiBox-root {
    margin-bottom: 10px;
    > .MuiTextField-root {
      padding-right: 5px;
    }
  }
  .radioContainer {
   display:flex;
   margin: 1rem 0;
   label {
    cursor: pointer;
    input {
      cursor: pointer;
    }
   }
   .error {
      color:${COLORS.errorColor};
      padding: 5px 0;
      text-align: left;
  }
   p{
    margin-right:1rem;
   }
  }
  .dobcontainer{
    p{
      color:red;
    }

    .datepicker{
      width:100%;
      height: 3rem;
      padding: 0 8px;
      font-size: 16px;
      border: 1px solid lightgray;
      border-radius: 5px;
    }
    .react-datepicker__navigation--next {
      background-color: #054752;
      color: #054752;
    }
    .react-datepicker__navigation--previous {
      background-color: #054752;
      color: #054752;
    }
    .react-datepicker__year-read-view--down-arrow {
      color: #054752;
    }
  
    .datepicker-dropdown::after {
      content: "\f078";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none; 
      font-family: FontAwesome; 
      font-size: 14px;
    }
    .react-datepicker__close-icon::after {
      cursor: pointer;
      background-color: #054752;
      color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 2px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: "×";
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      border-radius: 50%;
      background-color: #054752;
      color: #fff;
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      border-radius: 50%;
      background-color: #054752;
      color: #fff;
    }
 
    .error {
      color:${COLORS.errorColor}
      padding: 5px 0;
      text-align: left;
    }
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  min-height: 50px;
  > a {
    text-decoration: none;
    color: grey;
    span {
      color: ${COLORS.linkColor};
    }
    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin-top: 5px;
  }
`;

const BtnContainer = styled.div`
  margin-top: 10px;
`;
const ErrorText = styled.p`
  color: red !important;
  margin-top: 15px;
`;
const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const RadioInput = styled.input`
  margin-right: 16px;
  margin-top: -6px;

  &:checked {
    &::before {
      background-color: ${COLORS.primaryColor};
    }
  }

  &:disabled {
    &::before {
      background-color: lightgray;
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${COLORS.primaryColor};
    background-color: white;
    transition: background-color 0.2s ease-in-out;
  }
`;
