import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomerTable from "./CustomerTable";
import { IconButton } from "@material-ui/core";
import { CloudDownloadOutlined, RefreshRounded } from "@material-ui/icons";
import api from "../../../services/api";
import CircularLoader from "../../UI/Loader";
import { Pagination } from "@material-ui/lab";
function Customers() {
  const [customers, setCustomers] = useState(null);
  const [customersCount, setCustomersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const limit = 5;
  const offset = (page - 1) * pageSize;
  useEffect(() => {
    async function fetchUsers() {
      try {
        setLoading(true);
        const response = await api.get("admin/user/", {
          params: { limit, offset },
        });
        if (response.status == 200) {
          setLoading(false);
          setCustomers(response.data.data);
          setCustomersCount(response.data.count);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    fetchUsers();
  }, [page]);
  useEffect(() => {
    if (customersCount > 0) {
      setPages(Math.ceil(customersCount / pageSize));
    }
  }, [customersCount]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <CustomerWrapper>
      <div className="customer-nav">
        <Nav>
          <NavContainer>
            <NavLogo>
              <Typography>Customer Details</Typography>
            </NavLogo>
            <NavMenu>
              <NavMenuItem>
                <IconButton>
                  <RefreshRounded style={{ color: "white" }} />
                </IconButton>
              </NavMenuItem>
              <IconButton>
                <CloudDownloadOutlined style={{ color: "white" }} />
              </IconButton>
            </NavMenu>
          </NavContainer>
        </Nav>
      </div>
      <div className="customer">
        {loading ? (
          <CircularLoader isLoading={loading} />
        ) : (
          <CustomerTable customers={customers} />
        )}
      </div>
      <div className="pagination">
        {pages > 1 && customers?.length >= 1 && (
          <Pagination
            onChange={handleChange}
            size="large"
            page={page}
            count={pages}
            color="primary"
          />
        )}
      </div>
    </CustomerWrapper>
  );
}

export default Customers;
const CustomerWrapper = styled.div`
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 4px;
  height: auto;
  .customer {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .pagination {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    padding: 2rem;
    margin-bottom: 4rem;
  }
`;
const Nav = styled.nav`
  color: #fff;
  padding: 1rem;
  color: white;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgb(7 30 28) 0%,
    rgba(5, 71, 82, 1) 60%,
    rgb(23 104 120) 100%
  );
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavLogo = styled.div`
  font-size: 2.5rem;
  color: #054752;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 2rem;
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
`;

const NavMenuItem = styled.li`
  font-size: 1.5rem;
  color: #054752;
`;
const Typography = styled.div`
  font-family: sans-serif;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
`;
