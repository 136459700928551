import React from "react";
import styled from "styled-components";
import Setting from "../../components/Setting";
import { useSelector } from "react-redux";

function Settings() {
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <PageContainer>
      {currentUser.id && <Setting currentUser={currentUser} />}
    </PageContainer>
  );
}

export default Settings;
const PageContainer = styled.div`
  height: 100vh;
`;
