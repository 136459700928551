import React, { useState } from "react";
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";

import { COLORS } from "../../../assets/colors";
import {
  FaUtensils,
  FaPlus,
  FaMinus,
  FaCalendarAlt,
  FaEdit,
  FaCalendarCheck,
} from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import GroupSuccessPage from "../../../pages/GroupSuccessPage";

const TourPlan = ({ formData, handleChange }) => {
  const [tourPlan, setTourPlan] = useState([
    {
      day: 1,
      activities: "",
      accommodation: "",
      transportation: "",
      foodArrangements: "",
    },
  ]);
  const navigate = useNavigate(); // Initialize useHistory

  const handleInputChange = (index, name, value) => {
    const updatedTourPlan = [...tourPlan];
    updatedTourPlan[index][name] = value;
    setTourPlan(updatedTourPlan);
  };

  const handleAddDay = () => {
    setTourPlan([
      ...tourPlan,
      {
        day: tourPlan.length + 1,
        activities: "",
        accommodation: "",
        transportation: "",
        foodArrangements: "",
      },
    ]);
  };

  const handleRemoveDay = (index) => {
    const updatedTourPlan = tourPlan.filter((_, i) => i !== index);
    setTourPlan(updatedTourPlan.map((day, i) => ({ ...day, day: i + 1 })));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Simulate form submission success
    setTimeout(() => {
      // Redirect to SuccessPage after a delay (simulating verification process)
      navigate("/group-tour/publish");
    }, 1500);
  };

  return (
    <Wrapper>
      <div className="title">
        <h2>Tourism Plan</h2>
        <p>Please provide your tour plan details</p>
      </div>

      <FormGroup>
        <Label>
          <FaEdit /> Tour Title
        </Label>
        <Input
          placeholder="Tour Title"
          type="text"
          value={formData.tourTitle}
          onChange={handleChange("tourTitle")}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <FaEdit /> Tour description
        </Label>
        <StyledTextArea
          rows={10}
          placeholder="Tour Description"
          type="text"
          value={formData.tourDescription}
          onChange={handleChange("tourDescription")}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <FaCalendarAlt /> Start Date
        </Label>
        <Input
          type="date"
          value={formData.startDate}
          onChange={handleChange("startDate")}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <FaCalendarAlt /> End Date
        </Label>
        <Input
          type="date"
          value={formData.endDate}
          onChange={handleChange("endDate")}
        />
      </FormGroup>

      {tourPlan.map((day, index) => (
        <DayPlan key={index}>
          <h2>Day {day.day}</h2>
          <FormGroup>
            <Label>Activities</Label>
            <RichTextEditor
              value={day.activities}
              onChange={(value) =>
                handleInputChange(index, "activities", value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Accommodation</Label>
            <RichTextEditor
              value={day.accommodation}
              onChange={(value) =>
                handleInputChange(index, "accommodation", value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Transportation</Label>
            <RichTextEditor
              value={day.transportation}
              onChange={(value) =>
                handleInputChange(index, "transportation", value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Food Arrangements</Label>
            <RichTextEditor
              value={day.foodArrangements}
              onChange={(value) =>
                handleInputChange(index, "foodArrangements", value)
              }
            />
          </FormGroup>
          <RemoveButton type="button" onClick={() => handleRemoveDay(index)}>
            <FaMinus /> Remove Day
          </RemoveButton>
        </DayPlan>
      ))}
      <div className="add-day">
        <AddDayButton type="button" onClick={handleAddDay}>
          <FaPlus /> Add Day
        </AddDayButton>
        <SubmitButton type="submit" onClick={handleSubmit}>
          <FaCalendarCheck /> Submit for review
        </SubmitButton>
      </div>
    </Wrapper>
  );
};

export default TourPlan;

const StyledTextArea = styled.textarea`
  width: 100%;
  border: 1px solid gainsboro;
  padding: 1rem;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  color: #3d485d;
  font-family: "Arial", sans-serif;

  .add-day {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 24px;
      color: ${COLORS.primaryColor};
    }

    p {
      margin: 5px 0 0;
      font-size: 14px;
      color: gray;
    }
  }
`;

const DayPlan = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: ${COLORS.primaryColor};
  }
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${COLORS.primaryColor};

  svg {
    margin-right: 8px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid lightgray;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${COLORS.primaryColor};
  }
`;

const RichTextEditor = styled(ReactQuill)`
  width: 100%;
  min-height: 150px; /* Adjust height as needed */
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-top: 5px;
  padding: 10px;
  font-size: 16px;

  .ql-editor {
    min-height: 100px; /* Adjust editor height */
  }
`;

const AddDayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: #218838;
  }
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  padding: 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: #c82333;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: ${COLORS.primaryColor};
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${COLORS.primaryDarkColor};
  }
`;
