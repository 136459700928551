import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../cards";
import HistogramChart from "../AdminSider/chart";
import EmployeeCard from "../employeeCard";
import api from "../../../services/api";
import CircularLoader from "../../UI/Loader";
import Chart from "./Chart";
import PieChart from "./PieChart";
import ColumnChart from "./BarChart";
import BookingsChart from "./BookingsChart";
import ExpiredBookingsChart from "./BookingRequestsChart";
import BookingRequestsChart from "./BookingRequestsChart";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchDashboardData() {
      try {
        setLoading(true);
        const response = await api.get("admin/dashboard/");
        if (response.status == 200) {
          setLoading(false);
          setDashboardData(response.data);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    fetchDashboardData();
  }, []);
  console.log("Dashboard data", dashboardData);
  if (loading) {
    return <CircularLoader isLoading={loading} />;
  }
  return (
    <DashboardWrapper>
      <div className="card">
        <Card
          title="Total Customers"
          content={dashboardData.users}
          descriptions="Active customers"
          styles={{ background: "#4ec74e", color: "white" }}
        />
        <Card
          title="Total Rides"
          content={dashboardData.rides}
          descriptions="rides complete"
          styles={{ background: "aliceblue", color: "#232323" }}
        />{" "}
        <Card
          title="Drivers"
          content={dashboardData.drivers}
          descriptions=" Drivers published rides"
          styles={{ background: "aliceblue", color: "#232323" }}
        />
        <Card
          title="Passengers"
          content={dashboardData.passangers}
          descriptions="Travellers"
          styles={{ background: "aliceblue", color: "#232323" }}
        />
        <Card
          title="Bookings"
          content={dashboardData.bookings}
          descriptions="Total Bookings"
          styles={{ background: "aliceblue", color: "#232323" }}
        />
        <Card
          title="Vehicles"
          content={dashboardData.vehicles}
          descriptions=" Added vehicles"
          styles={{ background: "aliceblue", color: "#232323" }}
        />
        <Card
          title="Refunds"
          content={dashboardData.refunds}
          descriptions=" Refund items"
          styles={{ background: "aliceblue", color: "#232323" }}
        />
        <Card
          title="Verifications"
          content={dashboardData.verificationsCount}
          descriptions="Verification Requests"
          styles={{ background: "aliceblue", color: "#232323" }}
        />
        <Card
          title="Banned Users"
          content={dashboardData.banned}
          descriptions="Banned users"
          styles={{ background: "orange", color: "white" }}
        />
        <Card
          title="Reports"
          content={dashboardData.reports}
          descriptions=" received reports"
          styles={{ background: "firebrick", color: "white" }}
        />
        <Card
          title="Earnings"
          content={`Rs.${dashboardData.totalEarnings}.00`}
          descriptions=" Total earnings"
          styles={{ background: "green", color: "white" }}
        />
      </div>

      <div class="chart-container">
        <h3> Rides</h3>

        <div className="chart-bar">
          <ColumnChart />
        </div>
        <h3> Users</h3>
        <div className="chart-item">
          <Chart />{" "}
        </div>
        <h3> Bookings</h3>
        <div className="chart-item">
          <BookingsChart />
        </div>
        <h3> Requests</h3>
        <div className="chart-item">
          <BookingRequestsChart />
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Dashboard;
const DashboardWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  gap: 2rem;
  .card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .chart-container {
    height:auto;
    width: 900px;
    display: flex;
    flex-direction column;
    .chart-item{
      height: 400px;
      width: auto;
      display: flex;
    }
    .chart-bar{
      margin-top:1rem;
      height: 400px;
      width: auto;
      display: flex;
    }
    h3 {
      margin-top:1rem;
    }
  }
`;
