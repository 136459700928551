import React from 'react'
import Forget from '../../components/Forget'
import PageContainer from '../../components/UI/PageContainer'

const ForgetPassword = () => {
    
    return (
        <PageContainer>
            <Forget />
        </PageContainer>
    )
}

export default ForgetPassword
