// import React from "react";
// import styled from "styled-components";
// import Accordion from "./Accordion";
// import IncludeAccordion from "./IncludeAccordion";
// import ImageCard from "./Gallery/index";
// import ToursTermsAndConditions from "./ToursTermsAndConditions";

// const images = [
//   {
//     id: 1,
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
//   },
//   {
//     id: 2,
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
//   },
//   {
//     id: 3,
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
//   },
//   {
//     id: 4,
//     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
//   },
// ];
// function GroupInformation() {
//   return (
//     <Wrapper>
//       <div className="left-section">
//         <div className="left-header">
//           <div className="header-content">
//             <h3> Tour operator :</h3>
//             <p>Exploria </p>
//           </div>
//           <div className="header-content">
//             <h3> age range :</h3>
//             <p>35 to 60 </p>
//           </div>
//           <div className="header-content">
//             <h3> tour id :</h3>
//             <p>277330 </p>
//           </div>
//           <div className="header-content">
//             <h3> max group size :</h3>
//             <p>12 </p>
//           </div>
//           <div className="header-content">
//             <h3> operated in :</h3>
//             <p>english </p>
//           </div>
//           <div className="header-content">
//             <h3> personalize :</h3>
//             <p>Request a quote </p>
//           </div>
//         </div>
//         <div className="left-bottom">
//           <div className="bottom-itinerary">
//             <h4>Itinerary</h4>
//             <p>
//               Start and end in Islamabad! With the Explorer tour Explore Hunza
//               Valley Pakistan 2024/25, you have a 7 days tour package taking you
//               through Islamabad, Pakistan and 2 other destinations in Pakistan.
//               Explore Hunza Valley Pakistan 2024/25 includes accommodation in a
//               hotel as well as flights, an expert guide, meals, transport and
//               more
//             </p>
//           </div>
//         </div>
//         <div className="left-accordion">
//           <Accordion />
//         </div>
//         <div className="include-section">
//           <IncludeAccordion />
//         </div>
//         <TermsWrapper>
//           <Title>Cancellation and Refunding Policy</Title>
//           <Subtitle>
//             Please review our cancellation and refunding policies
//           </Subtitle>
//           <PolicySection>
//             <PolicyTitle>1. Cancellation by Member</PolicyTitle>
//             <PolicyText>
//               If a member wishes to cancel their tour, they must notify us at
//               least 14 days prior to the departure date to receive a full
//               refund. Cancellations made within 14 days will incur a 50%
//               cancellation fee. No refunds will be provided for cancellations
//               made within 7 days of the departure date.
//             </PolicyText>
//           </PolicySection>
//           <PolicySection>
//             <PolicyTitle>2. Cancellation by Company</PolicyTitle>
//             <PolicyText>
//               In the unlikely event that the tour is cancelled by the company,
//               members will receive a full refund or the option to reschedule for
//               a future date.
//             </PolicyText>
//           </PolicySection>
//           <PolicySection>
//             <PolicyTitle>3. Refund Process</PolicyTitle>
//             <PolicyText>
//               Refunds will be processed within 10 business days of receiving the
//               cancellation request. Refunds will be issued to the original
//               payment method.
//             </PolicyText>
//           </PolicySection>
//           <PolicySection>
//             <PolicyTitle>4. Non-Refundable Items</PolicyTitle>
//             <PolicyText>
//               Any additional services such as travel insurance, visa fees, or
//               other personal expenses are non-refundable.
//             </PolicyText>
//           </PolicySection>
//         </TermsWrapper>
//         <div className="terms">
//           <ToursTermsAndConditions />
//         </div>
//       </div>
//       <div className="right-section">
//         {images.map((image) => (
//           <ImageCard key={image.id} src={image.src} alt={image.alt} />
//         ))}
//       </div>
//     </Wrapper>
//   );
// }

// export default GroupInformation;

// const Wrapper = styled.div`
//   padding: 1rem;
//   height: auto;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   gap: 1rem;
//   .left-section {
//     width: 50%;
//     height: auto;
//     gap: 2.28rem;
//     display: flex;
//     flex-direction: column;
//     .left-header {
//       display: flex;
//       gap: 2rem;
//       flex-wrap: wrap;
//       justify-content: start;
//       max-width: 39rem;
//       .header-content {
//         display: flex;
//         flex-direction: column;
//         gap: 0.3rem;
//         h3 {
//           font-size: 1rem;
//           font-weight: 700;
//           text-transform: capitalize;
//         }
//         p {
//           font-size: 14px;
//           text-transform: capitalize;
//         }
//       }
//     }
//     .left-bottom {
//       display: flex;
//       flex-direction: column;
//       gap: 1.5rem;

//       .bottom-itinerary {
//         display: flex;
//         flex-direction: column;
//         gap: 1.5rem;
//         h4 {
//           font-size: 2rem;
//           font-weight: 700;
//           text-transform: capitalize;
//           letter-spacing: 1.2px;
//         }
//         p {
//           font-size: 14px;
//           text-transform: capitalize;
//           color: gray;
//         }
//       }
//     }
//     .left-accordion {
//     }
//     .nclude-section {
//     }
//   }

//   .right-section {
//     width: 45%;
//     height: fit-content;
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1rem;
//     justify-content: flex-end;
//   }
// `;
// ///////
// const TermsWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 2rem;
//   background-color: #f9f9f9;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;
// const Title = styled.h2`
//   font-size: 24px;
//   margin-bottom: 1rem;
//   color: #333;
// `;

// const Subtitle = styled.p`
//   font-size: 16px;
//   margin-bottom: 2rem;
//   color: #666;
// `;

// const PolicySection = styled.div`
//   margin-bottom: 1.5rem;
// `;

// const PolicyTitle = styled.h3`
//   font-size: 18px;
//   margin-bottom: 0.5rem;
//   color: #444;
// `;

// const PolicyText = styled.p`
//   font-size: 14px;
//   color: #555;
//   line-height: 1.5;
// `;

import React from "react";
import styled from "styled-components";
import Accordion from "./Accordion";
import IncludeAccordion from "./IncludeAccordion";
import ImageCard from "./Gallery/index";
import ToursTermsAndConditions from "./ToursTermsAndConditions";

const images = [
  {
    id: 1,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    id: 2,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    id: 3,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    id: 4,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
];

function GroupInformation() {
  return (
    <Wrapper>
      <LeftSection>
        <LeftHeader>
          <HeaderContent>
            <h3>Tour operator:</h3>
            <p>Exploria</p>
          </HeaderContent>
          <HeaderContent>
            <h3>Age range:</h3>
            <p>35 to 60</p>
          </HeaderContent>
          <HeaderContent>
            <h3>Tour ID:</h3>
            <p>277330</p>
          </HeaderContent>
          <HeaderContent>
            <h3>Max group size:</h3>
            <p>12</p>
          </HeaderContent>
          <HeaderContent>
            <h3>Operated in:</h3>
            <p>English</p>
          </HeaderContent>
          <HeaderContent>
            <h3>Personalize:</h3>
            <p>Request a quote</p>
          </HeaderContent>
        </LeftHeader>

        <LeftBottom>
          <BottomItinerary>
            <h4>Itinerary</h4>
            <p>
              Start and end in Islamabad! With the Explorer tour Explore Hunza
              Valley Pakistan 2024/25, you have a 7 days tour package taking you
              through Islamabad, Pakistan and 2 other destinations in Pakistan.
              Explore Hunza Valley Pakistan 2024/25 includes accommodation in a
              hotel as well as flights, an expert guide, meals, transport and
              more
            </p>
          </BottomItinerary>
        </LeftBottom>

        <LeftAccordion>
          <Accordion />
        </LeftAccordion>

        <IncludeSection>
          <IncludeAccordion />
        </IncludeSection>

        <TermsWrapper>
          <Title>Cancellation and Refunding Policy</Title>
          <Subtitle>
            Please review our cancellation and refunding policies
          </Subtitle>
          <PolicySection>
            <PolicyTitle>1. Cancellation by Member</PolicyTitle>
            <PolicyText>
              If a member wishes to cancel their tour, they must notify us at
              least 14 days prior to the departure date to receive a full
              refund. Cancellations made within 14 days will incur a 50%
              cancellation fee. No refunds will be provided for cancellations
              made within 7 days of the departure date.
            </PolicyText>
          </PolicySection>
          <PolicySection>
            <PolicyTitle>2. Cancellation by Company</PolicyTitle>
            <PolicyText>
              In the unlikely event that the tour is cancelled by the company,
              members will receive a full refund or the option to reschedule for
              a future date.
            </PolicyText>
          </PolicySection>
          <PolicySection>
            <PolicyTitle>3. Refund Process</PolicyTitle>
            <PolicyText>
              Refunds will be processed within 10 business days of receiving the
              cancellation request. Refunds will be issued to the original
              payment method.
            </PolicyText>
          </PolicySection>
          <PolicySection>
            <PolicyTitle>4. Non-Refundable Items</PolicyTitle>
            <PolicyText>
              Any additional services such as travel insurance, visa fees, or
              other personal expenses are non-refundable.
            </PolicyText>
          </PolicySection>
        </TermsWrapper>

        <Terms>
          <ToursTermsAndConditions />
        </Terms>
      </LeftSection>

      <RightSection>
        {images.map((image) => (
          <ImageCard key={image.id} src={image.src} alt={`Image ${image.id}`} />
        ))}
      </RightSection>
    </Wrapper>
  );
}

export default GroupInformation;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const LeftSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 39rem;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  h3 {
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  p {
    font-size: 14px;
    text-transform: capitalize;
    color: #555;
  }
`;

const LeftBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const BottomItinerary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h4 {
    font-size: 2rem;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1.2px;
    color: #333;
  }

  p {
    font-size: 14px;
    color: gray;
    line-height: 1.6;
  }
`;

const LeftAccordion = styled.div`
  /* Style as needed */
`;

const IncludeSection = styled.div`
  /* Style as needed */
`;

const TermsWrapper = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 1rem;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin-bottom: 2rem;
  color: #666;
`;

const PolicySection = styled.div`
  margin-bottom: 1.5rem;
`;

const PolicyTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 0.5rem;
  color: #444;
`;

const PolicyText = styled.p`
  font-size: 14px;
  color: #555;
  line-height: 1.5;
`;

const Terms = styled.div`
  /* Style as needed */
`;

const RightSection = styled.div`
  width: 45%;
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  height: fit-content;
  justify-content: center;
`;
