import * as types from "../constants";

const initialState = {
  isLoading: false,
  success: false,
  error: "",
  data: [],
  count: 0,
};

const myRideRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MY_RIDE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_MY_RIDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        data: action.payload.data,
        count: action.payload.count,
      };

    case types.GET_MY_RIDE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        data: [],
      };
    case types.ACCEPT_RIDE_REQUEST:
      const updated = state.data.map((rideReq) => {
        if (rideReq.id === action.payload.id) {
          rideReq.status = "2";
        }
        return rideReq;
      });
      return { ...state, data: updated };
    case types.REJECT_RIDE_REQUEST:
      const updatedRR = state.data.map((rideReq) => {
        if (rideReq.id === action.payload.id) {
          rideReq.status = "3";
        }
        return rideReq;
      });
      return { ...state, data: updatedRR };
    default:
      return state;
  }
};
export default myRideRequestsReducer;
