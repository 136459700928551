import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "../../services/api";
import VehicleItem from "../../components/VehicleItem";
import AddCarModal from "../VehicleInfo/AddCarModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const MyVehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      const response = await api.get("/vehicle/my-vehicles");
      if (response.status !== 200) {
        throw new Error("Failed to fetch vehicles");
      }
      setVehicles(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleModal = () => {
    setIsOpen(!isOpen);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <Container>
      <Wrapper>
        <div className="header-section">
          <div className="heading-text">
            <h3>Your Vehicles</h3>
            <p>Please provide valid information about your vehicle</p>
          </div>
          <div className="btn" onClick={handleToggleModal}>
            <button>
              <p>Add vehicle</p>
            </button>
          </div>
        </div>
        <div className="card-section">
          {vehicles.map((vehicle) => (
            <VehicleItem
              vehicle={vehicle}
              page="myvehicles"
              fetchVehicles={fetchVehicles}
            />
          ))}
        </div>
        {isOpen && (
          <AddCarModal
            auth={auth}
            dispatch={dispatch}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            publishRideInfo={null}
            fetchVehicles={fetchVehicles}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default MyVehicles;
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  height: auto;
  width: 100%;
`;

const Wrapper = styled.div`
  .header-section {
    padding: 2rem 0;

    width: 100%;
    display: flex;
    justify-content: space-between;
    .heading-text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      h3 {
        font-size: xx-large;
        color: #000000ba;
      }
      p {
        color: gray;
      }
    }

    .btn {
      button {
        background-color: #054752;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        cursor: pointer;
        border: none;
        p {
          color: white;
        }
      }
    }
  }
  .card-section {
    max-width: 600px;
  }
`;
