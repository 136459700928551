import React from 'react';
import { Button, Container, Description, Header } from './styles';


const BookingConfirmation = () => {
  return (
    <Container>
      <Header>Booking Request Sent</Header>
      <Description>Thanks for sending the booking request, we will inform you as soon as your request is accepted or
        rejected. It will take a few minutes. Once your request is accepted then you will get a notification for payment.
      </Description>
      <Button to="/my-bookings" animation={true}>View Your Bookings</Button>
    </Container>
  );
};

export default BookingConfirmation;