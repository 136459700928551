import React, { useState } from "react";
import styled from "styled-components";
import { LuLayoutDashboard } from "react-icons/lu";
import { VscGraph } from "react-icons/vsc";
import { AiFillCar } from "react-icons/ai";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { PiShieldWarningBold } from "react-icons/pi";
import { MdBlock } from "react-icons/md";
import { FaRoute } from "react-icons/fa6";

const AdminSider = ({ handleItem, currentItem }) => {
  return (
    <SiderWraper>
      <div className="main">
        <ul>
          <li
            onClick={() => handleItem("dashboard")}
            className={currentItem === "dashboard" ? "active" : null}
          >
            <LuLayoutDashboard />
            Dashoard
          </li>
          <li
            onClick={() => handleItem("requests")}
            className={currentItem === "requests" ? "active" : null}
          >
            <VscGraph />
            Refund
          </li>
          <li
            onClick={() => handleItem("rides")}
            className={currentItem === "rides" ? "active" : null}
          >
            <AiFillCar />
            Rides
          </li>
          <li
            onClick={() => handleItem("bookings")}
            className={currentItem === "bookings" ? "active" : null}
          >
            <AiFillCar />
            Bookings
          </li>

          <li
            onClick={() => handleItem("customers")}
            className={currentItem === "customers" ? "active" : null}
          >
            <BsFillPersonVcardFill />
            Customers
          </li>
          <li
            onClick={() => handleItem("reports")}
            className={currentItem === "reports" ? "active" : null}
          >
            <PiShieldWarningBold />
            Reports
          </li>
          <li
            onClick={() => handleItem("banned")}
            className={currentItem === "banned" ? "active" : null}
          >
            <MdBlock />
            Banned Users
          </li>
          <li
            onClick={() => handleItem("route")}
            className={currentItem === "route" ? "active" : null}
          >
            <FaRoute />
            Routes
          </li>
        </ul>
      </div>
    </SiderWraper>
  );
};

export default AdminSider;
const SiderWraper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-right: 1px solid #f4f6fb;
  .main {
    ul {
      list-style-type: none;
      li {
        line-height: 3rem;
        cursor: pointer;
        padding: 0.5rem;

        svg {
          margin-right: 1rem;
          // color: #054752;
          :hover {
            font-weight: 600;
          }
        }
        :hover {
          font-weight: 600;
          color: #054752;
          -webkit-text-decoration: none;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;
          background: #f4f6fb;
          border-radius: 0.5rem;
        }
      }
      .active {
        font-weight: 600;
        color: #054752;
        -webkit-text-decoration: none;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
        background: #f4f6fb;
        border-radius: 0.5rem;
        svg {
          margin-right: 1rem;
          color: #054752;
        }
      }
    }
  }
`;
