import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import SilkCabsFooter from "../../components/SilkCabsFooter";

function TermsAndConditions() {
  return (
    <div>
      <PageContainer>
        <h2> Terms and Conditions </h2>
        <div className="mainContainer">
          <div className="pageContent">
            <div className="linksContainer">
              <div className="link-items">
                <Link to={"/terms-conditions"}>Terms & Conditions</Link> |&nbsp;
                <Link to={"/privacy-policy"}>Privacy Policy </Link> |&nbsp;
                <Link to={"/gosawari-refund-policy"}>
                  Cancellation and Refund Policy{" "}
                </Link>
              </div>
              {/* <div className="cookies">
              |&nbsp; <Link>Cookies Policy</Link>
            </div> */}
            </div>
            <div className="date">
              <p>Effective date: 28/09/2024 </p>
            </div>
            <div className="description">
              <div className="item">
                <h3>1. Introduction</h3>
                <p>
                  Welcome to GoSawari, the carpooling platform dedicated to
                  providing a convenient and sustainable transportation
                  solution. By using our platform, you agree to comply with the
                  following Terms and Conditions. These terms govern your access
                  to and use of the GoSawari services.
                </p>
              </div>
              <div className="item">
                <h3>2. User Eligibility</h3>
                <p>
                  By using GoSawari, you affirm that you are at least 18 years
                  old and capable of entering into legally binding agreements.
                  If you are using GoSawari on behalf of an organization or
                  entity, you represent and warrant that you have the authority
                  to bind that organization or entity to these Terms.
                </p>
              </div>
              <div className="item">
                <h3>3. User Responsibilities </h3>
                <p>
                  3.1&nbsp; <strong>User Accounts: </strong>Users are required
                  to create an account to access certain features of GoSawari.
                  You are responsible for maintaining the confidentiality of
                  your account information and for all activities that occur
                  under your account.
                  <br />
                  3.2&nbsp;<strong>Accurate Information: </strong> Users must
                  provide accurate and up-to-date information during the
                  registration process and promptly update any changes.
                </p>
              </div>
              <div className="item">
                <h3> 4. GoSawari Services</h3>

                <p>
                  {" "}
                  4.1&nbsp; <strong>Carpooling: </strong> GoSawari facilitates
                  ridesharing by connecting drivers and passengers. Users
                  understand and agree that GoSawari is not a transportation
                  service provider, and the platform is solely a facilitator of
                  ridesharing arrangements.
                  <br />
                  4.2&nbsp; <strong>Payment: </strong>
                  Payments are made in cash to the driver whereas the service
                  commission from the passengers is charged online through the
                  GoSawari platform. Users agree to abide by the payment terms
                  and conditions specified in the app.
                </p>
              </div>
              <div className="item">
                <h3> 5. User Conduct </h3>

                <p>
                  5.1 &nbsp;<strong> Compliance with Laws:</strong> Users agree
                  to comply with all applicable laws and regulations while using
                  GoSawari, including but not limited to traffic laws and
                  regulations.
                  <br />
                  5.2 &nbsp;Code of Conduct: Users must adhere to a respectful
                  and courteous behavior when using GoSawari. Any form of
                  harassment, discrimination, or inappropriate conduct is
                  strictly prohibited.
                </p>
              </div>
              <div className="item">
                <h3>6. Liability and Disputes</h3>

                <p>
                  6.1&nbsp;<strong>Limitation of Liability: </strong> GoSawari
                  is not liable for any direct, indirect, incidental, special,
                  consequential, or punitive damages resulting from the use of
                  our platform.
                  <br />
                  6.2 &nbsp;<strong> Dispute Resolution:</strong> Any disputes
                  between users should be resolved directly between the parties
                  involved. GoSawari may, at its discretion, assist in dispute
                  resolution{" "}
                </p>
              </div>
              <div className="item">
                <h3> 7. Privacy</h3>
                <p>
                  7.1&nbsp;<strong>Data Collection: </strong> GoSawari collects
                  and processes user data in accordance with its Privacy Policy.
                  By using the platform, users consent to the collection and use
                  of their data.{" "}
                </p>
              </div>
              <div className="item">
                <h3>8. Termination</h3>

                <p>
                  8.1&nbsp;<strong>Termination by Users: </strong> Users may
                  terminate their GoSawari account at any time.
                  <br />
                  8.2&nbsp;<strong>Termination by GoSawari: </strong> GoSawari
                  reserves the right to suspend or terminate user accounts for
                  violations of these Terms or for any other reason deemed
                  necessary by GoSawari.{" "}
                </p>
              </div>
              <div className="item">
                <h3>9. Changes to Terms</h3>
                <p>
                  GoSawari reserves the right to modify or revise these Terms
                  and Conditions at any time. Users will be notified of any
                  changes, and continued use of the platform constitutes
                  acceptance of the revised terms.
                </p>
              </div>
              <div className="item">
                <h3>10. Contact Information </h3>
                <p>
                  For any questions or concerns about these Terms and
                  Conditions, please contact us at{" "}
                  <strong>support@gosawari.com</strong>. Effective Date:
                  September 28,2024{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
      <SilkCabsFooter />
    </div>
  );
}

export default TermsAndConditions;
const PageContainer = styled.div`
    background-color: #f8f8f8;
    height:auto;
    padding: 3rem;
    @media(max-width: 546px){
      padding: 1rem;
    }

    h2{
        text-align: center;
        margin: 3.75rem 0;
        font-size: 3.4375rem;
        font-weight: 100;
        @media(max-width: 546px){
          font-size: 1.5rem;      
        }
}
    }
    .mainContainer{
        background-color:white;
        max-width:1000px;
        min-height:900px;
        margin:auto;
        padding:5rem;
        @media(max-width: 546px){
          padding: 2rem 1rem;      
        }
        .pageContent{
            width:100%;
            height:auto;
            .linksContainer{
                width:fit-content;
                margin: 0 auto;  
                display: flex;
                justify-content: space-around;
                a {
                  text-decoration: none;
                    color: ${COLORS.secondaryTextColor};
                    :hover{
                        color: ${COLORS.linkColor};
                    }
                }
                @media (max-width: 546px) {
                  display:flex;
              flex-direction:column;
              gap:.5rem;

                }


            }
            .date {
                margin-top:3rem;

                p{
                    font-size: 1.3rem;
                    font-weight: 700;
                    color: #5d5d5d;
                }
            }
            .description {
                height:auto;
                width:100%;
                margin-top:4rem;
                @media(max-width: 546px){
                  margin-top:0;
                }
                .item{
                    margin-bottom: 2rem;

                    h3{
                        font-size: 28px;
                        font-weight: 100;
                        margin-bottom: 1.5rem;
                        color: ${COLORS.secondaryColor};
                        @media(max-width: 546px){
                          font-size: 1.4rem;
                        }
                    }
                    p {
                        color: ${COLORS.secondaryTextColor};
                        line-height:1.4;
                    }
                }
            }
        }
    }

`;
