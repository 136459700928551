import React from "react";
import styled from 'styled-components';

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
`;

const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;

const PaymentInput = styled.input`
  margin: 10px 0;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const PaymentButton = styled.button`
  margin: 10px 0;
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #3e8e41;
  }
`;

function Payments() {
  return (
    <PaymentContainer>
      <h2>Make a Payment</h2>
      <PaymentForm>
        <label>Amount:</label>
        <PaymentInput type="text" placeholder="Enter amount" />
        <PaymentButton type="submit">Submit</PaymentButton>
      </PaymentForm>
    </PaymentContainer>
  );
}
 
export default Payments;



