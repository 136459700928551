import * as types from "../constants";

const initialState = {
  isLoading: false,
  success: false,
  error: "",
  data:[], 
};

const citiesReducer=(state=initialState,action)=>{
    switch(action.type){
        case types.GET_ALL_CITIES_REQUEST:
            return {
                ...state, isLoading: true
            }
            case types.GET_ALL_CITIES_SUCCESS:
                let sortedCities = action.payload.sort((a, b) => a.name.localeCompare(b.name));

            return { ...state, isLoading: false, success: true,data: sortedCities }

            case types.GET_ALL_CITIES_FAILED:
                return { ...state, isLoading:false, success: false,success: false ,error:action.payload}


        default: return state;
    }

}
export default citiesReducer;;