const helpData = [
  {
    id: 1,
    title: "How do I book a carpool ride?",
    description:
      "You can book a carpool ride on our mobile app, or on GoSawari.com. Simply search for your destination, choose the date you want to travel and pick the carpool that suits you best! Some rides can be booked instantly, while other rides require manual approval from the driver. Either way, booking a carpool ride is fast, simple and easy.",
  },

  {
    id: 2,
    title: "How do I cancel my carpool ride?",
    description:
      "If you have a change of plans, you can always cancel your carpool ride from the ‘Your rides’ section of our app. The sooner you cancel, the better. That way the driver has time to accept new passengers. The amount of your refund will depend on how far in advance you cancel. If you cancel more than 24 hours before departure, for example, you’ll receive a full refund, excluding the service fee.",
  },
  {
    id: 3,
    title: "How much does a carpool ride cost? ",
    description:
      "The costs of a carpool ride can vary greatly, and depend on factors like distance, time of departure, the demand of that ride and more. It is also up to the driver to decide how much to charge per seat, so it’s hard to put an exact price tag on a ride. Check out some of our top carpool destinations to get an idea of price, or start searching for your next carpool ride on GoSawari.com.",
  },
  {
    id: 4,
    title: "How do I publish a carpool ride?",
    description:
      "GoSawari is a free service for our drivers.Offering a carpool ride on GoSawari is easy. To publish your ride, use our mobile app or GoSawari.com. Indicate your departure and arrival points, the date and time of your departure, how many passengers you can take and the price per seat. You’ll also need to choose how you want to accept bookings (either automatically or manually), and you have the option of adding any important details you think your passengers should know about. Then tap ‘Publish ride’ and you’re done!",
  },
  {
    id: 5,
    title: "What are the benefits of travelling by carpool?",
    description:
      "There are multiple advantages to carpooling, over other means of transport. Travelling by carpool is usually more affordable, especially for longer distances. Carpooling is also more eco-friendly, as sharing a car means there will be fewer cars on the road, and therefore fewer emissions. Taking a carpool ride is also a safe way to travel in the current times. Because there are only a few people in a car, you have fewer points of contact and there’s less risk than other travel options.",
  },
  {
    id: 6,
    title: "How do I start carpooling?",
    description:
      "Carpooling with GoSawari is super easy, and free! Simply sign up for an account and tell us some basic details about yourself. Once you have a GoSawari account, you can start booking or publishing rides directly on our app or website.",
  },
  {
    id: 7,
    title: "Are there rides for women only?",
    description:
      "Yes, there are rides which are only for women.In Female Only rides only the females can book the ride.",
  },
  {
    id: 8,
    title: "How to publish Female only rides?",
    description:
      "You can publish a ride by checking the female only option in publish ride form.",
  },
];
export default helpData;
