import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { center, justify } from "@cloudinary/url-gen/qualifiers/textAlignment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    marginBottom: "0.5rem",
  },
}));

export default function IncludeAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>What's Included</h4>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <strong>Break Fast</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            A representative from our company will receive you at Islamabad
            International Airport. The representative will hold a card with your
            Name and our company name in their hand. You will be transferred to
            the hotel in Islamabad. Free time for rest in Islamabad Please
            notify us if you do not need an airport transfer Hotel check-in:
            2:00 PM
            <br />
            Note: Pre-tour accommodation is not included
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong> Lunch</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            Take the morning flight to Gilgit Your guide will receive you at
            Gilgit Airport Road trip to Hunza (2 hours) Stopover at Rakaposhi's
            viewpoint Reach Hunza by the afternoon Hotel transfer Visit Baltit
            Fort Sunset at Duikar Valley to get a panoramic view of central
            Hunza Free time to Explore the tourist street in Hunza Overnight
            stay in central Hunza
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong> Guide</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            Take the morning flight to Gilgit Your guide will receive you at
            Gilgit Airport Road trip to Hunza (2 hours) Stopover at Rakaposhi's
            viewpoint Reach Hunza by the afternoon Hotel transfer Visit Baltit
            Fort Sunset at Duikar Valley to get a panoramic view of central
            Hunza Free time to Explore the tourist street in Hunza Overnight
            stay in central Hunza
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong> Local Transport</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            Take the morning flight to Gilgit Your guide will receive you at
            Gilgit Airport Road trip to Hunza (2 hours) Stopover at Rakaposhi's
            viewpoint Reach Hunza by the afternoon Hotel transfer Visit Baltit
            Fort Sunset at Duikar Valley to get a panoramic view of central
            Hunza Free time to Explore the tourist street in Hunza Overnight
            stay in central Hunza
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong> Accomodation</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            Take the morning flight to Gilgit Your guide will receive you at
            Gilgit Airport Road trip to Hunza (2 hours) Stopover at Rakaposhi's
            viewpoint Reach Hunza by the afternoon Hotel transfer Visit Baltit
            Fort Sunset at Duikar Valley to get a panoramic view of central
            Hunza Free time to Explore the tourist street in Hunza Overnight
            stay in central Hunza
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
