import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomButton from "../../components/UI/Button";
import { Select, MenuItem } from "@material-ui/core";
import info from "../../images/info.png";
import { COLORS } from "../../assets/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import api from "../../services/api";
import { LOGIN_SUCCESS, SET_CURRENT_USER } from "../../redux/constants";
import { useDispatch } from "react-redux";
import { getAge } from "../../Utils";

const Dob = () => {
  const navigate = useNavigate();

  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setGender] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get("token");
      if (!token || token === "null") {
        token = localStorage.getItem("authToken");

        if (!token) {
          toast.error("Something went wrong, try again");
          navigate("/login");
        }
      }
      localStorage.setItem("authToken", token);
      const decoded = jwt_decode(token);
      console.log(decoded, "decoded");
      if (decoded.dob && decoded.gender && decoded.otp_verified) {
        dispatch({
          type: SET_CURRENT_USER,
          payload: { ...decoded, token },
        });

        dispatch({
          type: LOGIN_SUCCESS,
        });
        navigate("/search-rides");
      } else {
        dispatch({
          type: SET_CURRENT_USER,
          payload: { ...decoded, token },
        });

        dispatch({
          type: LOGIN_SUCCESS,
        });
        setCurrentUser(decoded);
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  }, []);

  const handleSave = async () => {
    if (!dateOfBirth) {
      toast.error("Please enter your date of birth.");
      return;
    }
    if (!gender) {
      toast.error("Please select your Gender.");
      return;
    }
    if (getAge(dateOfBirth) < 18) {
      toast.info("You should be atleast 18 years old");
      return;
    }
    try {
      const response = await api.patch(
        `/user/update-details/${currentUser?.id}`,
        {
          dob: dateOfBirth,
          gender: gender,
        }
      );
      if (response.status == 200) {
        navigate("/get-phone-number");
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  return (
    <Wrapper>
      <div className="header-section">
        <div className="image-container">
          <img src={info} alt="info" />
        </div>
        <h4>Enter your Dob and Gender</h4>
        <p>Enter your date of birth and select your gender </p>
        <div className="dobcontainer">
          <DatePicker
            className="datepicker"
            placeholderText={"DOB e.g 1994-12-15"}
            selected={dateOfBirth}
            onChange={(date) => setDateOfBirth(date)}
            isClearable
            showYearDropdown
            useShortMonthInDropdown
          />
        </div>
        <div className="radio-container">
          <p> Gender:&nbsp;</p>
          <RadioLabel>
            <RadioInput
              type="radio"
              id="male"
              name="gender"
              value="male"
              checked={gender === "male"}
              onChange={() => setGender("male")}
            />
            Male
          </RadioLabel>

          <RadioLabel>
            <RadioInput
              type="radio"
              id="female"
              name="gender"
              value="female"
              checked={gender === "female"}
              onChange={() => setGender("female")}
            />
            Female
          </RadioLabel>
        </div>
        <div className="button">
          <CustomButton label="Save" onClick={handleSave} />
        </div>
      </div>
    </Wrapper>
  );
};

export default Dob;

const Wrapper = styled.div`

  justify-content: center;
  display: flex;
  padding-top: 6rem;
  height: auto;
  .header-section{
    gap:1rem;
    width: auto;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-container{
      img{
        height: 6rem;
        width: 6rem;
      }
    }
   h4{
      font-size: large;
      text-align:center;
    }
    p {
      font-size: 1rem;
      color: gray;
      text-align:center;
    }
    .dobcontainer{
      width: 100%;
      padding: 10px;
        .datepicker{
          width:100%;
          height: 3rem;
          padding: 0 8px;
          font-size: 16px;
          border: 1px solid lightgray;
          border-radius: 5px;
        }
  
      .react-datepicker__navigation--next {
        background-color: #054752;
        color: #054752;
      }
      .react-datepicker__navigation--previous {
        background-color: #054752;
        color: #054752;
      }
      .react-datepicker__year-read-view--down-arrow {
        color: #054752;
      }
    
      .datepicker-dropdown::after {
        content: "\f078";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none; 
        font-family: FontAwesome; 
        font-size: 14px;
      }
      .react-datepicker__close-icon::after {
        cursor: pointer;
        background-color: #054752;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 2px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "×";
      }
      .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        border-radius: 50%;
        background-color: #054752;
        color: #fff;
      }
      .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
        border-radius: 50%;
        background-color: #054752;
        color: #fff;
      }
   
      .error {
        color:${COLORS.errorColor}
        padding: 5px 0;
        text-align: left;
      }
    }
    .radio-container{
      display:flex;
      justify-content: space-around;  
    }
    .button{
        width:100%;
        padding:10px;
      
    }
  }
 
`;
const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const RadioInput = styled.input`
  margin-right: 16px;
  margin-top: -6px;

  /* Default color */
  &:checked {
    &::before {
      background-color: ${COLORS.primaryColor};
    }
  }

  /* Disabled color */
  &:disabled {
    &::before {
      background-color: lightgray;
    }
  }

  /* Radio button appearance */
  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${COLORS.primaryColor};
    background-color: white;
    transition: background-color 0.2s ease-in-out;
  }
`;
