import React from "react";
import styled from "styled-components";
import RefundRecord from "../../components/RefundProgress";

const MyRefunds = () => {
  return (
    <Wrapper>
      <RefundRecord />
    </Wrapper>
  );
};

export default MyRefunds;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`;
