import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

const StyledTimelineContent = styled(TimelineContent)`
  cursor: pointer;
  :hover {
    background: rgb(233 236 237);
    border-radius: 8px;
  }
`;
const textLength = 26;
export default function OppositeContentTimeline({
  fromTime,
  leavingFrom,
  toTime,
  headingTo,
  drop,
  pick,
  startLat,
  startLng,
  endLat,
  endLng,
  rideId,
}) {
  const navigate = useNavigate();
  const showOnMap = (lat, lng, rideId) => {
    navigate(`showOnMap?lat=${lat}&lng=${lng}&rideId=${rideId}`);
  };

  return (
    <React.Fragment>
      <Timeline align="left" style={{ alignItems: "center" }}>
        <TimelineItem style={{ width: "100%" }}>
          <TimelineOppositeContent style={{ flex: "none" }}>
            <Typography style={{ minWidth: "5rem" }} color="textPrimary">
              {moment(fromTime).format("h:mm A") == toTime ? "calc.." : toTime}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{ backgroundColor: "#d90d0d" }} />

            <TimelineConnector style={{ backgroundColor: "rgb(5, 71, 82)" }} />
          </TimelineSeparator>
          <StyledTimelineContent
            onClick={() => showOnMap(startLat, startLng, rideId)}
          >
            <Typography>{headingTo}</Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {drop?.substring(0, textLength)}{" "}
              {drop?.length > textLength && "..."}
            </Typography>
          </StyledTimelineContent>
        </TimelineItem>
        <TimelineItem style={{ width: "100%" }}>
          <TimelineOppositeContent style={{ flex: "none" }}>
            <Typography style={{ minWidth: "5rem" }} color="textPrimary">
              {moment(fromTime).format("h:mm A")}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{ backgroundColor: "green" }} />
          </TimelineSeparator>
          <StyledTimelineContent
            onClick={() => showOnMap(endLat, endLng, rideId)}
          >
            <Typography>{leavingFrom}</Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {pick?.substring(0, textLength)}{" "}
              {pick?.length > textLength && "..."}
            </Typography>
          </StyledTimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  );
}
