import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import SilkCabsFooter from "../../components/SilkCabsFooter";

function PrivacyPolicy() {
  return (
    <div>
      <PageContainer>
        <h2> Privacy and Data Protection Policy </h2>
        <div className="mainContainer">
          <div className="pageContent">
            <div className="linksContainer">
              <Link to={"/terms-conditions"}>Terms & Conditions</Link> |
              <Link to={"/privacy-policy"}>Privacy Policy </Link> |
              <Link to={"/gosawari-refund-policy"}>
                Refund and Cancellation Policy
              </Link>
            </div>
            <div className="date">
              {" "}
              <p>Effective date: 28/09/2024 </p>
            </div>
            <div className="description">
              <div className="item">
                <h3>1. Introduction</h3>
                <p>
                  Welcome to GoSawari, the premier carpooling platform dedicated
                  to connecting riders and drivers for efficient and sustainable
                  transportation in Pakistan. This Privacy and Data Protection
                  Policy outlines our commitment to safeguarding the privacy of
                  our users and the secure handling of their personal
                  information in accordance with Pakistani laws and regulations.
                </p>
              </div>
              <div className="item">
                <h3>2. Information Collection</h3>
                <p>
                  2.1 Personal Information: To facilitate our carpooling
                  services in Pakistan, we collect and process essential
                  personal information, including names, contact details, and
                  payment information.
                  <br />
                  2.2 Location Information: To optimize route planning within
                  Pakistan, we collect location data while the app is in use.
                  Users have the option to enable or disable location services
                  at any time.
                  <br />
                  2.3 Usage Data: We analyze how users interact with our
                  platform, including app usage and device information to
                  enhance our services and improve the user experience.{" "}
                </p>
              </div>
              <div className="item">
                <h3> 3. Use of Information</h3>

                <p>
                  <span>3.1 </span> Service Provision: Your data is utilized to
                  provide seamless carpooling services in Pakistan, including
                  efficient matching of riders with drivers, communication
                  facilitation, and secure payment processing.
                  <br />
                  <span>3.2 </span> Service Improvement: Aggregate and
                  anonymized data are employed to analyze usage patterns,
                  troubleshoot issues, and continuously enhance the
                  functionality of our platform within the Pakistani market.
                  <br />
                  <span>3.3 </span> Communication: We use contact information to
                  relay important updates and service notifications.
                </p>
              </div>
              <div className="item">
                <h3> 4. Data Security</h3>
                <p>
                  4.1 Access Controls: Access to personal information is
                  restricted to authorized personnel in Pakistan, governed by
                  stringent access controls.
                  <br />
                  4.2 Data Storage: Your data is stored on secure servers,
                  subject to robust security measures to prevent unauthorized
                  access.
                </p>
              </div>
              <div className="item">
                <h3>5. Information Sharing</h3>
                <p>
                  5.1 Third-Party Partners: Necessary information is shared with
                  trusted third-party service providers within Pakistan, such as
                  payment processors to deliver core services.
                  <br />
                  5.2 Legal Compliance: We may disclose information as required
                  by Pakistani law, to protect our rights, or in response to
                  valid legal requests.
                </p>
              </div>
              <div className="item">
                <h3> 6. User Choices</h3>
                <p>
                  6.1 Account Settings: Users in Pakistan have the flexibility
                  to review and update their account information through the app
                  settings.
                </p>
              </div>
              <div className="item">
                <h3>7. Policy Updates</h3>
                <p>
                  This Privacy and Data Protection Policy for GoSawari Pakistan
                  may be updated periodically, with any changes communicated
                  through the app or our website.
                </p>
              </div>
              <div className="item">
                <h3>8. Contact Information</h3>
                <p>
                  For inquiries regarding our privacy practices in Pakistan,
                  please contact us at <strong>support@gosawari.com</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
      <SilkCabsFooter />
    </div>
  );
}

export default PrivacyPolicy;
const PageContainer = styled.div`
    background-color: #f8f8f8;
    height:auto;
    padding: 3rem;
    @media(max-width: 546px){
      padding: 1rem;
    }

    h2{
        text-align: center;
        margin: 3.75rem 0;
        font-size: 3.4375rem;
        font-weight: 100;
        @media(max-width: 320px){
          font-size: 1rem;
        }
        @media(max-width: 546px)and (min-width: 321px){
          font-size: 1.5rem;
        }
        @media(max-width: 768px) and (min-width: 547px){
          font-size: 2rem;
        }
}

    }
    .mainContainer{
        background-color:white;
        max-width:1000px;
        min-height:900px;
        margin:auto;
        padding:5rem;
        @media(max-width: 546px){
          padding:1rem;

        }
        .pageContent {
            width:100%;
            height:auto;
            @media(max-width: 546px){
              height: auto;
              padding-bottom: 2rem;
            }
           

            .linksContainer {
              max-width: 500px;
              width: auto;
                    margin: 0 auto;  
                display: flex;
                justify-content: space-around;
                @media(max-width: 546px){
                  font-size: small;
                  gap: 0.25rem;
                  justify-content: flex-start;
                  width:fit-content;
                }
                @media(max-width: 320px){
                  font-size: small;
                  gap: 0.25rem;
                  justify-content: flex-start;
                  flex-direction: column;
                }
                a {
                    color: ${COLORS.secondaryTextColor};
                    :hover{
                        color: ${COLORS.linkColor};
                    }
                }

            }
            .date {
                margin-top:3rem;

                p{
                    font-size: 1.3rem;
                    font-weight: 700;
                    color: #5d5d5d;
                }
            }
            .description {
                height:auto;
                width:100%;
                margin-top:4rem;
                .item{
                    margin-bottom: 2rem;

                    h3{
                        font-size: 28px;
                        font-weight: 100;
                        margin-bottom: 1.5rem;
                        color: ${COLORS.secondaryColor}
                    }
                    p {
                        color: ${COLORS.secondaryTextColor};
                        line-height:1.4;
                    }
                }
            }
        }
    }

`;
