// import { Typography } from "@material-ui/core";
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import styled from "styled-components";
// import { CheckCircleRounded } from "@material-ui/icons";
// import { COLORS } from "../../assets/colors";
// import { useEffect } from "react";

// function RideSuccess({ rideId }) {
//   const navigate = useNavigate();
//   useEffect(() => {
//     return () => {
//       localStorage.removeItem("rideId");
//       localStorage.removeItem("activeStep");
//     };
//   });
//   return (
//     <Container>
//       <Body>
//         <CheckCircleRounded style={{ fontSize: "7rem" }} />
//         <Typography
//           variant="subtitle2'"
//           align="start"
//           color="primary"
//           gutterBottom
//         >
//           Ride Published!
//         </Typography>
//         <Message>
//           <Typography
//             align="center"
//             gutterBottom
//             variant="body1"
//             color="secondary"
//           >
//             <span className="congrats">Congratulations!</span> You have
//             successfully published your ride. Click the link below to see your
//             published ride.
//           </Typography>
//         </Message>
//         <span
//           className="see-ride"
//           onClick={() => navigate(`/active-ride/${rideId}`)}
//         >
//           <Typography variant="body1" color="primary">
//             See your Ride
//           </Typography>
//         </span>
//       </Body>
//     </Container>
//   );
// }

// export default RideSuccess;
// const Container = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   max-width: 600;
// `;
// const Body = styled.div`
//   padding: 60px;
//   border-radius: 8px;
//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   @media (max-width: 546px) {
//     padding: 0;
//   }
//   :nth-child(1) {
//     color: ${COLORS.primaryColor};
//     font-weight: 900;
//     font-size: 30px;
//     margin-bottom: 10px;
//     margin-top: 1rem;
//     max-width: 662px;
//   }
//   .congrats {
//     color: orange;
//     font-weight: bold;
//   }
//   .see-ride {
//     p {
//       color: ${COLORS.primaryTextColor};
//       text-decoration: underline;
//       cursor: pointer;
//       font-weight: bold;
//     }
//   }
// `;

// const Message = styled.p`
//   margin: 0;
//   color: #999999;
//   text-decoration: none;
//   text-transform: none;
//   font-weight: 400;
//   p {
//     font-size: 18px;
//   }
// `;

import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { CheckCircleRounded } from "@material-ui/icons";
import { COLORS } from "../../assets/colors";

function RideSuccess({ rideId }) {
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      localStorage.removeItem("rideId");
      localStorage.removeItem("activeStep");
    };
  }, []);

  return (
    <Container>
      <Body>
        <AnimatedCheckCircleRounded
          style={{ fontSize: "7rem", marginTop: "1rem" }}
        />
        <h4 style={{ marginTop: "1rem" }}>Ride Published!</h4>
        <Message>
          <Typography
            align="center"
            gutterBottom
            variant="body1"
            color="secondary"
          >
            <span className="congrats">Congratulations!</span> You have
            successfully published your ride. Click the link below to see your
            published ride.
          </Typography>
        </Message>
        <span
          className="see-ride"
          onClick={() => navigate(`/active-ride/${rideId}`)}
        >
          <Typography variant="body1" color="primary">
            See your Ride
          </Typography>
        </span>
      </Body>
    </Container>
  );
}

export default RideSuccess;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 600;
`;

const Body = styled.div`
  padding: 60px;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 546px) {
    padding: 0;
  }
  :nth-child(1) {
    color: ${COLORS.primaryColor};
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 10px;

    margin-top: 1rem;
    max-width: 662px;
  }

  .congrats {
    color: orange;
    font-weight: bold;
  }

  .see-ride {
    p {
      color: ${COLORS.primaryTextColor};
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

const Message = styled.p`
  margin-top: 1rem;

  // margin: 0;
  color: #999999;
  text-decoration: none;
  text-transform: none;
  font-weight: 400;

  p {
    font-size: 18px;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const AnimatedCheckCircleRounded = styled(CheckCircleRounded)`
  animation: ${spin} 1s linear infinite, ${bounce} 1s ease-in-out infinite;
  .typo {
    margin-top: 2rem;
  }
`;
