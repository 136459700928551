import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import SilkCabsFooter from "../../components/SilkCabsFooter";

function AboutUs() {
  return (
    <div>
      <PageContainer>
        <h2> About Us </h2>
        <div className="mainContainer">
          <div className="pageContent">
            <div className="linksContainer">
              <Link to={"/terms-conditions"}>Terms & Conditions</Link> |
              <Link to={"/privacy-policy"}>Privacy Policy </Link> |
              <Link>Cookies Policy</Link>
            </div>
            <div className="date">
              {" "}
              <p>Effective date: 28/09/2024 </p>
            </div>
            <div className="description">
              <div className="item">
                {/* <h3>1. General</h3> */}
                <p>
                  GoSawari is a cutting-edge carpooling platform. At GoSawari,
                  we are driven by a passion for innovation and a commitment to
                  providing sustainable and efficient transportation solutions.
                  GoSawari represents our dedication to revolutionizing the way
                  people commute, emphasizing convenience, environmental
                  responsibility, and community collaboration. As a product of
                  Agaicode Technologies, GoSawari combines advanced technology
                  with a user-centric approach to create a seamless and secure
                  carpooling experience for individuals across Pakistan. Our
                  team at Agaicode Technologies is continuously working to
                  enhance and expand the GoSawari platform, staying true to our
                  mission of making transportation smarter, greener, and more
                  accessible for everyone.
                </p>
              </div>
              <div className="item">
                <p>
                  <strong>GoSawari Support</strong>
                </p>
                <p>Contact us at: support@gosawari.com </p>
                <p>Phone: +923457068896 </p>
              </div>
              {/* <div className="item">
              <p>
                <strong>GoSawari Team</strong>
              </p>
              <p>Co-Founder: Jamil Abbas - jamil.abbas813@gmail.com</p>
              <p>Co-Founder: Sikander Abbas - sikanderabbas.dev@gmail.com</p>
              <p>Co-Founder: Nadeem Abbas - na.abbas001@gmail.com</p>
            </div> */}
            </div>
          </div>
        </div>
      </PageContainer>
      <SilkCabsFooter />
    </div>
  );
}

export default AboutUs;
const PageContainer = styled.div`
    background-color: #f8f8f8;
    height:auto;
    padding: 3rem;
    @media(max-width: 546px){
      padding: 1rem;
    }

    h2{
        text-align: center;
        margin: 3.75rem 0;
        font-size: 3.4375rem;
        font-weight: 100;
        @media(max-width: 320px){
          font-size: 1rem;
        }
        @media(max-width: 546px)and (min-width: 321px){
          font-size: 1.5rem;
        }
        @media(max-width: 768px) and (min-width: 547px){
          font-size: 2rem;
        }
}
    }
    .mainContainer{
        background-color:white;
        max-width:1000px;
        min-height:auto;
        margin:auto;
        padding:5rem;
        @media(max-width: 546px){
          padding:1rem;

        }
        .pageContent {
            width:100%;
            height:auto;
            @media(max-width: 546px){
              height: auto;
              padding-bottom: 2rem;
            }
            .linksContainer {
                width:400px;
                margin: 0 auto;  
                display: flex;
                justify-content: space-around;
                @media(max-width: 546px){
                  font-size: small;
                  gap: 0.25rem;
                  justify-content: flex-start;
                  width:fit-content;
                }
                @media(max-width: 320px){
                  font-size: small;
                  gap: 0.25rem;
                  justify-content: flex-start;
                  flex-direction: column;
                }
                a {
                    color: ${COLORS.secondaryTextColor};
                    :hover{
                        color: ${COLORS.linkColor};
                    }
                }

            }
            .date {
                margin-top:3rem;

                p{
                    font-size: 1.3rem;
                    font-weight: 700;
                    color: #5d5d5d;
                }
            }
            .description {
                height:auto;
                width:100%;
                margin-top:4rem;
                .item{
                    margin-bottom: 2rem;

                    h3{
                        font-size: 28px;
                        font-weight: 100;
                        margin-bottom: 1.5rem;
                        color: ${COLORS.secondaryColor}
                    }
                    p {
                        color: ${COLORS.secondaryTextColor};
                        line-height:1.4;
                    }
                }
            }
        }
    }

`;
