/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { Container } from "./styles";
import Notification from "../../components/Notification";
import { GlobalContext } from "../../contexts/globalContext";

const Notifications = () => {
  const { setNotificationCount } = useContext(GlobalContext);

  const { currentUser } = useSelector((state) => state.auth);

  const getNotificationCount = async () => {
    const response = await api.get("/notifications/count");
    setNotificationCount(response.data.data);
  };
  return (
    <Container>
      <div className="header">
        <h1>Notifications</h1>
      </div>
      <Notification
        getNotificationCount={getNotificationCount}
        currentUser={currentUser}
      />
    </Container>
  );
};

export default Notifications;
