import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReportTable from "./ReportTable";
import { IconButton } from "@material-ui/core";
import { CloudDownloadOutlined, RefreshRounded } from "@material-ui/icons";
import api from "../../../services/api";
import CircularLoader from "../../UI/Loader";

function Report() {
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchReports() {
      try {
        setLoading(true);
        const response = await api.get("admin/report/");
        if (response.status == 200) {
          setLoading(false);
          setReports(response.data.data);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    fetchReports();
  }, []);
  return (
    <Wrapper>
      <div className="report-nav">
        <Nav>
          <NavContainer>
            <NavLogo>
              <Typography>Report Details</Typography>
            </NavLogo>
            <NavMenu>
              <NavMenuItem>
                <IconButton>
                  <RefreshRounded style={{ color: "white" }} />
                </IconButton>
              </NavMenuItem>
              <IconButton>
                <CloudDownloadOutlined style={{ color: "white" }} />
              </IconButton>
            </NavMenu>
          </NavContainer>
        </Nav>
      </div>
      <div className="report-table">
        {loading ? (
          <CircularLoader isLoading={loading} />
        ) : (
          <ReportTable reports={reports} />
        )}
      </div>
    </Wrapper>
  );
}

export default Report;

const Wrapper = styled.div`
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 4px;
  height: auto;

  .report-table {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
  }
`;

const Nav = styled.nav`
  color: #fff;
  padding: 1rem;
  color: white;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgb(7 30 28) 0%,
    rgba(5, 71, 82, 1) 60%,
    rgb(23 104 120) 100%
  );
`;
const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavLogo = styled.div`
  font-size: 2.5rem;
  color: red;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 2rem;
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;
`;

const NavMenuItem = styled.li`
  font-size: 1rem;
`;
const Typography = styled.div`
  font-family: sans-serif;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
`;
