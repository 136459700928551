import React, { useEffect, useState } from "react";
import { Container, Content, Header } from "./styles";
import { Avatar, Divider } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import PaginationComp from "../Pagination";
import { toast } from "react-toastify";
import api from "../../services/api";
import { RATINGS } from "../../Utils";
import moment from "moment";
import NoData from "../NoData";
import CircularLoader from "../UI/Loader";
import { Pagination } from "@material-ui/lab";

const ReviewsAndRating = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const { userId } = useParams();
  const pageSize = 4;
  const offset = (page - 1) * pageSize;

  useEffect(() => {
    async function getFeedback() {
      try {
        setIsLoading(true);
        const response = await api.get(`feedback/${userId}`, {
          params: {
            limit: pageSize,
            offset: offset,
          },
        });
        setFeedbacks(response.data.data.rows);
        setFeedbackCount(response.data.data.count);
        if (response.data.data.count > 0) {
          setPages(Math.ceil(response.data.data.count / pageSize));
        }
        setAvgRating(response.data.data.average_rating);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
    getFeedback();
  }, [userId, offset]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Container>
      <Header>
        <h1>Reviews & Ratings</h1>
      </Header>
      <Content>
        {isLoading && <CircularLoader isLoading={isLoading} />}
        {!isLoading && feedbackCount > 0 ? (
          <>
            <div className="score">
              <p className="overall-rating">
                {parseFloat(avgRating).toFixed(1)}/5
              </p>
              <p className="total-ratings">{feedbackCount} ratings</p>
            </div>
            <Divider className="divider" />
            {feedbacks.map((item) => (
              <>
                <div className="review">
                  <div
                    className="user-info"
                    onClick={() =>
                      navigate(`/profile/${item?.reviewerInfo?.id}`)
                    }
                  >
                    <h1>{item?.reviewerInfo?.first_name}</h1>
                    <div className="avatar">
                      <Avatar src={item?.reviewerInfo?.avatar_url} />
                      <ChevronRight />
                    </div>
                  </div>
                  <div className="review-text">
                    <h3>{RATINGS[item.rating]}</h3>
                    <p>{item.review_text}</p>
                    <p>{moment(item?.created_at).format("MMMM Do, YYYY")}</p>
                  </div>
                </div>
                <Divider className="divider content" />
              </>
            ))}
            <div style={{ margin: "2rem" }}>
              {!isLoading && pages > 1 && feedbacks?.length > 1 && (
                <Pagination
                  onChange={handleChange}
                  size="large"
                  page={page}
                  count={pages}
                  color="primary"
                />
              )}{" "}
            </div>
          </>
        ) : (
          <>
            <NoData message={"No Reviews yet"} />
          </>
        )}
      </Content>
    </Container>
  );
};

export default ReviewsAndRating;
