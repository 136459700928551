/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import UserVehicles from "../../components/userVehicles";
import api from "../../services/api";
import { toast } from "react-toastify";
import { COLORS } from "../../assets/colors";
import AddCarModal from "./AddCarModal";
import { FaPlus } from "react-icons/fa";
import { getCurrentRideInfo } from "../../redux/actions";
import ConfirmModal from "../../components/models/ConfirmModal";
import { FaDeleteLeft, FaTrash } from "react-icons/fa6";

function VehicleInfo({ rideId, handleNext }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const dispatch = useDispatch();
  const [selectedVehicleId, setSelectedVehicle] = useState(null);

  const auth = useSelector((state) => state.auth);
  const publish = useSelector((state) => state.publish);
  const publishRideInfo = useSelector((state) => state.publishRideInfo);

  useEffect(() => {
    if (auth.currentUser.id && rideId) {
      dispatch(getCurrentRideInfo(auth.currentUser.id, rideId));
    }
  }, [auth.currentUser.id, rideId]);

  const handleSelectedVehicle = (selectedVehicle) => {
    const rideSeats = publishRideInfo?.currentRideInfo?.Ride?.total_seats;
    const selectedVehicleSeatsCapacity = parseInt(selectedVehicle.capacity);
    if (selectedVehicleSeatsCapacity < rideSeats) {
      toast.warning("Select a car with more seats");
      return;
    } else setSelectedVehicle(selectedVehicle.id);
  };

  const submitExisitingVehicle = async () => {
    try {
      const ride_id = publishRideInfo?.currentRideInfo?.ride_id;
      await api.patch(`/ride/${ride_id}`, { vehicle_id: selectedVehicleId });
      handleNext();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const deleteRide = async (rideId) => {
    try {
      const response = await api.post("ride/delete", {
        id: rideId,
      });
      if (response.data.data) {
        localStorage.removeItem("activeStep");
        toast.info("Ride Deleted");
        setShowConfirm(false);
        window.location.reload("/publish");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const renderSubTitle = () => {
    if (publishRideInfo?.userVehicles?.length > 0) {
      return (
        <>
          <span onClick={() => setIsOpen(true)} className="addCarLink">
            Add a new car
          </span>{" "}
          &nbsp;or select from the existing ones
        </>
      );
    } else {
      return (
        <NoCars>
          <Typography gutter variant="body1" color="secondary">
            You have not yet added any car. Please add a new car to be able to
            publish your rides
          </Typography>
          <div className="btn-grp">
            <DeleteButton
              onClick={() => setShowConfirm(true)}
              style={{ margin: "0 auto" }}
            >
              <DeleteIcon />
              Delete Ride
            </DeleteButton>
            <Button
              onClick={() => setIsOpen(true)}
              style={{ margin: "0 auto" }}
            >
              <CreateIcon />
              Add a Car
            </Button>
          </div>
        </NoCars>
      );
    }
  };
  return (
    <>
      <PageContainer>
        <div className="rideimg">{/* <img src={rideImg} /> */}</div>
        <Typography
          variant="h4"
          align="center"
          style={{ color: COLORS.primaryTextColor }}
        >
          Add Car Details
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className="pageSubtitle"
          style={{ color: COLORS.secondaryTextColor }}
        >
          {renderSubTitle()}
        </Typography>
        <Content>
          {publishRideInfo?.userVehicles?.length > 0 && (
            <UserVehicles
              selectedVehicleId={selectedVehicleId}
              setSelectedVehicle={handleSelectedVehicle}
              vehicles={publishRideInfo.userVehicles}
              submitExisitingVehicle={submitExisitingVehicle}
              setShowConfirm={() => setShowConfirm(true)}
            />
          )}
        </Content>

        {isOpen && (
          <AddCarModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            publishRideInfo={publishRideInfo}
            auth={auth}
            dispatch={dispatch}
            publish={publish}
          />
        )}
        {showConfirm && (
          <ConfirmModal
            title={"Delete Ride"}
            content={"Are you sure you want to delete this ride?"}
            open={showConfirm}
            handleClose={() => setShowConfirm(false)}
            confirmBtnText={"Confirm"}
            cancelBtnText={"Cancel"}
            onConfirm={() => {
              deleteRide(rideId);
            }}
          />
        )}
      </PageContainer>
    </>
  );
}

export default VehicleInfo;

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  max-width: 662px;
  margin: 0 auto;
  .rideimg {
    display: flex;
    justify-content: center;
    img {
      width: 89px;
    }
  }
  .addCarLink {
    text-decoration: underline;
    font-weight: bolder;
    color: ${COLORS.primaryTextColor};
    cursor: pointer;
  }
`;
const NoCars = styled.div`
  max-width: 430px;
  margin: 7px auto;
  p {
    padding: 5px;
  }
  .btn-grp {
    display: flex;
    gap: 10;
  }
`;
const Content = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  padding: 1rem;
`;
const CreateIcon = styled(FaPlus)`
  margin-right: 0.5rem;
`;

const DeleteIcon = styled(FaTrash)`
  margin-right: 0.5rem;
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0e0e0e;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: orangered;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.primaryColor};
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #063d49;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
`;
