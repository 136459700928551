import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  CommuteOutlined,
  FlashOffOutlined,
  FlashOnOutlined,
  SmokeFree,
  SmokingRoomsRounded,
  VerifiedUser,
} from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../Utils";
import { COLORS } from "../../assets/colors";

function DriverDetail({ driver, vehicle, smoking, autoApproval }) {
  const navigate = useNavigate();
  console.log("vehicle picture ", vehicle?.picture);
  const isVerified = driver?.cnic_verified && driver?.otp_verified;
  return (
    <Container>
      <StyledDriverSection>
        <div className="btnsubcontainer">
          <Button
            fullWidth
            className="btn"
            onClick={() => {
              navigate(`/profile/${driver?.id}`);
            }}
          >
            <div className="btnitems">
              <div className="btncontent">
                <h1 className="heading">
                  Captain:&nbsp;
                  {driver ? capitalizeFirstLetter(driver?.first_name) : ""}
                </h1>

                <p className="subtext">
                  {driver?.otp_verified ? "Phone verified" : ""}
                </p>
                {driver?.averageRating && (
                  <p className="subtext">
                    {driver?.averageRating}/5 star - {driver?.totalRatings}{" "}
                    ratings
                  </p>
                )}
              </div>
            </div>
            <div className="subbtn">
              <Avatar
                src={
                  driver?.avatar_url ||
                  `https://ui-avatars.com/api/?name=${driver?.first_name}`
                }
              />
              {isVerified && (
                <span className="verifiedBage">
                  <VerifiedUser
                    style={{ color: COLORS.linkColor }}
                    fontSize="small"
                  />
                </span>
              )}
            </div>
          </Button>
        </div>
      </StyledDriverSection>

      <Verificationcontainer>
        <div className="verificationsection">
          <div className="verifbody">
            <div className="listitems">
              <List>
                {isVerified && (
                  <ListItem style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <ListItemIcon style={{ minWidth: "36px" }}>
                      <VerifiedUser className="icon" />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "darkslategray", fontSize: "16px" }}
                        >
                          Verified Profile
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
                {autoApproval ? (
                  <ListItem style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <ListItemIcon style={{ minWidth: "36px" }}>
                      <FlashOnOutlined className="icon" />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "darkslategray", fontSize: "16px" }}
                        >
                          {" "}
                          Your booking will be confirmed instantly
                        </Typography>
                      }
                    />
                  </ListItem>
                ) : (
                  <ListItem style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <ListItemIcon style={{ minWidth: "36px" }}>
                      <FlashOffOutlined className="icon" />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "darkslategray", fontSize: "16px" }}
                        >
                          {" "}
                          Your booking won't be confirmed until the driver
                          approves your request
                        </Typography>
                      }
                    />
                  </ListItem>
                )}

                {/* {smoking ? (
                  <ListItem style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <ListItemIcon style={{ minWidth: "36px" }}>
                      <SmokingRoomsRounded className="icon" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "darkslategray", fontSize: "16px" }}
                        >
                          Smoking allowed
                        </Typography>
                      }
                    />
                  </ListItem>
                ) : (
                  <ListItem>
                    <ListItemIcon>
                      <SmokeFree className="icon" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "primary", fontSize: "16px" }}
                        >
                          Please no smoking
                        </Typography>
                      }
                    />
                  </ListItem>
                )} */}
                {!smoking && (
                  <ListItem style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <ListItemIcon style={{ minWidth: "36px" }}>
                      <SmokeFree className="icon" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "darkslategray", fontSize: "16px" }}
                        >
                          Please no smoking
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </div>
          </div>
        </div>
        {vehicle?.vehicle_name && (
          <div className="car-info">
            <span>
              <CommuteOutlined style={{ color: "gray" }} />
              <Typography variant="p" color="secondary" align="left">
                {vehicle?.vehicle_name}
              </Typography>
            </span>
            <Typography variant="p" color="textSecondary" align="left">
              {vehicle?.vehicle_model}
            </Typography>
          </div>
        )}

        {vehicle?.picture && (
          <div className="vehicle-image">
            <img className="picture" src={vehicle.picture} alt="car image" />
          </div>
        )}
      </Verificationcontainer>
    </Container>
  );
}

export default DriverDetail;
const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledDriverSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 546px;
  @media (max-width: 546px) {
    width: 100% !important;
  }
  .btnsubcontainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      border: 1px dotted cadetblue;
    }
    .btnitems {
      .btncontent {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .heading {
        font-size: 14px;
        text-transform: capitalize;
        color: rgb(5, 71, 82);
      }
      .subtext {
        font-size: 10px;
        text-transform: capitalize;
        color: rgb(61, 72, 93);
        align-items: center;
      }
    }
    .subbtn {
      position: relative;
      .verifiedBage {
        right: -8px;
        width: auto;
        border: 1px solid deepskyblue;
        bottom: 4px;
        height: 22px;
        position: absolute;
        background: white;
        border-radius: 50%;
      }
    }
  }
`;

const Verificationcontainer = styled.div`
  border-radius: 0rem 0rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 546px;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 546px) {
    width: 100% !important;
    flex-direction: column;
  }
  .verificationsection {
    .verifbody {
      .listitems {
        .icon {
          color: rgb(5, 71, 82);
        }
      }
    }
  }
  .car-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    @media (max-width: 546px) {
      align-items: start !important;
      button {
        width: 50%;
      }
    }
  }
  .vehicle-image {
    .picture {
      border-radius: 8px;
      max-width: 23rem;
      @media (max-width: 546px) {
        max-width: 18rem;
      }
    }
  }
`;
