import React from "react";
import styled from "styled-components";
import CancellationPolicy from "../components/CancellationPolicy";
import { Link, useNavigate } from "react-router-dom";
import SilkCabsFooter from "../components/SilkCabsFooter";
import { ArrowBack } from "@material-ui/icons";

function CarSawarPolicy() {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <div>
      <ContainerStyled>
        <div className="back-btn" onClick={goBack}>
          <ArrowBack />
          <span>Go Back</span>
        </div>
        <h2>Cancellation and Refund Policy</h2>
        <div>
          <LinkContainer>
            <Link to={"/terms-conditions"}>Terms & Conditions</Link> |
            <Link to={"/privacy-policy"}>Privacy Policy </Link> |
            <Link to={"/gosawari-refund-policy"}>
              Refund and Cancellation Policy
            </Link>
          </LinkContainer>
        </div>
        <Wrapper>
          <CancellationPolicy />
        </Wrapper>
      </ContainerStyled>
      <SilkCabsFooter />
    </div>
  );
}

export default CarSawarPolicy;

const ContainerStyled = styled.div`
  background-color: #f8f8f8;
  height: auto;
  padding: 3rem;
  .back-btn {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  @media (max-width: 546px) {
    padding: 1rem;
  }

  h2 {
    text-align: center;
    margin: 3.75rem 0;
    font-size: 3.4375rem;
    font-weight: 100;
    @media (max-width: 320px) {
      font-size: 1rem;
    }
    @media (max-width: 546px) and (min-width: 321px) {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) and (min-width: 547px) {
      font-size: 2rem;
    }
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10;
  gap: 10px;
  margin-top: 2rem;
  a {
    color: rgb(112, 140, 145);
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  min-height: 88vh;
  padding: 2rem;
  margin: 2rem auto;
  border-radius: 8px;
  max-width: 846px;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media (max-width: 546px) {
    padding: 1rem;
    margin: auto;
  }
`;
