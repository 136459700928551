import React from "react";
import Forget from "../../components/Forget";
import PageContainer from "../../components/UI/PageContainer";
import ChangePasswordForm from "../../components/ChangePasswordForm";

const ChangePassword = () => {
  return (
    <PageContainer>
      <ChangePasswordForm />
    </PageContainer>
  );
};

export default ChangePassword;
