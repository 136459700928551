import React from "react";
import styled from "styled-components";

const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  font-size: 1.2rem;
  color: #ff0000;
`;

function UnauthorizedPage() {
  return (
    <UnauthorizedContainer>
      <Title>Unauthorized Access</Title>
      <ErrorMessage>
        Sorry, you do not have permission to access this page.
      </ErrorMessage>
    </UnauthorizedContainer>
  );
}

export default UnauthorizedPage;
