import React from "react";
import { Button } from "@material-ui/core";
import { styled as materialStyled } from "@material-ui/core/styles";

const CancelButton = ({ label, onClick, disabled, height = 40 }) => {
  return (
    <div>
      <StyledButton height={height} onClick={onClick} disabled={disabled}>
        {label}
      </StyledButton>
    </div>
  );
};

export default CancelButton;

const StyledButton = materialStyled(Button)(({ height }) => ({
  height: `${height}px`,
  padding: "0 30px",
  width: "100%",
  background: "transparent",
  color: "darkslategray",
  boxShadow: "none",
  border: "1px solid gray",
}));
