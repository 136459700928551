import * as types from "../constants";

const initialState = {
  isLoading: false,
  success: false,
  error: "",
};
const publishRides = (state = initialState, action) => {
  switch (action.type) {
    case types.PUBLISH_RIDE_REQUEST:
      return { ...state, isLoading: true };

    case types.PUBLISH_RIDE_SUCCESS:
      return { ...state, isLoading: false, success: true };

    case types.PUBLISH_RIDE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      }; 
    case types.ADD_VEHICLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case types.ADD_VEHICLE_CLEAN_UP: 
    return {
      ...state, isLoading: false,
      success:false,
    }
    case types.PUBLISH_RIDE_CLEAN_UP: 
    return {
      ...state, isLoading: false,
      success:false,
    }
    

    default:
      return state;
  }
};
export default publishRides;
