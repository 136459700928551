import styled from "styled-components";
import { COLORS } from "../../assets/colors";

export const Container = styled.div`
  width: 100%;
  height: auto;
  @media (max-width: 546px) {
    padding-bottom: 4rem;
  }
  .no-data {
    display: flex;
    padding: 1rem;
    justify-content: center;
    font-size: small;
    font-weight: 200 !important;
    color: gray;
    font-family: system-ui;
  }
  .header {
    align: center;
    display: flex;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
    width: 100%;
    background: ${COLORS.backgroundGradient};
    height: fit-content;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: 400;
      color: white;
      margin: auto;
      @media (max-width: 546px) {
        font-size: larger;
        font-weight: bold;
      }
    }
    .tabs {
      width: fit-content;
      height: 2rem;
      display: flex;

      div {
        cursor: pointer;
        width: fit-content;
        padding: 1rem;
        height: 100%;
        background: #40808b;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        color: white;
        border-radius: 20px;
        margin: 5px;
        color: white;
        :hover {
          background: #054752;
        }
        @media (max-width: 546px) {
          padding: 0.5rem;
        }
      }
      .active {
        background: #054752;
      }
    }
  }
`;
export const CardButton = styled.button`
  display: block;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) =>
    props.disabled
      ? COLORS.secondaryColor
      : props.buttonType === "accept"
      ? COLORS.primaryColor
      : "red"};
  color: #fff;
  font-size: 14px;
  cursor: ${(props) => (!props.disabled ? "pointer" : null)};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      !props.disabled && props.buttonType !== "reject" ? "#2d5e66" : null};
  }
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: ${COLORS.primaryColor};
  color: white;
`;

export const TableHeadRow = styled.tr`
  text-align: left;
`;

export const TableHeadCell = styled.th`
  padding: 1rem;
`;

export const TableBody = styled.tbody`
  .isLoading {
    width: 100%;
    display: flex;
  }
`;

export const TableRow = styled.tr`
  .title {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;
      font-size: 1rem;
      img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
  .route {
    a {
      text-decoration: none;
      color: ${COLORS.primaryColor};
      font-weight: bold;
    }
  }
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 1rem;
  svg {
    width: 15px;
    margin: -7px 2px;
  }
  .actionBtns {
    display: flex;
    justify-content: space-evenly;
    max-width: 132px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 546px) {
    .actionBtns {
      button {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
export const TableWrapper = styled.div`
  max-width: 900px;
  margin: 2rem auto;
  padding: 1rem;
  .pagination {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 546px) {
    margin: 0 auto;
  }
`;
