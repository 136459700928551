import * as types from "../constants";

const initialState = {
  isLoading: false,
  success: false,
  error: "",
};

const otpReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OTP_REQUEST:
      return { ...state, isLoading: true };
    case types.OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: "",
      };
    case types.OTP_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    case types.OTP_CLEAN_UP:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
export default otpReducer;
