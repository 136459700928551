import AccountCircle from "@material-ui/icons/AccountCircle";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
export const settingList = [
  {
    id: 1,
    name: "Account",
    description: "Manage your public profile and private information",
    icon: <AccountCircle />,
  },
  {
    id: 2,
    name: "Security",
    description: "Manage your public profile and private information",
    icon: <LockOutlinedIcon />,
  },
  {
    id: 3,
    name: "Documents",
    description: "Manage your uploaded documents e.g License, ID Card",
    icon: <DescriptionOutlinedIcon />,
  },
];
