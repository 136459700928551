import moment from "moment";

export const validateSignupForm = (state) => {
  let errorItem = false;
  let errorsObj = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    dob: "",
    selectedGender: "",
  };

  const errorObj = { ...errorsObj };
  if (state.firstName.length < 3) {
    errorObj.firstName = "Should be greater than 2 letters";
    errorItem = true;
  }
  if (state.firstName.length > 20) {
    errorObj.firstName = "Should be less than 20 letters";
    errorItem = true;
  }

  if (state.lastName.length < 3) {
    errorObj.lastName = "Should be greater than 2 letters";
    errorItem = true;
  }
  if (state.lastName.length > 20) {
    errorObj.lastName = "Should be less than 20 letters";
    errorItem = true;
  }
  if (!validatePhoneNumber(state.mobileNumber)) {
    errorObj.mobileNumber = "Phone number is invalid";
    errorItem = true;
  }

  if (state.password.length < 8) {
    errorObj.password = "Password should be 8 charactors or above ";
    errorItem = true;
  }

  if (state.password.length > 50) {
    errorObj.password = "Password should be less then 50 charactors ";
    errorItem = true;
  }

  if (state.password !== state.confirmPassword) {
    errorObj.confirmPassword = "Password do not match";
    errorItem = true;
  }

  if (getAge(state.dob) < 18) {
    errorObj.dob = "Age should be minimum 18 years";
    errorItem = true;
  }

  if (!state.selectedGender) {
    errorObj.selectedGender = "Select gender";
    errorItem = true;
  }

  return {
    hasError: errorItem,
    errorObj: errorObj,
  };
};

export const validatePhoneNumber = (phoneNumber) => {
  // Define a regular expression for the specified format
  const regex = /^(03\d{9})$/;

  // Test the input phoneNumber against the regular expression
  const isValid = regex.test(phoneNumber);

  // Return the validation result
  return isValid;
};

export const getAge = (dob) => {
  // Get the current date
  const currentDate = new Date();
  // Create a new date with the date you want to subtract (example: '1990-05-29')
  const subtractedDate = new Date(dob);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - subtractedDate;

  // Convert milliseconds to years
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years
  const differenceInYears = Math.floor(
    differenceInMilliseconds / millisecondsInYear
  );

  return differenceInYears;
};

export const validatePasswordResetForm = (state) => {
  let errorObj = {
    email: "",
    currentPassword: "",
    newPassword: "",
  };
  let hasError = false;

  return {
    errorObj,
    hasError,
  };
};

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const rideRequestStatus = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3, // BOOKING REQUEST REJECTED BY THE DRIVER
  CANCELLED: 4, // BOOKING REQUEST CANCELLED BY THE PASSANGER
  RIDE_CANCELLED: 5, // RIDE CANCELLED BY DRIVER
  EXPIRED: 6,
};

export const renderStatusText = (bookingStatus, name) => {
  if (!bookingStatus) {
    return "Unknown";
  } else if (bookingStatus === rideRequestStatus.PENDING) {
    return "Requested";
  } else if (bookingStatus === rideRequestStatus.ACCEPTED) {
    return "Accepted";
  } else if (bookingStatus === rideRequestStatus.REJECTED) {
    return "Declined by driver";
  } else if (bookingStatus === rideRequestStatus.CANCELLED) {
    return `Cancelled by ${name ? name : "you"}`;
  } else if (bookingStatus === rideRequestStatus.RIDE_CANCELLED) {
    return "Ride cancelled by driver";
  } else if (bookingStatus === rideRequestStatus.EXPIRED) {
    return "Request expired";
  } else {
    return "Unknown";
  }
};

export const BOOKING_REQUEST_STATUS = {
  PENDING: "1",
  ACCEPTED: "2",
  REJECTED: "3",
  CANCELLED: "4",
  RIDE_CANCELLED: "5", // RIDE CANCELLED BY DRIVER
  EXPIRED: "6",
};

export const REPORT_STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  RESOLVED: 3,
};

export const RATINGS = {
  1: "Worst",
  2: "Bad",
  3: "Good",
  4: "Better",
  5: "Excellent",
};

export const RIDE_STATUS_AS_DRIVER = {
  PENDING: "pending", // ride is to be taken
  COMPLETED: "completed", // when ride finished successfully
  ACTIVE: "active", // When ride has started
  CANCELLED: "cancelled", // Driver cancelled the ride
};

export const getFormattedTime = (time) =>
  moment(new Date(time).getTime()).format("hh:mm a");

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

// Removes numbers and characters thats don't make sense
export function cleanText(text) {
  if (text) {
    text = text.replace(/\d+[a-zA-Z]+\d+/g, "");
    return text.replace(/[^a-zA-Z\s]/g, "");
  }
}

export const uploadToCloudinary = async (base64Image, imageName) => {
  try {
    // Create a new FormData instance
    const data = new FormData();

    // Directly append the base64 image string to the `file` parameter
    data.append("file", base64Image);
    data.append("upload_preset", "silkcabs"); // Your Cloudinary upload preset
    data.append("cloud_name", "dqmvr7nnr"); // Your Cloudinary cloud name
    data.append("public_id", imageName); // Optional: Set a custom name for the image

    // Make the request using async/await
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dqmvr7nnr/image/upload",
      {
        method: "POST",
        body: data,
      }
    );

    // Parse the JSON response
    const result = await response.json();

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(
        result.error?.message || "Failed to upload image to Cloudinary"
      );
    }

    // Destructure and return the relevant data
    const { url, public_id } = result;
    return { url, public_id };
  } catch (error) {
    // Provide detailed error handling
    console.error("Error uploading to Cloudinary:", error.message);
    throw error;
  }
};
