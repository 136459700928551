import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import EditForm from "../../Ride/EditRide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditRideModal({
  open,
  ride,
  handleClose,
  title,
  handleUserRides,
  currentUser,
}) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary">{title || "Confirm"}</DialogTitle>
        <DialogContent>
          {
            <EditForm
              ride={ride}
              handleClose={handleClose}
              handleUserRides={handleUserRides}
              currentUser={currentUser}
            />
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}
