import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { BOOKING_REQUEST_STATUS } from "../../Utils";
import { ArrowForward } from "@material-ui/icons";
import moment from "moment";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: #f7f7f9;
  border-radius: 8px;
  margin: 16px;
  max-width: 746px;
  animation: ${fadeIn} 0.5s ease-in-out;

  &:hover {
    background: #f3f3f3;
  }

  @media (max-width: 546px) {
    margin: 5px;
  }
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 8px;
`;

const FromTo = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Date = styled.div`
  color: #777;
`;

const Status = styled.div`
  color: ${(props) =>
    props.status === "Accepted"
      ? "green"
      : props.status === "Requested"
      ? "grey"
      : "red"};
  font-weight: bold;
`;

const ActionButton = styled.button`
  background-color: ${(props) =>
    props.btnType === "confirm" ? "#054752" : "gray"};
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  color: orange;
  .expires-at {
    font-size: 14px;
  }

  @media (max-width: 546px) {
    flex-direction: column;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  .reject {
    background: transparent;
    border: 1px solid;
    color: #136170;
  }

  @media (max-width: 546px) {
    flex-direction: column;
  }
`;

const HorizontalCard = ({
  avatarSrc,
  name,
  from,
  to,
  date,
  status,
  rideId,
  onActionClick,
  statusType,
  requestId,
  seatsRequired,
  expiresAt,
}) => {
  const isPending = statusType === BOOKING_REQUEST_STATUS.PENDING;
  const isActionable = ![
    BOOKING_REQUEST_STATUS.REJECTED,
    BOOKING_REQUEST_STATUS.CANCELLED,
    BOOKING_REQUEST_STATUS.ACCEPTED,
    BOOKING_REQUEST_STATUS.EXPIRED,
    BOOKING_REQUEST_STATUS.RIDE_CANCELLED,
  ].includes(statusType);

  return (
    <CardContainer>
      <Avatar src={avatarSrc} alt="User Avatar" />
      <InfoContainer>
        <Name>{name}</Name>
        <Link
          to={`/active-ride/${rideId}`}
          style={{ textDecoration: "none", color: "#1c1c1c" }}
        >
          <FromTo>
            {from} <ArrowForward fontSize="small" /> {to}
          </FromTo>
        </Link>
        <div className="content">
          <p>
            Seats required:{" "}
            <span
              style={{ fontSize: "large", fontWeight: "bold", color: "teal" }}
            >
              {seatsRequired}
            </span>
          </p>
          <Date>{date}</Date>
          {status !== "Requested" && <Status status={status}>{status}</Status>}
        </div>
      </InfoContainer>
      <ActionWrapper>
        {isActionable && (
          <StyledButtonContainer>
            <ActionButton
              btnType="confirm"
              onClick={() => onActionClick(requestId, "accept")}
            >
              Confirm
            </ActionButton>
            {isPending && (
              <ActionButton
                btnType="reject"
                onClick={() => onActionClick(requestId, "reject")}
              >
                Decline
              </ActionButton>
            )}
          </StyledButtonContainer>
        )}
        {isPending && expiresAt && (
          <p className="expires-at">
            Expires at {moment(expiresAt).format("h:mm A MMM Do")}
          </p>
        )}
      </ActionWrapper>
    </CardContainer>
  );
};

export default HorizontalCard;
