import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Avatar from "../../images/zunair.jpeg";
import GroupInformation from "./Groupinfo";

function AdminInformation() {
  const navigate = useNavigate();
  // useHistory hook ka use karein

  const handleJoinClick = () => {
    navigate("/tour-payment"); // Tour Payment page par navigate karein
  };

  return (
    <Wrapper>
      <HeaderSection>
        <GroupTitle>
          <h3>Trip to North: Naran, Kaghan, Babsur, Naltar, Minapin</h3>
          <h2>July 12, 2025</h2>
        </GroupTitle>
        <HeaderBottom>
          <BottomInfo>
            <img src={Avatar} alt="Avatar" className="admin-avatar" />
            <AdminContacts>
              <ContactDetails>
                <p>
                  <strong>Company:</strong> GoSawari (official)
                </p>
                <p>
                  <strong>Name:</strong> Sikandar Abbas / Jamil Abbas
                </p>
                <p>
                  <strong>Mobile:</strong> 03119303262
                </p>
                <p>
                  <strong>E-mail:</strong> CarSawarioffical@gmail.com
                </p>
              </ContactDetails>
              <ContactDetails>
                <p>
                  <strong>Completed Tours:</strong> 5
                </p>
                <p>
                  <strong>Rating:</strong> 4/5
                </p>
                <p>
                  <strong>Verification:</strong>
                  <span className="verified"> Verified</span>
                </p>
                <p>
                  <strong>Per head:</strong> Rs.25000
                </p>
              </ContactDetails>
            </AdminContacts>
          </BottomInfo>
          <JoinButton>
            <button className="btn" onClick={handleJoinClick}>
              Join Group
            </button>
          </JoinButton>
        </HeaderBottom>
      </HeaderSection>
    </Wrapper>
  );
}

export default AdminInformation;

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

const HeaderSection = styled.div`
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const GroupTitle = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  h3 {
    color: #333;
    font-size: 2rem;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }

  h2 {
    color: #333;
    font-size: 2rem;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
`;

const HeaderBottom = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  flex-direction: column;
`;

const BottomInfo = styled.div`
  display: flex;
  gap: 2rem;
`;

const AdminContacts = styled.div`
  display: flex;
  gap: 4rem;
  align-items: center;
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-size: 1.2rem;
    color: #555;

    .verified {
      color: green;
    }
  }
`;

const JoinButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;

  .btn {
    background: #054752;
    color: white;
    font-size: 1rem;
    border: none;
    text-transform: capitalize;
    border-radius: 23px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
`;
