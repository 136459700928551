import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#054752",
    },
    secondary: {
      main: "rgb(61, 72, 93)",
      light: "rgb(112, 140, 145)",
    },
    text: {
      secondary: "rgb(112, 140, 145)",
      fontFamily: "Roboto",
    },
    alert: {
      main: "red",
    },
  },
});

export default theme;
