import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DatePicker = ({
  required,
  label,
  date,
  handleDateChange,
  departureDate,
  error,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        format="dd/MM/yyyy"
        label={label}
        value={date}
        onChange={handleDateChange}
        autoOk
        disablePast
        required={required}
        inputVariant="outlined"
        fullWidth
        error={error}
        helperText={error ? "Enter a valid date" : ""}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
