import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TourCard = ({ tour }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="tour-image-container">
        <img src={tour.image} alt={tour.title} className="tour-image" />
      </div>
      <div className="tour-details">
        <h2 className="tour-title">{tour.title}</h2>
        {/* <p className="tour-description">{tour.description}</p> */}
        <div className="tour-info">
          <p>
            <strong>Price:</strong> ${tour.price}
          </p>
          <p>
            <strong>Date:</strong> {tour.date}
          </p>
        </div>

        <button
          onClick={() => {
            navigate(`${tour.id}`);
          }}
          className="join-btn"
        >
          {" "}
          Join Group
        </button>
      </div>
    </Wrapper>
  );
};

export default TourCard;

const Wrapper = styled.div`
  border: 1px solid #e0e0e0;

  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: auto;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  }

  .tour-image-container {
    height: 11rem;
    overflow: hidden;

    .tour-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s;
    }
  }

  &:hover .tour-image {
    transform: scale(1.1);
  }

  .tour-details {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .tour-title {
      font-size: 1rem;
      color: #333;
      text-transform: capitalize;
    }

    .tour-description {
      color: #666;
    }

    .tour-info {
      display: flex;
      justify-content: space-between;
      color: #333;
      font-weight: bold;
      font-size: 10px;
      p {
        font-size: 14px;
        color: darkolivegreen;
      }
    }
    .join-btn {
      background: #054752;
      color: white;
      font-size: 14px;
      border: none;
      text-transform: capitalize;
      border-radius: 23px;
      width: 100%;
      max-width: 100px;
      height: 28px;
      cursor: pointer;
    }
  }
`;
