import { IconButton } from "@material-ui/core";
import { CheckCircleOutline, MoreVertRounded } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import api from "../../../services/api";
import { MdOutlinePendingActions, MdPending } from "react-icons/md";
import { REPORT_STATUS } from "../../../Utils";
import { toast } from "react-toastify";

const tableHeaders = [
  "Reason",
  "Description",
  "Reporter",
  "Reportee",
  "status",
  "Image",
  "action",
];

const TableContainer = styled.div`
  overflow-x: auto;
  min-height: 500px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  .isActive {
    border: 1px solid #c3c5c9;
  }
  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  // border: 1px solid #054752;
  font-size: 14px;
  color: grey;
  cursor: pointer;
`;

const ReportTable = ({ reports }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const ddref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (event) => {
    if (ddref.current && !ddref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const toggleDropdown = (item) => {
    setIsOpen(!isOpen);
    setActiveItem(item);
  };
  const onUpdateStatus = async (reportId, status) => {
    const payload = {
      reportId,
      status,
    };
    const response = await api.patch(`/admin/report/`, payload);
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    setIsOpen(false);
  };

  const getStatus = (statusID) => {
    switch (statusID) {
      case REPORT_STATUS.PENDING:
        return <p style={{ color: "grey" }}>Pending</p>;
      case REPORT_STATUS.PROCESSING:
        return <p style={{ color: "blue" }}>Processing</p>;
      case REPORT_STATUS.RESOLVED:
        return <p style={{ color: "green" }}>Resolved</p>;
      default:
        return "Pending";
    }
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {reports?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={row.id == activeItem?.id && isOpen ? "isActive" : null}
            >
              <TableCell>{row.reason}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                {row.reportedBy.first_name}&nbsp;{row.reportedBy.last_name}
              </TableCell>
              <TableCell>
                {row.reportedUser.first_name}&nbsp;{row.reportedUser.last_name}
              </TableCell>
              <TableCell>{getStatus(row.status)}</TableCell>
              <TableCell>
                {row.report_image && (
                  <a
                    href={row.report_image}
                    download={row.report_image}
                    target="_blank"
                  >
                    <img
                      src={row.report_image}
                      height={"50px"}
                      width={"50px"}
                      alt="Report attachment image"
                    />
                  </a>
                )}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => toggleDropdown(row)}>
                  <MoreVertRounded />
                </IconButton>
              </TableCell>
              {activeItem?.id === row.id && (
                <DropdownWrapper ref={ddref}>
                  <DropdownMenu isOpen={isOpen}>
                    <MenuItem
                      onClick={() =>
                        onUpdateStatus(row.id, REPORT_STATUS.PENDING)
                      }
                    >
                      <span>
                        {" "}
                        <MdOutlinePendingActions size={24} />
                      </span>{" "}
                      Set as pending
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onUpdateStatus(row.id, REPORT_STATUS.PROCESSING)
                      }
                    >
                      <span>
                        {" "}
                        <MdPending size={24} />
                      </span>{" "}
                      Set as in-progress
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onUpdateStatus(row.id, REPORT_STATUS.RESOLVED)
                      }
                    >
                      <span>
                        {" "}
                        <CheckCircleOutline size={24} color="green" />
                      </span>{" "}
                      Set as resolved
                    </MenuItem>
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default ReportTable;
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;

const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;
