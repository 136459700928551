import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { COLORS } from "../../assets/colors";

function SettingItem({ item, renderFunction }) {
  return (
    <SettingItemContainer onClick={() => renderFunction(item)}>
      <LeftItem>{item.icon}</LeftItem>
      <RightItem>
        <Typography variant='body1'>
          <p>{item.name}</p>
        </Typography>
        <SettingSubtitle>{item.description}</SettingSubtitle>
      </RightItem>
    </SettingItemContainer>
  );
}

export default SettingItem;
const SettingItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 5px;
  justify-content: space-between;
  box-shadow: 1px 1px ${COLORS.borderColor};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    background-color: #f1f5f9;
  }
`;

const LeftItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 3px;
`;
const RightItem = styled.div`
  widht: 100%;
  padding-left: 10px;
`;
const SettingSubtitle = styled.p`
  color: gray;
  font-size: 11px;
`;
