import React from "react";
import { Button } from "@material-ui/core";
import { styled as materialStyled } from "@material-ui/core/styles";
import { COLORS } from "../../../assets/colors";

const CustomButton = ({
  label = "Submit",
  type,
  onClick,
  disabled,
  bgColor,
  hoverColor,
}) => {
  return (
    <div>
      <StyledButton
        onClick={onClick}
        type={type}
        disabled={disabled}
        bgColor={bgColor}
        hoverColor={hoverColor}
      >
        {label}
      </StyledButton>
    </div>
  );
};

export default CustomButton;

// Correct way to access props for styling
const StyledButton = materialStyled(Button)(({ bgColor, hoverColor }) => ({
  background: bgColor ? bgColor : COLORS.primaryColor,
  border: 0,
  color: "white",
  height: 48,
  padding: "0 30px",
  width: "100%",
  "&:hover": {
    background: hoverColor ? hoverColor : "rgb(5 102 118)",
  },
  "&.Mui-disabled": {
    color: `${COLORS.secondaryTextColor}`,
  },
}));
