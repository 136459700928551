import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import { toast } from "react-toastify";
import api from "../../../../services/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BanUser({
  open,
  handleClose,
  title,
  content,
  cancelBtnText,
  confirmBtnText,
  onConfirm,
  data = {},
}) {
  const [reason, setReason] = useState("");
  const handleSubmit = async () => {
    setReason("");
    if (!reason) {
      toast.error("Please enter the reason");
      return;
    }
    await api
      .post(`admin/user/ban/${data.id}`, {
        reason: reason,
      })
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary">{title || "Report user"}</DialogTitle>
        <DialogContent>
          <Container>
            <Label htmlFor="reason">Reason for Ban:</Label>
            <Input
              type="text"
              id="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
              min={30}
              max={50}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelBtnText}</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {confirmBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
