import React from 'react';
import styled from 'styled-components';

const EditButton = ({ onClick }) => {
  return (
    <ButtonStyled onClick={onClick}>
      <i className="fas fa-edit"></i>
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  i {
    color: #ccc;
    font-size: 1.2rem;
  }

  &:hover i {
    color: #555;
  }
`;

export default EditButton;