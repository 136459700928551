import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { FaCheck, FaFemale, FaMapPin } from "react-icons/fa";
import moment from "moment";
import DriverDetail from "../../components/Driverdetails";
import CoTraveller from "../../components/CoTraveller";
import ConfirmModal from "../../components/models/ConfirmModal";
import { toast } from "react-toastify";
import { Divider } from "../../components/ProfileComponents";
import activeRideIcon from "../../assets/icons/images/car.png";
import { useSelector } from "react-redux";
import FeedbackList from "../../components/FeedbackList";
import ReviewModal from "../../components/models/ReviewModal";
import CircularLoader from "../../components/UI/Loader";
import { Button } from "@material-ui/core";
import CancellationPolicy from "../../components/CancellationPolicy";
import TripTimelineV2 from "../../components/UI/TripTimelineV2";
import { ShareSocial } from "react-share-social";

const ActiveRide = () => {
  const [activeRide, setActiveRide] = useState(null);
  const [markRideAsComplete, setMarkRideAsComplete] = useState(false);
  const [startRide, setStartRide] = useState(false);
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currentTraveller, setCurrentTraveler] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [alreadyReviewed, setAlreadyReviewed] = useState(false);
  const [userType, setUserType] = useState("public");
  const [loading, setLoading] = useState(false);
  const { rideId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchActiveRide();
  }, [rideId]);
  async function fetchActiveRide() {
    try {
      setLoading(true);
      const response = await api.get(`/ride/active-ride/${rideId}`);
      setActiveRide(response.data.data);
      setUserType(response.data.userType);
      setAlreadyReviewed(response.data.alreadyReviewed);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e.response);
      if (e.response.status === 401) {
        navigate("/login");
      }
    }
  }
  const handleMarkRideAsComplete = async (id) => {
    try {
      const response = await api.patch(`/ride/markRideAsComplete/${id}`, {
        status: "completed",
      });
      if (response.data.data) {
        toast.success("Ride Completed");
        fetchActiveRide();
        setMarkRideAsComplete(false);
      }
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };
  const handleStartRide = async (id) => {
    try {
      const response = await api.patch(`/ride/start-ride/${id}`, {
        has_departed: true,
        status: "active",
      });

      if (response.data.data) {
        toast.success("Ride updated");
        fetchActiveRide();
        setStartRide(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data, {
        autoClose: 8000,
      });
    }
  };

  const isCompleted = activeRide?.status === "completed";
  if (loading) {
    return <CircularLoader isLoading={loading} />;
  }
  const iAmDriver = userType == "driver";

  const handleCancelRide = async (rideId) => {
    try {
      const response = await api.patch(`/ride/cancel-ride/${rideId}`, {
        status: "cancelled",
      });

      if (response.data.data) {
        toast.warn("Ride cancelled.");
        fetchActiveRide();
        setShowCancelModal(false);
      }
    } catch (e) {
      toast.error(e.response.data, {
        autoClose: 8000,
      });
    }
  };
  const handleClickOpen = () => {
    if (!isAuthenticated) {
      toast.info("Please login to book your seat");
      navigate(`/login/?redirectUrl=active-ride/${rideId}`);
      return;
    }
    if (currentUser?.gender === "male" && activeRide?.female_only) {
      toast.error("This ride is only for females");
      return;
    }
    navigate("payment");
  };
  const isPassOrDvr = userType == "passanger" || userType == "driver";
  const isFutureRide = moment(activeRide?.departure_date).isSameOrAfter(
    moment().format("YYYY-MM-DD")
  );
  const hasDatePassed = moment(activeRide?.departure_date).isBefore(
    moment().format("YYYY-MM-DD")
  );

  const isFull =
    activeRide?.total_seats === activeRide?.seats_booked ? true : false;

  return (
    <StyledActiveRide>
      {isPassOrDvr && activeRide?.published && (
        <div className={`header ${isCompleted && "completed"}`}>
          <div className={`nav ${isCompleted && "done"}`}>
            <div>
              <span>
                {activeRide?.status === "completed" && (
                  <FaCheck color="#FFFF" />
                )}
                {activeRide?.status === "pending" && <FaMapPin color="red" />}
                {activeRide?.status == "active" && (
                  <img
                    height={"50px"}
                    width={"auto"}
                    src={activeRideIcon}
                    alt="car image"
                  />
                )}
              </span>
              {activeRide?.status === "completed" && (
                <p className="completed-ride">{`RIDE COMPLETED.`}</p>
              )}
              {activeRide?.status === "active" && <p>RIDE IN PROGRESS</p>}
              {activeRide?.status === "cancelled" && <p>RIDE CANCELLED</p>}
              {activeRide?.status === "pending" && !hasDatePassed && (
                <p>RIDE PENDING</p>
              )}
              {activeRide?.status === "pending" && hasDatePassed && (
                <p style={{ color: "orangered" }}>EXPIRED</p>
              )}
            </div>
            {activeRide?.status === "completed" &&
              !iAmDriver &&
              !alreadyReviewed && (
                <p
                  onClick={() => {
                    setCurrentTraveler(activeRide?.User);
                    setShowReviewModal(true);
                  }}
                  className="rateExp"
                >
                  Rate your experience
                </p>
              )}
            <div className="btns">
              <div className="btn-wrapper">
                {activeRide?.status === "pending" &&
                  iAmDriver &&
                  !hasDatePassed && (
                    <>
                      <StyledButton onClick={() => setStartRide(true)}>
                        START JOURNEY
                      </StyledButton>
                    </>
                  )}
                {activeRide?.status === "pending" &&
                  iAmDriver &&
                  !hasDatePassed && (
                    <Button
                      variant="text"
                      onClick={() => setShowCancelModal(true)}
                    >
                      Cancel
                    </Button>
                  )}
              </div>
              {activeRide?.status === "active" && iAmDriver && (
                <StyledButton onClick={() => setMarkRideAsComplete(true)}>
                  MARK RIDE AS COMPLETE
                </StyledButton>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="content">
        <div className="ride">
          <h2>{moment(activeRide?.departure_date).format("MMMM Do, YYYY")}</h2>
          <p className="small-text">
            {activeRide?.leaving_from == activeRide?.heading_to
              ? `${activeRide?.leaving_from} local ride`
              : `${activeRide?.leaving_from} to ${activeRide?.heading_to}`}
          </p>
          <div className="trip-detail">
            <TripTimelineV2
              fromTime={activeRide?.departure_time}
              toTime={activeRide?.arrival_time}
              headingTo={activeRide?.heading_to}
              leavingFrom={activeRide?.leaving_from}
              drop={activeRide?.drop_location}
              pick={activeRide?.pickup_location}
              startLat={activeRide?.start_lat}
              startLng={activeRide?.start_lng}
              endLat={activeRide?.end_lat}
              endLng={activeRide?.end_lng}
              rideId={activeRide?.id}
              duration={activeRide?.duration.substring(0, 13)}
            />
          </div>
          <Divider style={{ marginTop: "1rem" }}>
            <hr />
          </Divider>
          <div className="fare">
            <span>Total price for 1 passanger</span>
            <p>Rs.{activeRide?.ticket}</p>
          </div>
          <Divider>
            <hr />
          </Divider>
          {activeRide?.female_only && (
            <StyledFemaleOnly>
              <FaFemale size={22} />
              This ride is available only for the females
            </StyledFemaleOnly>
          )}
          <div className="driver-detail">
            <DriverDetail
              driver={activeRide?.User}
              vehicle={activeRide?.Vehicle}
              autoApproval={activeRide?.automatic_approval}
            />
          </div>
          <Divider>
            <hr />
          </Divider>

          <div className="passanger-detail">
            <CoTraveller
              travellers={activeRide?.RideRequests}
              iAmDriver={iAmDriver}
              rideStatus={activeRide?.status}
              rideId={activeRide?.id}
              currentUser={currentUser}
            />
          </div>
          <Divider>
            <hr />
          </Divider>
          <div className="cancellation-policy">
            {/* <CancellationPolicy /> */}
            <h4 className="refund-heading">Refunding and Cancellation</h4>
            <p>
              GoSawari charges an additional 10% of the fare from passangers
              which can be refunded based on the GoSawari's refunding and
              cancellation policy.
            </p>
            <span>See</span> &nbsp;
            <Link
              to="/gosawari-refund-policy"
              style={{ color: COLORS.linkColor }}
            >
              Refunding and Cancellation policy
            </Link>
          </div>
          <Divider>
            <hr />
          </Divider>
          {isPassOrDvr && (
            <div className="feedback">
              <FeedbackList rideId={rideId} />
            </div>
          )}
          {activeRide?.status == "cancelled" && (
            <p style={{ color: "red" }}>
              Apologies! This ride is cancelled by the carpool driver, if you
              had a booking, you will be refunded according to the cancellation
              policy within 1 to 5 working days.
              <span>
                &nbsp;
                <Link className="link" to="/my-refunds">
                  Please visit to process your refund.
                </Link>
              </span>
            </p>
          )}
          {!isPassOrDvr && activeRide?.status !== "cancelled" && !isFull && (
            <div className="booking">
              <StyledBookButton onClick={handleClickOpen}>
                {activeRide?.automatic_approval
                  ? "Book Seat"
                  : "Request to book"}
              </StyledBookButton>
            </div>
          )}
        </div>
        {startRide && (
          <ConfirmModal
            title={"Start your journey?"}
            content={
              "Make sure that you have collected the fare from each passanger.Don't forget to mark the ride as complete once you reach your destination."
            }
            open={startRide}
            handleClose={() => setStartRide(false)}
            confirmBtnText={"Confirm"}
            cancelBtnText={"Cancel"}
            onConfirm={() => {
              handleStartRide(activeRide.id);
            }}
          />
        )}
        {markRideAsComplete && (
          <ConfirmModal
            title={"Completed your journey?"}
            content={"Have you reached your destination successfully?"}
            open={markRideAsComplete}
            handleClose={() => setMarkRideAsComplete(false)}
            confirmBtnText={"Confirm"}
            cancelBtnText={"Cancel"}
            onConfirm={() => {
              handleMarkRideAsComplete(activeRide.id);
            }}
          />
        )}
        {showCancelModal && (
          <ConfirmModal
            title={"Cancel your ride?"}
            content={
              "Are you sure that you want to cancel this ride?.This action can not be undone.Frequent Cancellation of your rides can lead to low ranking of your rides in the search list. "
            }
            open={showCancelModal}
            handleClose={() => setShowCancelModal(false)}
            confirmBtnText={"Confirm"}
            cancelBtnText={"Cancel"}
            onConfirm={() => {
              handleCancelRide(activeRide.id);
            }}
          />
        )}
        {showReviewModal && (
          <ReviewModal
            handleClose={() => setShowReviewModal(false)}
            open={showReviewModal}
            traveller={currentTraveller}
            rideId={activeRide.id}
            rideStatus={activeRide.status}
            iAmDriver={iAmDriver}
            currentUser={currentUser}
          />
        )}
        <div className="social-share">
          <ShareSocial
            title="Share this ride"
            url={window.location.href}
            socialTypes={["facebook", "twitter", "linkedin"]}
            style={shareContentStyle}
          />
        </div>
      </div>
    </StyledActiveRide>
  );
};

const shareContentStyle = {
  root: {
    borderRadius: 3,
    border: 0,
    color: "gray",
  },
  copyContainer: {
    background: "#ccc",
    color: "gray",
    border: "none",
  },
  title: {
    color: "black",
    fontStyle: "italic",
  },
};

export default ActiveRide;
const StyledButton = styled.button`
  border-radius: 8px;
  background: darkslategrey;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: none;
  color: white;
  :hover {
    background: ${(props) => (props.completed ? "lightgrey" : "#326871")};
  }
`;
const StyledBookButton = styled.button`
  border-radius: 8px;
  background: darkslategrey;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 1rem;
  border: none;
  color: white;
  :hover {
    background: ${(props) => (props.completed ? "lightgrey" : "#326871")};
  }
`;
const StyledActiveRide = styled.div`
  height: auto;
  width: 100%;
  .completed {
    background: #0aba0a !important;
    color: ghostwhite !important;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 1;
    background: ${COLORS.lightBackgroundColor};
    .done {
      justify-content: center !important;
      margin: auto;
      max-width: 864px;
      align-items: center;
      height: 4rem;
      width: 100%;
      display: flex;
      padding: 0 1rem;
    }
    .nav {
      margin: auto;
      max-width: 864px;
      align-items: center;
      height: 4rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
      .btns {
        .btn-wrapper {
          display: flex;
        }
      }
      .rateExp {
        border: 1px solid ghostwhite;
        padding: 0.5rem;
        border-radius: 8px;
        cursor: pointer;
        color: ghostwhite;
        font-weight:400;

        :hover {
          background: ghostwhite;
          color: #484848;
        }
        @media(max-width: 546px) {
          border: 1px solid;
          padding: 0.5rem;
          border-radius: 8px;
          cursor: pointer;
          font-size: small;
        }
      }
      .completed-ride {
        color: white;
        letter-spacing: 1px;
        font-size: larger;
        @media(max-width: 600px){
        font-size: medium;
        }
        @media(max-width: 440px){
          font-size: small;
          }
}
      }

      div:first-child {
        color: orange;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 1.2rem;
        svg {
          color: gray;
          font-size: 2rem;
          @media(max-width: 440px){
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .content {
    height: 100%;
    width: 100%;
    max-width: 864px;
    border-top: 1px solid gray;
    margin: auto;
    .social-share {
      margin-bottom:4rem;
    }
    .ride {
      margin-top: 4rem;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: normal;
      align-items: center;
      padding: 2rem 1rem;
      @media(max-width: 546px){
        padding: 2rem 1rem 4rem 1rem;
      }
      .small-text {
        margin-top: 1rem;
        color: grey;
        font-size: 16px;
      }
      .fare {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding: 0.5rem;
        @media(max-width: 440px){
          gap: 1rem;
        }

        span {
          color: ${COLORS.secondaryTextColor};
        }
        p {
          font-size: 2rem;
          color: ${COLORS.secondaryColor};
          font-weight: 600;
          @media(max-width: 440px){
            font-size: 1.2rem;
          }
        }
      }
      .trip-detail {
        // width: 100
        margin-top: 1rem;
      }
      .driver-detail {
        width: 100%;
      }
      .passanger-detail {
        width: 100%;
      }
      .cancellation-policy {
        width: 75%;
        padding:1rem;
        @media(max-width: 600px){
            width: 100% !important;
          }

        .refund-heading {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: ${COLORS.primaryColor};
        }
        p{
          color:${COLORS.secondaryTextColor};
          margin-bottom:1rem;
        }
        span{
          color:${COLORS.secondaryTextColor};
        }
      }
      .feedback {
        width: 100%;
      }
      .booking {
        max-width: 300px;
        margin-bottom: 2rem;
        @media (max-width: 600px) {
          margin-bottom: 6rem;
        }
      }
      h2 {
        font-size: 34px;
        @media (max-width: 600px) {
          font-size: 30px;
        }
        @media (max-width: 400px) {
          font-size: 24px;
        }
      }
     
    }
  }
`;
const StyledFemaleOnly = styled.div`
  border: 1px solid gainsboro;
  padding: 1rem;
  background: pink;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  width: 75%;
  display: flex;
  align-items: center;
  @media (max-width: 546px) {
    font-size: small;
  }
`;
