import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../assets/colors";
const PayNowButtonWrapper = styled.button`
  background-color: #054752;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 230px;
  &:hover {
    background-color: ${COLORS.backgroundBoxColor}; /* Darker green on hover */
  }
`;
const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;
const PayNowButton = ({ onClick, label, loading }) => {
  return (
    <PayNowButtonWrapper onClick={onClick}>
      <Icon icon={loading ? faSpinner : faCreditCard} />
      {label}
    </PayNowButtonWrapper>
  );
};

export default PayNowButton;
