import React from "react";
import styled from "styled-components";

const PageContainer = (props) => {
  return <StyledContainer>{props.children}</StyledContainer>;
};

export default PageContainer;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;
