import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <strong>Day 1</strong> &nbsp;Receive at the lahore Terminal
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            A representative from our company will receive you at Islamabad
            International Airport. The representative will hold a card with your
            Name and our company name in their hand. You will be transferred to
            the hotel in Islamabad. Free time for rest in Islamabad Please
            notify us if you do not need an airport transfer Hotel check-in:
            2:00 PM
            <br />
            Note: Pre-tour accommodation is not included
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong> Day 2</strong>&nbsp; Tour to Gilgit
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="textPrimary">
            Take the morning flight to Gilgit Your guide will receive you at
            Gilgit Airport Road trip to Hunza (2 hours) Stopover at Rakaposhi's
            viewpoint Reach Hunza by the afternoon Hotel transfer Visit Baltit
            Fort Sunset at Duikar Valley to get a panoramic view of central
            Hunza Free time to Explore the tourist street in Hunza Overnight
            stay in central Hunza
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
