import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(0.5),
    },
  },
}));

function PaginationComp({ count, setCurrentPage, currentPage }) {
  const handleChange = (e, p) => {
    setCurrentPage(p);
  };
  const classes = useStyles();

  return (
    <PaginationContainer>
      <div className={classes.root}>
        <Pagination
          count={count}
          color="primary"
          size="large"
          shape="round"
          onChange={handleChange}
          page={currentPage}
        />
      </div>
    </PaginationContainer>
  );
}

export default PaginationComp;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 100%;
  margin-bottom: 2rem;
  @media (max-width: 546px) {
    margin-bottom: 5rem;
  }
  align-content: center;
`;
