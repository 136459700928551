import React, { useContext, useEffect, useState } from "react";
import SearchForm from "../SearchForm";
import { GlobalContext } from "../../contexts/globalContext";
import styled from "styled-components";
import HeroImage from "../../images/heroImage2.jpg";
import Smallbg from "../../assets/images/smallbg.png";
import { getRandomInt } from "../../Utils";

const opts = ["Find", "Offer", "Intercity", "Local"];

const HeroSection = () => {
  const [changingText, setChangingText] = useState("Find");

  const { searchFields, setSearchFields } = useContext(GlobalContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setChangingText(opts[getRandomInt(3)]);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledContainer>
      <div className="hero-box">
        {/* <div className="dua">
          <marquee className="safar" width="60%" direction="right">
            سُبْحَانَ الَّذِي سَخَّرَ لَنَا هَذَا وَمَا كُنَّا لَهُ مُقْرِنِينَ
            وَإِنَّا إِلَى رَبِّنَا لَمُنقَلِبُونَ
          </marquee>
        </div> */}
        {/* <h2>
          <span className="changing-text">{changingText}</span> Carpool rides
          across Pakistan
        </h2> */}
        <h2>Pick intercity rides at low prices</h2>
        <div className="hero-subtitle">
          Connecting drivers with empty seats to passengers seeking rides
          throughout Pakistan.{" "}
        </div>
      </div>
      <div className="search-box">
        <SearchForm
          searchFields={searchFields}
          setSearchFields={setSearchFields}
        />
      </div>
    </StyledContainer>
  );
};

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00e5ff;
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  min-height: 52vh;
  background: url(${HeroImage});
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 546px) {
    background: url(${Smallbg});
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .hero-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;

    h2 {
      height: 2rem;
      width: fit-content;
      font-size: xxx-large;
      color: honeydew;
      font-weight: 600;
      transition: color 0.2s ease;
    }
    .dua {
      padding-bottom: 2rem;

      .safar {
        font-size: 1.5rem;
        color: yellow;
      }
    }

    .changing-text {
      animation: changeText 4s linear infinite alternate;
    }

    @keyframes changeText {
      0% {
        color: white; /* Change color or any other style you want */
      }
      100% {
        color: yellowgreen; /* Change to a different color */
      }
    }

    .hero-subtitle {
      color: #ffffff;
      font-weight: 500;
      font-size: 1.2rem;
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .arabic-text {
    color: #ffd700;
    font-size: 0.9rem;
  }

  .search-box {
    margin: 40px;
  }

  @media (max-width: 768px) {
    .hero-box {
      .hero-title {
        font-size: x-large;
        font-weight: 600;
      }
    }

    .search-box {
      margin: 20px;
    }
  }
`;

export default HeroSection;
