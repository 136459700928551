import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function SilkCabsFooter() {
  return (
    <Container>
      <div className="footer-container">
        <div className="footer">
          <ul className="footer-list">
            <li>
              <Link to={"/about-us"} className="footer-link">
                About Us
              </Link>
            </li>
            <li style={{ marginTop: "1rem" }}>
              <Link to={"/gosawari-refund-policy"} className="footer-link">
                Refund and Cancellation
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer">
          <ul className="footer-list">
            <li>
              <Link to={"/contact-us"} className="footer-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer">
          <ul className="footer-list">
            <li>
              <Link to={"/terms-conditions"} className="footer-link">
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer">
          <ul className="footer-list">
            <li>
              <Link to={"/privacy-policy"} className="footer-link">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>

        <div className="copyrights">
          <p>
            <span>&copy;</span> 2024 GoSawari.com. All rights reserved.
          </p>
          {/* <p>NTN:E866598-6</p> */}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem;
  background-color: #ededed;
  width: 100%;
  height: auto;
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ededed;
    padding: 3rem 0;
    display: flex;
    justify-content: space-around;
    @media (max-width: 546px) {
      flex-direction: row;
      gap: 1rem;
      padding: 7px;
      flex-wrap: wrap;
    }
  }

  .footer {
    flex: inline;
  }

  .footer-list {
    list-style: none;
    padding: 0;
  }

  .footer-link {
    text-decoration: none;
    color: #00b5ff;
    cursor: pointer;
    display: inline-block;
    transition: transform 0.3s ease;

    &:hover {
      text-decoration: underline;
      transform: rotate(-10deg);
    }
  }

  .copyright {
    padding: 10px;
    display: inline-block;

    p {
      font-size: 1rem;
      color: #798e9b;
      span {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
`;
