import loginReducer from './redux/reducers/loginReducer' 
import registerReducer from "./redux/reducers/registerReducer";
import resetPasswordReducer from "./redux/reducers/resetPasswordReducer";
import authReducer from "./redux/reducers/authReducer";
import otpReducer from "./redux/reducers/otpReducer";
import publishRides from "./redux/reducers/publishRides";
import ridesReducer from "./redux/reducers/ridesReducer";
import citiesReducer from "./redux/reducers/citiesReducer";
import publishRideInfoReducer from './redux/reducers/publishRideInfo';
import myBookingsReducer from './redux/reducers/myBookings';
import myRideRequestsReducer from './redux/reducers/myRideRequests';
import { LOGOUT_CURRENT_USER } from './redux/constants';
import { setAuthHeader } from './services/api';
const { combineReducers } = require("redux");


export const appReducer = combineReducers({
  register: registerReducer,
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  auth: authReducer,
  otp: otpReducer,
  publish: publishRides,
  rides: ridesReducer,
  cities: citiesReducer,
  publishRideInfo: publishRideInfoReducer,
  myBookings: myBookingsReducer,
  myRideRequests: myRideRequestsReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_CURRENT_USER) {
    localStorage.removeItem('authToken');
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}