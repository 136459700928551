import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../../services/api";

const UnreadMessageCount = ({ userId, senderMessagesCleared }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getUnreadCount() {
      setLoading(true);
      try {
        const response = await api.get(`/chat/userUnread/${userId}`);
        if (response.status == 200) {
          setCount(response.data.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    getUnreadCount();
  }, [userId]);
  useEffect(() => {
    if (userId === senderMessagesCleared) {
      setCount(0);
    }
  }, [senderMessagesCleared, userId]);
  return (
    <StyledWrapper count={count}>
      {loading ? "..." : <p>{count}</p>}
    </StyledWrapper>
  );
};

export default UnreadMessageCount;

const StyledWrapper = styled.div`
  background: #054752;
  height: 24px;
  width: 24px;
  color: white;
  border-radius: 50%;
  text-align: center;
  margin-left: 1.5rem;
  display: ${(props) => (props.count > 0 ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  p {
    color: white;
    font-weight: bold;
  }
`;
