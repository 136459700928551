import React, { useState } from "react";
import styled from "styled-components";
import MyModal from "./Modal";
import SilkPort from "../../components/SilkPort";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 2rem;
`;

const Trending = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <SilkPort openSearchModal={() => setIsOpen(true)} />
      {isOpen && <MyModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </Container>
  );
};

export default Trending;
