///OTP actions
import * as types from "./constants";
import jwt_decode from "jwt-decode";
import api from "../services/api";
import { toast } from "react-toastify";
import axios from "axios";

export const otpCodeVerify = (otpCode, email) => async (dispatch) => {
  dispatch({
    type: types.OTP_REQUEST,
  });
  await api
    .post("/auth/verifyOTP", {
      otp_code: otpCode,
      email: email,
    })
    .then((result) => {
      if (result.status === 200) {
        const token = result.data;
        localStorage.setItem("authToken", token);
        const decoded = jwt_decode(token);

        dispatch({
          type: types.SET_CURRENT_USER,
          payload: { ...decoded, token },
        });

        dispatch({
          type: types.LOGIN_SUCCESS,
        });
        dispatch({
          type: types.OTP_SUCCESS,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.OTP_FAILED,
        payload: error.response.data,
      });
    });
};
export const cleanOTPForm = () => async (dispatch) => {
  dispatch({
    type: types.OTP_CLEAN_UP,
  });
};

///Ride Shareing

export const getAllRides = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ALL_RIDES_REQUEST,
    });
    const response = await api.get("/ride");
    dispatch({
      type: types.GET_ALL_RIDES_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: types.GET_ALL_RIDES_FAIL,
      payload: e.message,
    });
  }
};

export const searchRides = (queryString) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ALL_RIDES_REQUEST,
    });
    const response = await api.get(`/ride/search/?${queryString}`);
    dispatch({
      type: types.GET_ALL_RIDES_SUCCESS,
      payload: { data: response.data.data, count: response.data.count },
    });
  } catch (e) {
    dispatch({
      type: types.GET_ALL_RIDES_FAIL,
      payload: e.message,
    });
  }
};

////VehicleInfo

export const addVehicleRequest = (data) => async (dispatch) => {
  dispatch({
    type: types.ADD_VEHICLE_REQUEST,
  });
};

////HeroSection

export const getCities = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ALL_CITIES_REQUEST,
    });
    const response = await api.get("/location/cities");
    dispatch({
      type: types.GET_ALL_CITIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: types.GET_ALL_CITIES_FAILED,
      payload: e.message,
    });
  }
};

////Login

export const login = (data) => async (dispatch) => {
  dispatch({
    type: types.LOGIN_REQUEST,
  });
  dispatch({
    type: types.LOGIN_FAILED,
    payload: false,
  });
  await api
    .post("/auth/login", {
      emailOrPhone: data.emailOrPhone,
      password: data.password,
    })
    .then((result) => {
      if (result.status === 200) {
        const token = result.data.data;
        localStorage.setItem("authToken", token);
        const decoded = jwt_decode(token);

        dispatch({
          type: types.SET_CURRENT_USER,
          payload: { ...decoded, token },
        });

        dispatch({
          type: types.LOGIN_SUCCESS,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.LOGIN_FAILED,
        payload: error.response.data,
      });
    });
};
export const cleanLoginForm = () => async (dispatch) => {
  dispatch({
    type: types.LOGIN_CLEAN_UP,
  });
};

///published ride

export const publishRideRequest = (data, userid) => async (dispatch) => {
  dispatch({
    type: types.PUBLISH_RIDE_REQUEST,
  });

  await api
    .post("/ride/create", {
      leaving_from: data.leavingFrom,
      heading_to: data.headingTo,
      route: data.route,
      departure_date: data.date,
      departure_time: data.time,
      user_id: userid,
      ticket: data.ticket,
      pickup_location: data.pickupLocation,
      drop_location: data.dropLocation,
    })
    .then((result) => {
      dispatch({
        type: types.PUBLISH_RIDE_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.PUBLISH_RIDE_FAILED,
        payload: error.response.data,
      });
    });
};
//add current Ride
export const getCurrentRideInfo = (userId, rideId) => async (dispatch) => {
  try {
    dispatch({
      type: types.PUBLISH_RIDE_INFO_REQUEST,
    });
    const response = await api.get(`/ride/currentRideInfo/${userId}/${rideId}`);
    dispatch({
      type: types.PUBLISH_RIDE_INFO_SUCCESS,
      payload: {
        currentRideInfo: response.data.currentRideInfo,
        userVehicles: response.data.userVehicles,
      },
    });
  } catch (e) {
    dispatch({
      type: types.PUBLISH_RIDE_INFO_SUCCESS,
      payload: { error: e.message },
    });
  }
};
//////add vehicle information/
export const addVehicle = (data, userid) => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_VEHICLE_REQUEST,
    });
    const response = await api.post("/vehicle/create", {
      vehicle_name: data.vehicleName,
      vehicle_model: data.vehicleModel,
      vehicle_type: data.type,
      vehicle_color: data.color,
      owner_id: userid,
      ride_id: data.rideId,
      capacity: data.vehicleCapacity,
      picture: data.vehicleImage,
    });
    dispatch({
      type: types.ADD_VEHICLE_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: types.ADD_NEW_VEHICLE_INFO,
      payload: response.data.data,
    });
    toast.success("Added a new car successfully");
    return true;
  } catch (error) {
    toast.error(error.response.data);
  }
};

export const registerRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: types.REGISTER_REQUEST,
    });
    await api
      .post(`/auth/register`, {
        first_name: data.firstName,
        last_name: data.lastName,
        mobile_number: data.mobileNumber,
        email: data.email,
        password: data.password,
      })
      .then((ress) => {
        if (ress.status == 200) {
          dispatch({
            type: types.REGISTER_SUCCESS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  } catch (err) {}
};

export const cleanRegisterForm = () => async (dispatch) => {
  dispatch({
    type: types.REGISTER_CLEAN_UP,
  });
};

///password reset
export const passwordResetRequest = (data) => (dispatch) => {
  dispatch({
    type: types.PASSWORDRESET_REQUEST,
  });
};

export const getMyBookings = (status, limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BOOKINGS_REQUEST,
    });
    const response = await api.get(`rideRequests/getMyBookings/${status}`, {
      params: { limit, offset },
    });

    dispatch({
      type: types.GET_BOOKINGS_SUCCESS,
      payload: {
        data: response.data.data,
        count: response.data.count,
      },
    });
  } catch (e) {
    dispatch({
      type: types.GET_BOOKINGS_FAILED,
      payload: { error: e.message },
    });
  }
};

export const getMyRideRequests =
  (status, limit, offset) => async (dispatch) => {
    try {
      dispatch({
        type: types.GET_MY_RIDE_REQUEST,
      });
      const response = await api.get(
        `rideRequests/getMyRideRequests/${status}`,
        {
          params: { limit, offset },
        }
      );
      dispatch({
        type: types.GET_MY_RIDE_SUCCESS,
        payload: {
          data: response.data.data,
          count: response.data.count,
        },
      });
    } catch (e) {
      dispatch({
        type: types.GET_MY_RIDE_FAILED,
        payload: { error: e.message },
      });
    }
  };
