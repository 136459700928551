import styled from "styled-components";
import { FaStar } from "react-icons/fa";
import { COLORS } from "../../assets/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Header = styled.div`
  align: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  background-color: #054752d1;
  height: 9rem;
  h1 {
    color: white;
    margin: auto;
    font-weight: 400;
  }
  p {
    color: ${COLORS.secondaryColor};
  }
`;
export const Content = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
  max-width: 767px;
  .score {
    height: auto;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
    .overall-rating {
      font-size: 60px;
      height: 100%;
      font-weight: 600;
      -webkit-letter-spacing: 2px;
      -moz-letter-spacing: 2px;
      -ms-letter-spacing: 2px;
      letter-spacing: 2px;
      color: ${COLORS.secondaryColor};
    }
    .total-ratings {
      font-size: 20px;
      -webkit-letter-spacing: 1px;
      -moz-letter-spacing: 1px;
      -ms-letter-spacing: 1px;
      letter-spacing: 1px;
      color: ${COLORS.secondaryTextColor};
      margin-top: 16px;
    }
  }
  .divider {
    color: ${COLORS.borderColor};
    margin: 0 2rem;
  }

  .review {
    min-height: auto;
    width: 100%;
    padding: 1rem;
    .user-info {
      height: 3rem;
      padding: 2rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      :hover {
        background: ${COLORS.lightBackgroundColor};
        cursor: pointer;
        border-radius: 10px;
      }
      h1 {
        font-size: 22px;
        color: ${COLORS.primaryColor};
        font-weight: 600;
        text-transform: capitalize;
      }
      .avatar {
        display: flex;
        align-items: center;
        width: 5rem;
        justify-content: space-between;
      }
    }
    .review-text {
      height: auto;
      width: 100%;
      padding: 0.5rem 2rem;
      h3 {
        font-size: 18px;
        color: ${COLORS.primaryColor};
        font-weight: 600;
        margin-bottom: 8px;
      }
      p {
        color: ${COLORS.secondaryTextColor};
        margin-bottom: 1rem;
      }
    }
  }
`;
