import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { COLORS } from "../../assets/colors";
import TripTimeline from "../UI/TripTimeline";
import { FaArrowRight } from "react-icons/fa";
import styled from "styled-components";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, getAge } from "../../Utils";
import { NavigateNext, VerifiedUser } from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";
import TripTimelineV2 from "../UI/TripTimelineV2";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  Boxstyles: {
    position: "relative",
    maxWidth: "445px",
    borderRadius: theme.spacing(1),
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 1pt 4pt, rgba(0, 0, 0, 0.08) 0px 2pt 8pt",
    transition: "box-shadow 200ms ease-in 0s",
    backgroundColor: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      minWidth: "445px",
      minHeight: "264px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
      minWidth: "338px",
    },

    flexGrow: 1,
    "&:hover": {
      cursor: "pointer",
      boxShadow:
        "rgba(0, 0, 0, 0.08) 0px 2pt 8pt, rgba(0, 0, 0, 0.08) 0px 2pt 16pt",
    },
  },
  CardFooter: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: theme.spacing(2),
  },
  flexItem: {
    margin: theme.spacing(1),
    position: "relative",
  },
  verifiedBage: {
    position: "absolute",
    bottom: "4px",
    right: "-8px",
    height: "22px",
    width: "auto",
    borderRadius: "50%",
    border: "1px solid deepskyblue",
    background: "white",
  },
  location: {
    color: `${COLORS.secondaryTextColor}`,
    fontSize: "12px",
  },
  fareStyles: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  toc: {
    display: "flex",
    flex: "auto",
  },
}));

const ArrowRightIcon = styled(FaArrowRight)`
  padding-top: 5px;
`;

export default function Ride({ ride, isMine = false }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isFull = ride?.total_seats === ride?.seats_booked ? true : false;
  const renderTicketPrice = (price) => {
    if (isFull) {
      return <p style={{ color: "red" }}>FULL</p>;
    } else return `Rs.${price}`;
  };
  const handleOnRideClick = () => {
    if (ride.status === "active" || ride.has_departed) {
      toast.info("This ride has already departed");
    } else {
      navigate(`/active-ride/${ride.id}`);
    }
  };
  console.log(ride.User, "userinfo");
  const onCheckDetails = () => {
    navigate(`/active-ride/${ride.id}`);
  };
  const isVerified = ride?.User?.cnic_verified && ride?.User?.otp_verified;
  console.log("isVerified", isVerified);
  return (
    <Box className={classes.Boxstyles}>
      {ride?.status == "active" && (
        <OverlayStyled>Already departed</OverlayStyled>
      )}

      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        alignContent="space-between"
      >
        <Grid item container xs={12} onClick={handleOnRideClick}>
          <Grid item xs={4} container alignContent="space-around">
            <Box padding={1}>
              <Typography
                variant="body1"
                style={{ fontSize: "14px" }}
                color="textSecondary"
              >
                {moment(ride?.departure_date).format("MMM Do, YYYY")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            onClick={handleOnRideClick}
            item
            xs={8}
            container
            justifyContent="flex-end"
          >
            <Box padding={1}>
              <Typography
                variant="body1"
                style={{ fontSize: "14px" }}
                color="textSecondary"
              >
                {ride?.leaving_from}{" "}
                <span>
                  <ArrowRightIcon />
                </span>{" "}
                {ride?.heading_to}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid
            item
            xs={9}
            container
            justify="flex-start"
            alignContent="space-between"
          >
            <Box pl={1}>
              <TripTimelineV2
                fromTime={ride?.departure_time}
                toTime={ride?.arrival_time}
                headingTo={ride?.heading_to}
                drop={ride?.drop_location}
                pick={ride?.pickup_location}
                leavingFrom={ride?.leaving_from}
                startLat={ride?.start_lat}
                startLng={ride?.start_lng}
                endLat={ride?.end_lat}
                endLng={ride?.end_lng}
                rideId={ride?.id}
                duration={ride?.duration.substring(0, 13)}
              />
            </Box>
          </Grid>
          <Grid item xs={3} onClick={handleOnRideClick}>
            <Box className={classes.fareStyles}>
              <Typography variant="h6" component="h1" color="secondary">
                {renderTicketPrice(ride?.ticket)}
              </Typography>
              <p className={classes.location}>&nbsp;{ride?.distance}</p>

              <p className={classes.location}>
                {ride?.route?.substring(0, 20)}.
              </p>
              {ride?.female_only && (
                <Tooltip title={"Only for females"}>
                  <div style={{ display: "flex", width: "max-content" }}>
                    <p style={{ color: "hotpink", fontSize: "small" }}>
                      female only
                    </p>
                  </div>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
        <div style={{ marginTop: "1rem" }}></div>
        <Grid item container xs={12} onClick={handleOnRideClick}>
          <Grid item xs={7}>
            <Box maxWidth="xs" className={classes.CardFooter}>
              <Box className={classes.flexItem}>
                <Avatar
                  style={{
                    border: isVerified
                      ? `2px solid ${COLORS.linkColor}`
                      : "none",
                  }}
                  src={ride?.User.avatar_url}
                />
                {isVerified && (
                  <span className={classes.verifiedBage}>
                    <VerifiedUser
                      style={{ color: COLORS.linkColor }}
                      fontSize="small"
                    />
                  </span>
                )}
              </Box>
              <Box className={classes.flexItem}>
                <Typography>
                  {capitalizeFirstLetter(ride?.User?.first_name) || "Some User"}
                  &nbsp;
                  {capitalizeFirstLetter(ride?.User?.last_name)}
                </Typography>
                {ride?.User?.averageRating && (
                  <Box display={"flex"} alignItems="center">
                    <Typography
                      variant="p"
                      component="span"
                      style={{ marginRight: "2px" }}
                    >
                      <StarIcon fontSize="small" style={{ color: "gold" }} />
                    </Typography>
                    <Typography
                      variant="p"
                      component="span"
                      color="textSecondary"
                      style={{ fontSize: "small", marginBottom: "2px" }}
                    >
                      {ride?.User?.averageRating}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {isMine ? (
              <Box display="flex" onClick={handleOnRideClick}>
                <Button
                  variant="text"
                  color="primary"
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  Edit
                </Button>
                <Button
                  variant="text"
                  style={{ color: "red" }}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  Delete
                </Button>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="flex-end"
                mr={2}
                onClick={handleOnRideClick}
                alignItems={"flex-end"}
                flexDirection={"column"}
              >
                {/* {ride?.female_only && ride?.status != "active" && (
                  <Tooltip
                    title={"Only for females"}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                  >
                    <div style={{ display: "flex" }}>
                      <p style={{ color: "orange" }}>female only</p>
                    </div>
                  </Tooltip>
                )} */}
                <p>{ride?.Vehicle?.vehicle_name.substring(0, 20)}</p>
                <p style={{ color: "gray" }}>
                  {ride?.total_seats - ride?.seats_booked == 0
                    ? "No"
                    : ride?.total_seats - ride?.seats_booked}{" "}
                  seats left
                </p>

                {ride?.status != "completed" && ride?.status != "active" && (
                  <Tooltip
                    title="View ride details"
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    placement="left-start"
                  >
                    <IconButton
                      size="medium"
                      color="primary"
                      aria-label="next"
                      onClick={onCheckDetails}
                      style={{
                        border: `1px solid ${COLORS.borderColor}`,
                        marginBottom: "10px",
                      }}
                    >
                      <NavigateNext fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const OverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  font-size: small;
  border-radius: 8px;
  background: ${COLORS.lightBackgroundColor};
  opacity: 0.6;
  justify-content: end;
  display: flex;
  padding: 1rem;
  color: red;
  align-items: end;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  p {
    font-size: small;
    color: gray;
  }
`;
