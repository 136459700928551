import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Divider } from "../ProfileComponents";
import { FaCoins, FaSmoking, FaUsers } from "react-icons/fa";

const Filters = ({
  handleSort,
  sortBy,
  handleDeparturetime,
  departureTime,
  amenity,
  handleAmenity,
  clearAll,
  page = "somepage",
}) => {
  return (
    <FilterWrapper page={page}>
      <div className="filter-items">
        <div className="header">
          <h3>Sort by</h3>
          <p className="link clear" onClick={clearAll}>
            Clear All
          </p>
        </div>
        <div className="item" onClick={(e) => handleSort("earliest")}>
          <span
            style={{ fontWeight: sortBy == "earliest" ? "bold" : "normal" }}
          >
            <AiOutlineClockCircle />
            <span>Earliest departure</span>
          </span>
          <span>
            <input
              type="radio"
              value="earliest"
              checked={sortBy == "earliest"}
            />
          </span>
        </div>
        <div className="item" onClick={(e) => handleSort("price")}>
          <span style={{ fontWeight: sortBy == "price" ? "bold" : "normal" }}>
            <FaCoins />
            <span>Lowest price</span>
          </span>
          <span>
            <input type="radio" value="price" checked={sortBy == "price"} />
          </span>
        </div>
        <Divider>
          <hr />
        </Divider>
        <h3>Departure Time</h3>

        <div className="item" onClick={(e) => handleDeparturetime("bf6")}>
          <span
            style={{ fontWeight: departureTime == "bf6" ? "bold" : "normal" }}
          >
            <span>Before 06:00</span>
          </span>
          <span>
            <input type="radio" value="bf6" checked={departureTime == "bf6"} />
          </span>
        </div>
        <div className="item" onClick={(e) => handleDeparturetime("6-12")}>
          <span
            style={{ fontWeight: departureTime == "6-12" ? "bold" : "normal" }}
          >
            <span>06:00 - 12:00</span>
          </span>
          <span>
            <input
              type="radio"
              value="6-12"
              onChange={(e) => handleDeparturetime("6-12")}
              checked={departureTime == "6-12"}
            />
          </span>
        </div>
        <div className="item" onClick={(e) => handleDeparturetime("12-18")}>
          <span
            style={{ fontWeight: departureTime == "12-18" ? "bold" : "normal" }}
          >
            <span>12:01 - 18:00</span>
          </span>
          <span>
            <input
              type="radio"
              value="12-18"
              onChange={(e) => handleDeparturetime("12-18")}
              checked={departureTime == "12-18"}
            />
          </span>
        </div>
        <div className="item" onClick={(e) => handleDeparturetime("after6Pm")}>
          <span
            style={{
              fontWeight: departureTime == "after6Pm" ? "bold" : "normal",
            }}
          >
            <span>After 18:00</span>
          </span>
          <span>
            <input
              type="radio"
              value="after6Pm"
              onChange={(e) => handleDeparturetime("after6Pm")}
              checked={departureTime == "after6Pm"}
            />
          </span>
        </div>
        <Divider>
          <hr />
        </Divider>
        <h3>Amenities</h3>
        <div className="item" onClick={(e) => handleAmenity("2inB")}>
          <span style={{ fontWeight: amenity == "2inB" ? "bold" : "normal" }}>
            <FaUsers />
            <span>Max.2 in back</span>
          </span>
          <span>
            <input
              type="radio"
              value="2inB"
              onChange={(e) => handleAmenity("2inB")}
              checked={amenity == "2inB"}
            />
          </span>
        </div>
        <div className="item" onClick={(e) => handleAmenity("smoking")}>
          <span
            style={{ fontWeight: amenity == "smoking" ? "bold" : "normal" }}
          >
            <FaSmoking />
            <span>Smoking allowed</span>
          </span>
          <span>
            <input
              type="radio"
              value="smoking"
              onChange={(e) => handleAmenity("smoking")}
              checked={amenity == "smoking"}
            />
          </span>
        </div>
      </div>
    </FilterWrapper>
  );
};

export default Filters;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  .filter-items {
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.page == "carpool" ? "1rem" : "0.8rem")};
    width: 100%;
    color: ${COLORS.secondaryColor};
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .clear {
        cursor: pointer;
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      cursor: pointer;
      min-width: ${(props) => (props.page == "carpool" ? "unset" : "190px")};
      span {
        :hover {
          font-weight: bold;
        }
      }
      span:first-child {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
`;
