import { Button, IconButton } from "@material-ui/core";
import {
  CheckCircleOutline,
  DoneOutline,
  Edit,
  Lock,
  MoreVertRounded,
} from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { REPORT_STATUS, getAge } from "../../../Utils";
import { MdOutlinePendingActions, MdPending } from "react-icons/md";
import GeneralModal from "../../models/General";
import NoData from "../../NoData";

const tableHeaders = [
  "ID",
  "Leaving from",
  "Pickup Location",
  "Going To",
  "Drop Off Location:",
  "Departure date",
  "Departure time",
  "Total seats",
  "Available seats",
  "Fare",
  "Status",
  "Driver",
  "Passengers",
  "Action",
];

const TableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  .isActive {
    border: 1px solid #c3c5c9;
  }

  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  // border: 1px solid #054752;
  font-size: 14px;
  color: grey;
  cursor: pointer;
  // :hover {
  //   background-color: #054752;
  //   color: white;
  //   font-weight: 500;
  //   text-transform: capitalize;
  // }
`;

const RoutesTable = ({ rides }) => {
  console.log("rides", rides);
  const [isOpen, setIsOpen] = useState(false);
  const [showPassModal, setShowPassModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const ddref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (event) => {
    if (ddref.current && !ddref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const toggleDropdown = (item) => {
    setIsOpen(!isOpen);
    setActiveItem(item);
  };
  const togglePassModal = (item) => {
    setShowPassModal(!showPassModal);
    setActiveItem(item);
  };
  console.log("ActiveIte", activeItem);
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {rides?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={row.id == activeItem?.id && isOpen ? "isActive" : null}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>Pindi To Karachi</TableCell>
              <TableCell>
                <small>{row.pickup_location}</small>
              </TableCell>
              <TableCell>{row.heading_to}</TableCell>
              <TableCell>
                <small>{row.drop_location}</small>
              </TableCell>

              <TableCell>{row.departure_date}</TableCell>
              <TableCell>
                {moment(new Date(row.departure_time).getTime()).format(
                  "hh:mm a"
                )}
              </TableCell>
              <TableCell>{row.total_seats}</TableCell>
              <TableCell>{row.seats_available}</TableCell>
              <TableCell>
                <strong>
                  <small>Rs.</small>
                  {row.ticket}
                  <small>.00</small>
                </strong>
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                <Link to={`/profile/${row.User.id}`} className="link">
                  <p style={{ textTransform: "capitalize" }}>
                    {row.User.first_name}
                  </p>
                </Link>
              </TableCell>
              <TableCell>
                <Link className="link" onClick={() => togglePassModal(row)}>
                  Passengers
                </Link>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => toggleDropdown(row)}>
                  <MoreVertRounded />
                </IconButton>
              </TableCell>
              {activeItem?.id === row.id && (
                <DropdownWrapper ref={ddref}>
                  <DropdownMenu isOpen={isOpen}>
                    <MenuItem>
                      <span>
                        {" "}
                        <CheckCircleOutline size={24} />
                      </span>{" "}
                      Mark Ride as Complete
                    </MenuItem>
                    <MenuItem>
                      <span>
                        {" "}
                        <Lock size={24} />
                      </span>{" "}
                      Lock Ride
                    </MenuItem>
                    <MenuItem>
                      <span>
                        {" "}
                        <Edit size={24} color="green" />
                      </span>{" "}
                      Edit Ride
                    </MenuItem>
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
      {activeItem?.id && showPassModal && (
        <GeneralModal
          cancelBtnText={"Close"}
          confirmBtnText={"OK"}
          onConfirm={() => setShowPassModal(false)}
          handleClose={() => setShowPassModal(false)}
          open={showPassModal}
          title={"Passengers List"}
          content={
            <div>
              {activeItem?.RideRequests?.filter(
                (it) => it.status !== "3" && it.status !== "1"
              ).length == 0 && (
                <div style={{ minWidth: "320px" }}>
                  <NoData message={"No Passengers"} />
                </div>
              )}
              {activeItem?.RideRequests?.length > 0 &&
                activeItem?.RideRequests?.filter(
                  (it) => it.status !== "3" && it.status !== "1"
                ).map((item) => {
                  return (
                    <Passanger>
                      <span>
                        <img
                          src={
                            item?.requestedBy.avatar_url ||
                            `https://ui-avatars.com/api/?name=${item?.requestedBy.first_name}`
                          }
                          height="auto"
                          width="70px"
                          alt="Passanger pic"
                        />
                      </span>
                      <div className="info">
                        <strong>
                          {item?.requestedBy.first_name} &nbsp;
                          {item?.requestedBy.last_name},
                        </strong>
                        <small>Mob: {item?.requestedBy.mobile_number}</small>
                        <small>Email: {item?.requestedBy.email}</small>
                        <small>Age: {getAge(item?.requestedBy.dob)}</small>
                        <small>Gender: {item?.requestedBy.gender}</small>
                        <strong>
                          {" "}
                          {item.status == "2"
                            ? "Acceped"
                            : item.status === "1"
                            ? "pending"
                            : item.status === "3"
                            ? "rejected"
                            : null}{" "}
                        </strong>
                      </div>
                    </Passanger>
                  );
                })}
            </div>
          }
        />
      )}
    </TableContainer>
  );
};

export default RoutesTable;
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const Passanger = styled.div`
  min-width: 340px;
  display: flex;
  gap: 0.5rem;
  height: fit-content;
  background: #f6f4f4;
  margin-bottom: 0.25rem;

  .info {
    display: flex;
    flex-direction: column;
  }
`;
const DropdownMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;

const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;
