import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputField = ({
    label,
    value,
    onChange,
    required = false,
    type = "text",
    fullWidth = true,
    variant="outlined",
    maxLength=null,
    pattern=null,
    minLength=null,
    placeholder=null,

}) => {
    return (
        <TextField
            label={label}
            variant={variant}
            value={value}
            onChange={onChange}
            required={required}
            type={type}
            fullWidth={fullWidth}
            defaultValue={value}
            placeholder={placeholder}
            inputProps={{
                pattern,
                maxLength,
                minLength
            }}
        />
    );
};

export default InputField;
