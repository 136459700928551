import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import api from "../../../services/api"; // Ensure this path is correct

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly Rides Published",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

function Chart() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Rides Count",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get("/admin/dashboard/get-rides-data");
        const data = response.data.data;

        const months = data.map((item) =>
          new Date(item.month).toLocaleString("default", { month: "long" })
        );
        const ridesCount = data.map((item) => item.rideCount);
        const pendingCount = data.map((item) => item.pendingCount);
        const completedCount = data.map((item) => item.completedCount);

        setChartData({
          labels: months,
          datasets: [
            {
              label: "Published",
              data: ridesCount,
              backgroundColor: "rgba(255, 159, 64, 1)", // Apply colors based on the number of months
              borderColor: "rgba(255, 159, 64, 1)",
              borderWidth: 1,
            },
            {
              label: "Pending",
              data: pendingCount,
              backgroundColor: "rgba(153, 0, 204, 1)", // Apply colors based on the number of months
              borderColor: "rgba(153, 0, 204, 1)",
              borderWidth: 1,
            },

            {
              label: "Completed",
              data: completedCount,
              backgroundColor: "rgba(0, 204, 153, 1)", // Apply colors based on the number of months
              borderColor: "rgba(0, 204, 153, 1)",
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching chart data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <div style={{ width: "100%" }}>
      {" "}
      {/* Adjust chart size */}
      {chartData.labels.length > 0 ? (
        <Bar options={options} data={chartData} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default Chart;
