import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TourPaymentSuccess = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <Wrapper>
      <Content>
        <IconWrapper>
          <FaCheckCircle size={80} />
        </IconWrapper>
        <Title>Payment Successful!</Title>
        <Message>
          Your payment was processed successfully. Thank you for booking with
          GoSawari!
        </Message>
        <Details>
          <DetailRow>
            <DetailLabel>Transaction ID:</DetailLabel>
            <DetailValue>#123456789</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Date:</DetailLabel>
            <DetailValue>July 9, 2024</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Amount Paid:</DetailLabel>
            <DetailValue>Rs. 25,000</DetailValue>
          </DetailRow>
        </Details>
        <BackButton onClick={handleBackToHome}>Back to Home</BackButton>
      </Content>
    </Wrapper>
  );
};

export default TourPaymentSuccess;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #5efce8, #736efe);
`;

const Content = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
`;

const IconWrapper = styled.div`
  color: #28a745;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #054752;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 2rem;
  font-family: "Roboto", sans-serif;
`;

const Details = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const DetailLabel = styled.span`
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
`;

const DetailValue = styled.span`
  font-size: 1rem;
  color: #054752;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
`;

const BackButton = styled.button`
  background-color: #054752;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Roboto", sans-serif;

  &:hover {
    background-color: #046a73;
  }
`;
