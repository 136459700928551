import React from "react";
import styled from "styled-components";
import Inbox from "../../components/Inbox";

function InboxPage({ socket }) {
  return (
    <PageContainer>
      <Inbox socket={socket} />
    </PageContainer>
  );
}

export default InboxPage;
const PageContainer = styled.div`
  width: 100%;
  height: 88.5vh;
`;
