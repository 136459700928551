import styled from "styled-components";
import { COLORS } from "../../assets/colors";

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 546px) {
    margin-bottom: 0;
  }
`;

const ChatTitle = styled.h2`
  font-size: 24px;
`;

const ChatInput = styled.input`
  width: 80%;
  padding-left: 1rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  @media (width: 1024px) and (height: 1366px) {
    font-size: 22px;
  }

  &:focus {
    outline: none;
  }
`;

const ChatLog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 420px;
  padding-bottom: 0.5rem;
  :first-child {
    padding-top: 0.5rem;
  }
  @media (min-height: 400px) and (max-height: 500px) {
    height: 45vh;
  }
  @media (min-height: 501px) and (max-height: 612px) {
    height: 50vh;
  }
  @media (max-width: 360px) and (min-height: 613px) {
    height: 58vh;
  }
  @media (width: 712px) {
    height: 62vh;
  }
  @media (width: 375px) and (height: 667px) {
    height: 62vh !important;
  }
  @media (min-width: 361px) and (max-width: 546px) {
    height: 62vh;
  }
  @media (min-width: 768px) {
    height: 60vh;
  }
  overflow-y: auto;
  .message-container {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem;

    img {
      border-radius: 50%;
    }
  }
`;

const ChatMessage = styled.div`
  max-width: 98%;
  height: auto;
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isMe ? "rgb(239, 239, 239)" : "#f4f4f7"};
  display: flex;
  .MuiAvatar-root {
    margin: 0 8px;
  }
  .message-content {
    width: 100%;
  }
`;

const ChatMessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChatMessageAuthor = styled.span`
  font-weight: bold;
  text-transform: capitalize;
`;

const ChatMessageTimestamp = styled.span`
  font-size: 12px;
  color: #666;
`;

const ChatMessageText = styled.p`
  font-size: 16px;
  line-height: 1.4;
`;
const LeftSection = styled.div`
  width: 100%;
  max-width: 300px;
  overflow-y: auto;

  @media (max-width: 546px) {
    width: 100%;
    max-width: none;
    height: 88.5vh;
  }
  border: 1px solid ${COLORS.borderColor};
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  gap: 0.25rem;
  .contacts {
    height: auto;
    text-align: center;
    justify-content: center;
    display: flex;
    // border-bottom: 1px solid ${COLORS.borderColor};
    flex-direction: column;
    h3 {
      font-size: 1.4rem;
    }
    .me {
      display: flex;
      padding: 2rem 0.5rem 1rem 0.5rem;
      align-items: center;
      gap: 0.5rem;
      .name-wrapper {
        text-align: left;
        medium {
          font-size: small;
          color: gray;
        }
      }
    }
    @media (max-width: 546px) {
      text-align: left;
      margin-left: 1rem;
    }
  }
  .item {
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: small;
    cursor: pointer;
    span {
      p {
        color: ${COLORS.secondaryTextColor};
      }
    }
    span:first-child {
      margin: 0 8px;
    }
    span:last-child {
      padding-top: 4px;
    }
  }
`;
const EndSection = styled.div`
  width: 100%;
  height: 100%;
  max-width: 300px;
  background: #fff;
  // background: ${COLORS.primaryColor};
  border-top: 1px solid ${COLORS.borderColor};
  border-bottom: 1px solid ${COLORS.borderColor};
  border-right: 1px solid ${COLORS.borderColor};
  .top {
    display: flex;
    color: black;
    justify-content: center;
    border-radius: 50%;
    gap: 0.5rem;
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiAvatar-root {
      height: 150px;
      width: 150px;
    }
  }
  .mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0.5rem;
    color: ${COLORS.primaryTextColor};

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-size: medium;
      svg {
        font-size: larger;
        color: ${COLORS.primaryTextColor};
      }
    }
  }
`;
const RightSection = styled.div`
  display: ${(props) => (props.hide ? "none" : null)};
  width: 100%;
  // max-width: 600px;
  border-top: 1px solid ${COLORS.borderColor};
  border-bottom: 1px solid ${COLORS.borderColor};
  border-right: 1px solid ${COLORS.borderColor};
  @media (max-width: 546px) {
    width: ${(props) => (props.chatOpen ? "100%" : "0%")};
    max-width: none;

  }
  .no-chat {
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;
    p {
      color: ${COLORS.secondaryTextColor};
    }
    @media(max-width: 546px){
      display:none;
      // height: 50%;
    }
  }
  .chat-head {
    height: 4rem;
    width: 100%;
    border-bottom: 1px solid ${COLORS.borderColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      height: 100%;
      .magic {
        display: flex;
      }
      span {
        margin: 0 4px;
      }
      h2 {
        font-size: 1rem;
      }
    }
    .right {
      display:flex;
    }
  }
  .content-type {
    /* padding: 1rem; */
    background: whitesmoke;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid ${COLORS.borderColor};
    align-items:center;
    height:4rem;
    .item {
      width:48%;
      height:100%;
      cursor:pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .active {
        background: #e9e9e9;
      }
    .activeTab {
      font-weight: bold;
    }
    }

    .vert-divider {
      width:2px;
      height:100%;
      background: ${COLORS.borderColor};
    }
  }
  .chat-body {
    padding: 0 0rem 1rem 1rem
    }
  }
  .chat-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.3rem;
    border-radius: 32px;
    bottom: 25px;
    background: white;
    border: 1px solid rgb(170 169 169);
    box-shadow: ${COLORS.borderColor};
    margin-right:1rem;
    .messagebox {
      display: flex;
      gap: 1rem;
      div {
        width: 330px;
        @media (min-width: 746px) {
          width: 500px;
        }
      }
    }
  }
`;
const Sections = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: left;
  // padding: 0 0 0 1rem;
`;

export {
  ChatHeader,
  ChatInput,
  ChatLog,
  ChatMessage,
  ChatMessageAuthor,
  ChatMessageHeader,
  ChatMessageText,
  ChatMessageTimestamp,
  ChatTitle,
  ChatWrapper,
  LeftSection,
  RightSection,
  Sections,
  EndSection,
};
