import { Button, IconButton } from "@material-ui/core";
import { MoreVertRounded } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PiCarBold, PiUserCircle } from "react-icons/pi";
import styled from "styled-components";
import api from "../../../services/api";
import { toast } from "react-toastify";
import GeneralModal from "../../models/General";
import { Divider } from "../../ProfileComponents";
import DriverDetail from "../../Driverdetails";
import CoTraveller from "../../CoTraveller";
import TripTimeline from "../../../components/UI/TripTimeline";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { COLORS } from "../../../assets/colors";

const tableHeaders = [
  // "Sr",
  "Ref.ID",
  "Avatar",
  "Full Name",
  "Refund Amount",
  "seats",
  "reason",
  "Ride Details",
  "Cancelled on",
  "Cancel at",
  "Booked on",
  "Booked at",
  "Status",
  "OrderId",
  "Action",
];

const TableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  font-size: 14px;
  color: grey;
  cursor: pointer;
`;
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;
const RideDetailModal = styled.div`
  display: ${(props) => (props.showRideDetail ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;
const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Table = ({ data, getRefundDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showRideDetail, setShowRideDetail] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const { currentUser } = useSelector((state) => state.auth);
  const ddref = useRef();
  const rmRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (event) => {
    if (ddref.current && !ddref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = (item) => {
    setIsOpen(!isOpen);
    setActiveItem(item);
  };
  const toggleRideModal = (item) => {
    setShowRideDetail(!showRideDetail);
    setActiveItem(item);
  };
  const onUpdteRefundingStatus = async (rideReqId) => {
    const response = await api.post(`/admin/refunds/update-refund-status`, {
      id: rideReqId,
    });
    if (response.status === 200) {
      toast.success("Updated refund status");
      getRefundDetails();
    }
    setIsOpen(false);
  };
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>{row?.requestedBy?.id}</TableCell>
              <TableCell>
                <img
                  src={
                    row.requestedBy.avatar_url ||
                    `https://ui-avatars.com/api/?name=${row.requestedBy.first_name}`
                  }
                  height={"30px"}
                  width={"30px"}
                />
              </TableCell>
              <TableCell>
                {row.requestedBy?.first_name} &nbsp;{row.requestedBy?.last_name}{" "}
              </TableCell>
              <TableCell>
                <strong>
                  <small>Rs.</small>
                  {row.refund_amount}
                  <small>.00</small>
                </strong>
              </TableCell>
              <TableCell>
                <strong>{row.seats}</strong>
              </TableCell>
              <TableCell>
                <strong>{row.cancel_reason}</strong>
              </TableCell>
              <TableCell>
                <Link
                  variant="text"
                  className="link"
                  onClick={() => toggleRideModal(row)}
                >
                  Ride Details
                </Link>
              </TableCell>
              <TableCell>
                {moment(row.cancelled_at).format("DD/MM/YY")}
              </TableCell>
              <TableCell>
                {moment(row.cancelled_at).format("hh:mm A")}
              </TableCell>
              <TableCell>{moment(row.createdAt).format("DD/MM/YY")}</TableCell>
              <TableCell>{moment(row.createdAt).format("hh:mm A")}</TableCell>
              <TableCell>
                {row.refund_status ? (
                  <span style={{ color: "green", textTransform: "capitalize" }}>
                    {row.refund_status}
                  </span>
                ) : (
                  "not refunded yet"
                )}
              </TableCell>
              <TableCell>{row.order_id}</TableCell>
              <TableCell>
                <IconButton onClick={() => toggleDropdown(row)}>
                  <MoreVertRounded />
                </IconButton>
              </TableCell>
              {activeItem?.id === row.id && (
                <DropdownWrapper ref={ddref}>
                  <DropdownMenu isOpen={isOpen}>
                    <MenuItem onClick={() => onUpdteRefundingStatus(row.id)}>
                      <span>
                        {" "}
                        <PiUserCircle size={24} color="#054752" />
                      </span>{" "}
                      Update refund status to {row.requestedBy.first_name} as
                      refunded
                    </MenuItem>
                    <MenuItem onClick={() => toggleRideModal(row)}>
                      <span>
                        {" "}
                        <PiCarBold size={24} color="#054752" />{" "}
                      </span>
                      See ride details
                    </MenuItem>
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
      {activeItem?.id && showRideDetail && (
        <GeneralModal
          title={"Ride Details"}
          cancelBtnText={"Close"}
          onConfirm={() => setShowRideDetail(false)}
          handleClose={() => setShowRideDetail(false)}
          open={showRideDetail}
          confirmBtnText={"OK"}
          content={
            <RideInfo>
              <h2>
                {moment(activeItem?.departure_date).format("MMMM Do, YYYY")}
              </h2>
              <p className="small-text">
                {activeItem?.Ride.leaving_from} to {activeItem?.Ride.heading_to}
              </p>
              <div className="trip-detail">
                <TripTimeline
                  fromTime={activeItem?.Ride.departure_time}
                  toTime={activeItem?.Ride.arrival_time}
                  headingTo={activeItem?.Ride.heading_to}
                  leavingFrom={activeItem?.Ride.leaving_from}
                  drop={activeItem?.Ride.drop_location}
                  pick={activeItem?.Ride.pickup_location}
                  startLat={activeItem?.Ride.start_lat}
                  startLng={activeItem?.Ride.start_lng}
                  endLat={activeItem?.Ride.end_lat}
                  endLng={activeItem?.Ride.end_lng}
                  rideId={activeItem?.Ride.id}
                />
              </div>
              <Divider style={{ width: "100%" }}>
                <hr />
              </Divider>
              <div className="fare">
                <span>Total price for 1 passanger</span>
                <p>
                  <strong>Rs.{activeItem?.Ride.ticket}.00</strong>
                </p>
              </div>
              <Divider style={{ width: "100%" }}>
                <hr />
              </Divider>
              <div className="driver-detail">
                <DriverDetail
                  driver={activeItem?.requestedTo}
                  vehicle={activeItem?.Vehicle}
                  autoApproval={activeItem?.automatic_approval}
                />
              </div>
            </RideInfo>
          }
        />
      )}
    </TableContainer>
  );
};

export default Table;

const RideInfo = styled.div`
  text-align: center;
  h2 {
    color: ${COLORS.primaryColor};
  }
`;
