import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ShowOnMap from "../../components/ShowOnMap";
import api from "../../services/api";
import { useLoadScript } from "@react-google-maps/api";
import CircularLoader from "../../components/UI/Loader";
const libraries = ["places"];

const ShowLocation = () => {
  const [directionResponse, setDirectionResponse] = useState(null);

  const [loading, setLoading] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");
  const rideId = searchParams.get("rideId");
  useEffect(() => {
    const getLocationData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`ride/${rideId}`);
        if (response.status === 200) {
          console.log("response", response);
          setTimeout(async () => {
            const directionService = new google.maps.DirectionsService();
            const results = await directionService.route({
              origin: response.data.data.pickup_location,
              destination: response.data.data.drop_location,
              travelMode: google.maps.TravelMode.DRIVING,
              region: "pk",
            });
            setDirectionResponse(results);
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.log("Error in rendering route::", error.message);
        setLoading(false);
      }
    };
    getLocationData();
    return () => {
      setDirectionResponse(null);
    };
  }, []);
  if (loading || !isLoaded) {
    return (
      <LoaderWrapper>
        <CircularLoader isLoading={loading} />
        <p>Refresh the page if the map takes too long to load</p>
      </LoaderWrapper>
    );
  }
  if (loadError) {
    return "something went wrong while loading google maps";
  }
  return (
    <StyledMapWrapper>
      {isLoaded && (
        <ShowOnMap
          lat={parseFloat(lat)}
          lng={parseFloat(lng)}
          width="100%"
          height={window.innerWidth < 600 ? "83.5vh" : "88vh"}
          directionResponse={directionResponse}
        />
      )}
    </StyledMapWrapper>
  );
};

export default ShowLocation;

const StyledMapWrapper = styled.div`
  height: 88.5vh;
  width: 100%;
  @media (min-width: 600px) {
    height: 90vh;
  }
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
