import { IconButton } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  width: 100%;
  .icon {
    cursor: pointer;
  }
  button {
    position: absolute;
    right: 5px;
}
  }
  input {
    border-radius: 32px;
    width: 100%;
    padding: 18px;
    height: 3rem;
    @media (max-width: 767px) {
      border: none;
      outline: none;
      padding: 15px;
      width: 100%;
      height: 2.5rem;
      font-size: 1rem;
    }
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  padding: 17px;
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
`;

const SearchField = ({
  onChange,
  clearSearch,
  value,
  placeholder,
  style = {},
}) => {
  return (
    <SearchContainer>
      <SearchInput
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        style={style}
      />
      <IconButton onClick={clearSearch} color="primary">
        <CancelRounded />
      </IconButton>
    </SearchContainer>
  );
};

export default SearchField;
