import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const TripTimelineV2 = ({
  handleShowMap,
  headingTo,
  drop,
  pick,
  leavingFrom,
  startLat,
  endLat,
  startLng,
  endLng,
  fromTime,
  toTime,
  rideId,
  duration,
}) => {
  const navigate = useNavigate();

  const showOnMap = (lat, lng, rideId) => {
    navigate(`showOnMap?lat=${lat}&lng=${lng}&rideId=${rideId}`);
  };
  return (
    <TimelineContainer>
      <TimeContainer>
        <TimeTextContainer>
          <TimeText>{toTime}</TimeText>
        </TimeTextContainer>
        <div>
          <TimeText>{moment(fromTime).format("h:mm A")}</TimeText>
        </div>
      </TimeContainer>
      {duration && <p className="duration">{duration}</p>}
      <LineContainer>
        <CircleTop />
        <Line>
          <Arrow />
        </Line>
        <Circle />
      </LineContainer>
      <LocationContainer>
        <div>
          <LocationButton onClick={() => showOnMap(startLat, startLng, rideId)}>
            <LocationContent>
              <div className="loc-detail">
                <LocationText>{headingTo}</LocationText>
                <SubLocationText>{drop?.substring(0, 40)}</SubLocationText>
              </div>
            </LocationContent>
          </LocationButton>
        </div>
        <div>
          <LocationButton onClick={() => showOnMap(endLat, endLng, rideId)}>
            <LocationContent>
              <div className="loc-detail" style={{ paddingTop: "10px" }}>
                <LocationText>{leavingFrom}</LocationText>
                <SubLocationText>{pick?.substring(0, 40)}</SubLocationText>
              </div>
            </LocationContent>
          </LocationButton>
        </div>
      </LocationContainer>
    </TimelineContainer>
  );
};

const TimelineContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  .duration {
    position: absolute;
    top: 40%;
    font-size: xx-small;
    color: gray;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 55px;
`;

const TimeTextContainer = styled.div`
  height: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TimeText = styled.div`
  font-size: 14px;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const CircleTop = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid red;
`;

const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid green;
`;

const Line = styled.div`
  width: 4px;
  height: 60px;
  background-color: teal;
  position: relative;
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid teal;
  position: absolute;
  top: 35%;
  left: 2px;
  -webkit-transform: translate(-50%, -45%);
  -ms-transform: translate(-50%, -45%);
  transform: translate(-50%, -50%);
`;

const LocationContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

const LocationButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const LocationContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  .loc-detail {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 160px;
    overflow: hidden;
    min-height: 42px;
  }
`;

const LocationText = styled.div`
  font-size: 14px;
  text-align: left;
  :hover {
    color: gray;
  }
`;

const SubLocationText = styled.div`
  font-size: 12px;
  max-width: 180px;
  color: gray;
  text-align: left;
  :hover {
    color: darkgray;
  }
`;

export default TripTimelineV2;
