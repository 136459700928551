import { IconButton } from "@material-ui/core";
import { MoreVertRounded } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PiUserCircle } from "react-icons/pi";
import api from "../../../services/api";
import { toast } from "react-toastify";

const tableHeaders = [
  //   "ID",
  "Avatar",
  "First name",
  "Last name",
  "E-mail",
  "Phone",
  "Amount",
  "Status",
  "Requested on",
  "Action",
];

const TableContainer = styled.div`
  overflow-x: auto;
  height: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  .isActive {
    border: 1px solid #c3c5c9;
  }
  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  // border: 1px solid #054752;
  font-size: 14px;
  color: grey;
  cursor: pointer;
  // :hover {
  //   background-color: #054752;
  //   color: white;
  //   font-weight: 500;
  //   text-transform: capitalize;
  // }
`;
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;

const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const WithdrawalRequestsTable = ({ customers, getWithdrawRequests }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const ddref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (event) => {
    if (ddref.current && !ddref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = (item) => {
    setIsOpen(!isOpen);
    setActiveItem(item);
  };

  const onChangeWithdrawRequestStatus = async (requestId, status) => {
    const response = await api.patch(
      `/admin/withdraw-requests/updateStatus/${requestId}`,
      {
        status,
      }
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      getWithdrawRequests();
    }
    setIsOpen(false);
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {customers?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={row.id == activeItem?.id && isOpen ? "isActive" : null}
            >
              {/* <TableCell>{row.id}</TableCell> */}
              <TableCell>
                <img
                  src={row.userInfo?.avatar_url}
                  height={"30px"}
                  width={"30px"}
                />
              </TableCell>
              <TableCell>{row.userInfo?.first_name}</TableCell>
              <TableCell>{row.userInfo?.last_name}</TableCell>
              <TableCell>{row.userInfo?.email}</TableCell>
              <TableCell>{row.userInfo?.mobile_number}</TableCell>
              <TableCell>Rs.{row.withdraw_amount}.00</TableCell>

              <TableCell>{row.status}</TableCell>
              <TableCell>
                {moment(row.createdAt).format("MMM Do, YYYY")}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => toggleDropdown(row)}>
                  <MoreVertRounded />
                </IconButton>
              </TableCell>
              {activeItem?.id === row.id && (
                <DropdownWrapper ref={ddref}>
                  {/* <DropdownMenu isOpen={isOpen}>
                    <MenuItem onClick={() => onUnBanUser(row.userInfo.id)}>
                      <span>
                        {" "}
                        <PiUserCircle size={24} color="green" />
                      </span>{" "}
                      Unban {row.userInfo.first_name}
                    </MenuItem>
                  </DropdownMenu> */}
                  <DropdownMenu isOpen={isOpen}>
                    <MenuItem
                      onClick={() =>
                        onChangeWithdrawRequestStatus(row.id, "pending")
                      }
                    >
                      <span>
                        {" "}
                        <PiUserCircle size={24} color="green" />
                      </span>{" "}
                      set to Pending
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onChangeWithdrawRequestStatus(row.id, "processing")
                      }
                    >
                      <span>
                        {" "}
                        <PiUserCircle size={24} color="green" />
                      </span>{" "}
                      set to Processing
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onChangeWithdrawRequestStatus(row.id, "complete")
                      }
                    >
                      <span>
                        {" "}
                        <PiUserCircle size={24} color="green" />
                      </span>{" "}
                      set to Complete
                    </MenuItem>
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default WithdrawalRequestsTable;
