import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import ReactGA from "react-ga4";

import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import CustomButton from "../UI/Button";
import { COLORS } from "../../assets/colors";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Alert } from "@material-ui/lab";
import configMerged from "../../config";

function Login({
  submitLoginForm,
  isLoading,
  success,
  loginError,
  location = null,
}) {
  const recaptchaRef = React.createRef();

  const [state, setState] = useState({
    emailOrPhone: "",
    password: "",
    showPassword: false,
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    if (loginError) {
      recaptchaRef.current.reset();
      setRecaptchaToken("");
    }
  }, [loginError]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/login", title: "Login" });
    return () => {
      setRecaptchaToken("");
      setState({
        emailOrPhone: "",
        password: "",
        showPassword: false,
      });
    };
  }, []);

  let errorsObj = { emailOrPhone: "", password: "" };
  const [error, setError] = useState(errorsObj);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    if (!recaptchaToken) {
      toast.info("Please complete the reCAPTCHA challange");
      return;
    }
    try {
      const response = await api.post("/auth/verify-recaptcha", {
        token: recaptchaToken,
      });
      if (response.data.success) {
        const errorObj = { ...errorsObj };
        if (validateEmailOrPhoneNumber(state.emailOrPhone) === "email") {
          if (!validateEmail(state.emailOrPhone)) {
            errorObj.emailOrPhone = "Email is invalid";
            error = true;
          }
        }
        if (validateEmailOrPhoneNumber(state.emailOrPhone) === "invalid") {
          errorObj.emailOrPhone =
            "Invalid entry e.g test@yopmail.com OR 03241231223";
          error = true;
        }

        if (state.password.length < 8) {
          errorObj.password = "Password should be 8 charactors ";
          error = true;
        }

        setError(errorObj);

        if (!error) {
          submitLoginForm(state);

          // setRecaptchaToken("");
        }
      } else {
        toast.error("reCAPTCHA verification failed. Please try again.");
        setRecaptchaToken("");
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
      recaptchaRef.current.reset();
    }
  };
  const handleEmailOrPhone = (e) => {
    setState({
      ...state,
      emailOrPhone: e.target.value,
    });
  };
  const handlePasswordVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };
  function validateEmailOrPhoneNumber(input) {
    // Regular expressions for email and phone number
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^(03\d{9})$/;

    // Test the input against the regular expressions
    const isEmail = emailRegex.test(input);
    const isPhoneNumber = phoneRegex.test(input);

    // Determine the type of input
    if (isEmail) {
      return "email";
    } else if (isPhoneNumber) {
      return "phone";
    } else {
      return "invalid";
    }
  }
  //login with google
  const loginwithgoogle = () => {
    window.open(`${config.api.baseUrl}/auth/google`, "_self");
  };
  // loginwihtfb
  const loginwihtfb = () => {
    window.open(`${config.api.baseUrl}/auth/facebook`, "_self");
  };

  const handleOnchange = (value) => {
    setRecaptchaToken(value);
  };
  //login with fb
  const loginwithfacebook = () => {
    window.open(`${config.api.baseUrl}/auth/facebook`, "_self");
  };
  const responseFacebook = (response) => {
    console.log("resp from fb", response);
  };

  return (
    <MainContainer>
      <Typography
        style={{ fontWeight: 600, marginBottom: "1rem" }}
        align="center"
        color="primary"
        gutterBottom
        variant="h5"
      >
        Welcome back
      </Typography>
      <FormContainer>
        <form onSubmit={onSubmit}>
          <TextField
            id="filled-last-name-input"
            label="Email"
            type="text"
            fullWidth
            autoComplete="current-last-name"
            variant="outlined"
            required
            onChange={(e) => handleEmailOrPhone(e)}
            error={error.emailOrPhone != ""}
            helperText={error.emailOrPhone}
          />

          <TextField
            id="filled-password-input"
            label="Password"
            type={state.showPassword ? "text" : "password"}
            fullWidth
            autoComplete="current-password"
            variant="outlined"
            required
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
            error={error.password != ""}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={configMerged.recaptchaKey}
            onChange={handleOnchange}
          />
          <BtnContainer>
            <CustomButton
              label={isLoading ? "Please wait" : "Login"}
              type="submit"
            />
          </BtnContainer>

          {loginError && loginError.length && (
            <Alert severity="error" style={{ marginTop: "0.5rem" }}>
              {loginError}
            </Alert>
          )}

          <LinkContainer>
            <Link to="/forget-password" style={{ fontSize: "18px" }}>
              <span>Forgot Password?</span>
            </Link>
            <Link to="/register">
              Don't have an account? <span>Register</span>
            </Link>
          </LinkContainer>
        </form>
        {/* <SociaMedia> */}
        {/* <button className="facebook-login-button" onClick={loginwihtfb}>
            <i class="fab fa-facebook"></i> <span>Continue with Facebook</span>
          </button> */}
        {/* <button className="google-login-button" onClick={loginwithgoogle}>
            <i class="fab fa-google"></i> <span>Continue with Google</span>
          </button> */}
        {/* </SociaMedia> */}
      </FormContainer>
    </MainContainer>
  );
}

export default Login;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  flex-direction: column;
  > form > .MuiTextField-root {
    text-align: center;
    color: white;
    margin-bottom: 15px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 15px 15px;
  gap: 0.5rem;
  min-height: 50px;
  > a {
    text-decoration: none;
    color: grey;
    span {
      color: ${COLORS.linkColor};
    }
  }
`;
const BtnContainer = styled.div`
  margin-top: 10px;
`;
const SociaMedia = styled.div`
  border-top: 1px solid ${COLORS.borderColor};
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  gap: 2rem;
  justify-content: center;
  width: 100%;

  .facebook-login-button {
    border: none;

    width: 16rem;
    transition: background-color 0.3s, box-shadow 0.3s;

    background-color: #3b5998;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .facebook-login-button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .facebook-login-button i {
    margin-right: 8px;
    font-size: 22px;
  }
  /* GoogleLoginButton.css */

  .google-login-button {
    border: 1px solid ${COLORS.borderColor};
    width: 16rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    background-color: white;
    color: gray;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .google-login-button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .google-login-button i {
    margin-right: 8px;
    font-size: 22px;
    background: conic-gradient(
        from -45deg,
        #ea4335 110deg,
        #4285f4 90deg 180deg,
        #34a853 180deg 270deg,
        #fbbc05 270deg
      )
      73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 767px) {
    diplay: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;
