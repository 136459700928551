import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { passwordResetRequest } from "../../redux/actions";
import ResetPassword from '../../components/ResetPassword'
import PageContainer from '../../components/UI/PageContainer'

const PasswordReset = () => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector(state => state.resetPassword)
    const submitPasswordResetForm = (data) => {
        dispatch(passwordResetRequest(data))
    }


    return (
        <PageContainer>
            <ResetPassword
                submitPasswordResetForm=
                {submitPasswordResetForm}
                isLoading={isLoading}
            />
        </PageContainer>
    )
}

export default PasswordReset
