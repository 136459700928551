import { css } from "styled-components";
import { COLORS } from "./assets/colors";

const flexAlignCenter = css`
  align-items: center;
  display: flex;
`;
const flexAlignStart = css`
  ${flexAlignCenter}
  align-items: flex-start;
`;
const scrollStyle = css`
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f3f3f3; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: gainsboro;
    border-radius: 20px;
    border: 1px solid gainsboro;
  }
`;

const scrollStyleSilkPort = css`
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: ${COLORS.primaryColor}; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.lightBackgroundColor};
    border-radius: 20px;
    border: 1px solid ${COLORS.lightBackgroundColor};
  }
`;

const hideScrollbar = css`
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f3f3f3; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 20px;
    border: 1px solid lightgray;
  }
`;

const mixins = {
  scrollStyleSilkPort,
  scrollStyle,
  flexAlignCenter,
  flexAlignStart,
  hideScrollbar,
};
export default mixins;
