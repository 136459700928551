import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportUser({
  open,
  handleClose,
  title,
  content,
  cancelBtnText,
  confirmBtnText,
  onConfirm,
}) {
  const [reason, setReason] = useState("");
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState("");
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [currentImagePublicId, setCurrentImagePublicId] = useState("");
  const { userId } = useParams();
  const reportRef = useRef();

  useEffect(() => {
    if (localStorage.getItem("reportImgUrl")) {
      setCurrentImageUrl(localStorage.getItem("reportImgUrl"));
      setCurrentImagePublicId(localStorage.getItem("reportImgPublicId"));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setReason("");
    setDescription("");
    if (!reason) {
      toast.error("Please enter the reason");
      return;
    }
    await api.post("user/reportUser", {
      reason: reason,
      description: description,
      reportedUser: userId,
      reportImage: currentImageUrl,
    });
    setCurrentImagePublicId("");
    setCurrentImageUrl("");
    localStorage.removeItem("reportImgUrl");
    localStorage.removeItem("reportImgPublicId");
    toast.success("Report submitted successfully");

    handleClose();
  };

  const hanldeUploadReportImage = (event, type) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    uploadReportImage(fileObj, type);
  };
  const uploadReportImage = (image, type) => {
    setUploading(true);
    let res;
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "silkcabs");
    data.append("cloud_name", "dqmvr7nnr");

    fetch("https://api.cloudinary.com/v1_1/dqmvr7nnr/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then(async (data) => {
        setUploading(false);
        const { url, public_id } = data;
        localStorage.setItem("reportImgUrl", url);
        localStorage.setItem("reportImgPublicId", public_id);
        setCurrentImageUrl(url);
        setCurrentImagePublicId(public_id);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const handleDelete = async () => {
    localStorage.removeItem("reportImgUrl");
    localStorage.removeItem("reportImgPublicId");
    setCurrentImagePublicId("");
    setCurrentImageUrl("");
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary">{title || "Report user"}</DialogTitle>
        <DialogContent>
          <Container>
            <Label htmlFor="reason">Reason for Report:</Label>
            <Input
              type="text"
              id="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
              min={30}
              max={50}
            />

            <Label htmlFor="description">Description (Optional):</Label>
            <TextArea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Label htmlFor="reason">Image(Optional):</Label>
            <input
              style={{ display: "none" }}
              ref={reportRef}
              type="file"
              onChange={(e) => hanldeUploadReportImage(e, "cnic")}
            />
            {currentImageUrl && (
              <img src={currentImageUrl} height={130} width={300} />
            )}
            {!currentImageUrl && (
              <Button
                variant="contained"
                color="primary"
                style={{ borderRadius: "16px", marginTop: "0.5rem" }}
                onClick={() => reportRef.current.click()}
              >
                {uploading ? "Uploading..." : "Upload"}
              </Button>
            )}

            {currentImageUrl && (
              <Button
                variant="text"
                style={{
                  color: "orange",
                  borderRadius: "16px",
                  marginTop: "0.5rem",
                }}
                onClick={handleDelete}
              >
                Delete Image
              </Button>
            )}
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelBtnText}</Button>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            {confirmBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
`;
