import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import useChatRides from "../../hooks/useChatRides";
import moment from "moment";
import { renderStatusText } from "../../Utils";

const ChatRideDetails = ({ currentUserId, chatUserId }) => {
  const chatRides = useChatRides(currentUserId, chatUserId, 20, 0);
  return (
    <StledChatRideDetails>
      <div className="count">
        <p>{chatRides?.count == 0 ? "No" : chatRides?.count} rides found</p>
      </div>
      {chatRides?.count > 0 &&
        chatRides?.data.map((item, index) => (
          <Card>
            <div className="header">
              <div>
                <h3>
                  {item?.Ride?.leaving_from == item?.Ride?.heading_to
                    ? `${item?.Ride?.leaving_from} local`
                    : `${item?.Ride?.leaving_from} to ${item?.Ride?.heading_to}`}
                </h3>
              </div>

              <h3>
                {moment(item?.Ride?.departure_date).format("MMM Do, YYYY")}
              </h3>
            </div>
            <div className="content">
              <div>
                <p className="label">Pickup Location</p>
                <p>{item?.Ride?.pickup_location}</p>
              </div>
              <div>
                <p className="label">Drop Location</p>
                <p>{item?.Ride?.drop_location}</p>
              </div>
              <div>
                <p className="label">Departure Time</p>
                <p>{moment(item?.Ride?.departure_time).format("h:mm A")}</p>
              </div>
              <div>
                <p className="label">Seats required</p>
                <p>{item?.seats}</p>
              </div>
              <div>
                <p className="label">Ticket per seat</p>
                <p>Rs.{item?.Ride?.ticket} </p>
              </div>
              <div>
                <p className="label">Booking request status</p>
                <p>
                  {" "}
                  {renderStatusText(
                    parseInt(item.status),
                    item.requestedBy?.first_name
                  )}
                </p>
              </div>
            </div>
          </Card>
        ))}
    </StledChatRideDetails>
  );
};

export default ChatRideDetails;
const StledChatRideDetails = styled.div`
  height: 66vh;
  widht: 100%;
  border: 1px solid ${COLORS.borderColor};
  padding: 1rem;
  overflow-y: auto;
  .count {
    margin-bottom: 1rem;
  }
`;
const Card = styled.div`
  height: auto;
  background: whitesmoke;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content {
    background: white;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .label {
      color: gray;
    }
  }
`;
