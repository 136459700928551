import React from "react";
import styled from "styled-components";

function CreateGroupBtn({ onClick }) {
  return <StyledGroupBtn onClick={onClick}>create group tour</StyledGroupBtn>;
}

export default CreateGroupBtn;

const StyledGroupBtn = styled.button`
  padding: 0.5rem 1rem;
  background: deepskyblue;
  color: white;
  font-size: 1.4rem;
  border: none;
  text-transform: capitalize;
  border-radius: 23px;
  cursor: pointer;
`;
