// Map.js
"use client";
import React from "react";
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import styled from "styled-components";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/colors";

const Map = ({ lat, lng, width, height, directionResponse }) => {
  const navigate = useNavigate();
  const center = {
    lat,
    lng,
  };
  const containerStyle = {
    width: width ? width : "360px",
    height: height ? height : "400px",
  };
  const mapOptions = {
    zoomControl: true, // Enable zoom control (zoom in and zoom out buttons)
    mapTypeControl: false, // Disable map type control
    fullscreenControl: false, // Disable fullscreen control
  };

  return (
    <StyledMap>
      <div className="info">
        <span
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          {" "}
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Go Back
        </span>
        <span className="msg">
          <FaInfoCircle color="gray" />
          <p>Refresh the page if the map does not load</p>
        </span>
      </div>
      <div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          options={mapOptions}
        >
          {directionResponse && (
            <DirectionsRenderer
              directions={directionResponse}
              options={{
                polylineOptions: {
                  strokeWeight: 5, // Set the stroke weight (thickness)
                  strokeColor: COLORS.primaryColor, // Change the route color to red
                },
              }}
            />
          )}
        </GoogleMap>
      </div>
    </StyledMap>
  );
};

export default Map;
const StyledMap = styled.div`
  .info {
    position: absolute;
    z-index: 1;
    padding: 1rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    align-items: self-start;

    background: rgb(245 245 245 / 70%);
    .go-back {
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      font-size: 1.2rem;
      :hover {
        font-weight: 600;
      }
    }
    .msg {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
`;
