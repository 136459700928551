import { makeStyles } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import VehicleInformation from "../../components/VehicleInformation";
import { addVehicle } from "../../redux/actions";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  animation: modal 0.3s forwards;
  width: auto;
  margin: 1rem;

  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ModalInput = styled.input`
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const AddCarModal = ({
  isOpen,
  setIsOpen,
  publishRideInfo,
  auth,
  dispatch,
  fetchVehicles = () => {},
}) => {
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const submitAddVehicleForm = async (data) => {
    let dataWithRideInfo = {
      ...data,
      rideId: publishRideInfo?.currentRideInfo?.ride_id,
    };
    await dispatch(addVehicle(dataWithRideInfo, auth.currentUser.id));

    setIsOpen(false);
    fetchVehicles();
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper onClick={handleCloseModal}>
          <ModalContent onClick={(event) => event.stopPropagation()}>
            <ModalTitle>Add Vehicle</ModalTitle>
            <VehicleInformation
              submitAddVehicleForm={submitAddVehicleForm}
              setIsOpen={setIsOpen}
            />
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};
export default AddCarModal;
