import { IconButton, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import SearchForm from "../../components/SearchForm";
import { GlobalContext } from "../../contexts/globalContext";
import { FaCross } from "react-icons/fa";
import { Close } from "@material-ui/icons";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #f4f4f4;
  padding: 2rem;
  @media (max-width: 546px) {
    padding: 1rem;
  }
  border-radius: 5px;
  animation: modal 0.3s forwards;
  width: 100%;
  margin: 1rem;
  max-width: 874px;
  min-height: 70dvh;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Modal = ({ isOpen, setIsOpen }) => {
  const { searchFields, setSearchFields } = useContext(GlobalContext);
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper onClick={handleCloseModal}>
          <ModalContent onClick={(event) => event.stopPropagation()}>
            <div className="header">
              <ModalTitle>Find a Ride</ModalTitle>
              <IconButton onClick={handleCloseModal} size="medium">
                <Close />
              </IconButton>
            </div>
            <SearchForm
              searchFields={searchFields}
              setSearchFields={setSearchFields}
            />
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};
export default Modal;
