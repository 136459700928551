import styled from "styled-components";

export const StyledHeader = styled.header`
  height: 4.5rem;
`;

export const StyledMain = styled.main`
  overflow: auto;
  height: 90vh;
  width: 100%;

  &.hide-scrollbar {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f3f3f3;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 20px;
    border: 1px solid lightgray;
  }

  .children {
    @media (max-width: 880px) {
    }
  }

  @media (max-width: 880px) {
  }
`;

export const StyledFooter = styled.footer``;
