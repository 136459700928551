import React, { useState } from "react";
import styled from "styled-components";
import {
  FaBuilding,
  FaPhone,
  FaMapMarkerAlt,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";
import { COLORS } from "../../../assets/colors";

const CompanyInformation = ({ formData, handleChange }) => {
  const [error, setError] = useState({
    companyName: "",
    companyContact: "",
    address: "",
    email: "",
    website: "",
  });

  const validateField = (field, value) => {
    let errorText = "";
    if (field === "companyName" && value.trim() === "") {
      errorText = "Company name is required";
    }
    if (field === "companyContact") {
      const phoneRegex = /^(\+\d{1,9})?\d{11}$/;
      if (!phoneRegex.test(value)) {
        errorText = "Please enter a valid phone number";
      }
    }
    if (field === "address" && value.trim() === "") {
      errorText = "Office Address is required";
    }
    return errorText;
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    handleChange(field)(event);

    const errorText = validateField(field, value);
    setError({ ...error, [field]: errorText });
  };

  return (
    <Wrapper>
      <TitleSection>
        <h2>Company Information</h2>
        <p>Only registered travel companies can create group tours*</p>
      </TitleSection>
      <Form>
        <FormGroup>
          <Label>
            <FaBuilding /> Company Name
          </Label>
          <Input
            type="text"
            placeholder="Enter tour company name"
            value={formData.companyName}
            onChange={handleInputChange("companyName")}
            error={error.companyName !== ""}
          />
          {error.companyName && <ErrorText>{error.companyName}</ErrorText>}
        </FormGroup>
        <FormGroup>
          <Label>
            <FaPhone /> Contact
          </Label>
          <Input
            type="text"
            placeholder="Provide your company contact"
            value={formData.companyContact}
            onChange={handleInputChange("companyContact")}
            error={error.companyContact !== ""}
          />
          {error.companyContact && (
            <ErrorText>{error.companyContact}</ErrorText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            <FaMapMarkerAlt /> Address
          </Label>
          <Input
            type="text"
            placeholder="Enter your company address"
            value={formData.address}
            onChange={handleInputChange("address")}
            error={error.address !== ""}
          />
          {error.address && <ErrorText>{error.address}</ErrorText>}
        </FormGroup>
        <FormGroup>
          <Label>
            <FaEnvelope /> Email (Optional)
          </Label>
          <Input
            type="email"
            placeholder="Company email"
            value={formData.email}
            onChange={handleInputChange("email")}
            error={error.email !== ""}
          />
          {error.email && <ErrorText>{error.email}</ErrorText>}
        </FormGroup>
        <FormGroup>
          <Label>
            <FaGlobe /> Website (Optional)
          </Label>
          <Input
            type="text"
            placeholder="Company Website"
            value={formData.website}
            onChange={handleInputChange("website")}
            error={error.website !== ""}
          />
          {error.website && <ErrorText>{error.website}</ErrorText>}
        </FormGroup>
      </Form>
    </Wrapper>
  );
};

export default CompanyInformation;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  color: #3d485d;
  font-family: "Arial", sans-serif;

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 95%;
  }
`;

const TitleSection = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 24px;
    color: ${COLORS.primaryColor};

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    margin: 5px 0 0;
    font-size: 14px;
    color: gray;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${COLORS.primaryColor};

  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    margin-bottom: 6px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid lightgray;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${COLORS.primaryColor};
  }

  ${(props) =>
    props.error &&
    `
    border-color: ${COLORS.errorColor};
  `}

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const ErrorText = styled.p`
  color: ${COLORS.errorColor};
  margin-top: 4px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
