// // MainForm.js
import React, { useState } from "react";
import CompanyInformation from "./../components/groupSection/Groupinfo/CompanyInformation";
import TourDetailss from "./../components/groupSection/Groupinfo/TourDetailss";
import TourPlan from "./../components/groupSection/Groupinfo/TourPlan";
import { COLORS } from ".././assets/colors";
import styled from "styled-components";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const GroupTourForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    companyName: "",
    companyOwner: "",
    companyContact: "",
    address: "",
    emailAddress: "",
    govtRegisterNumber: "",
    completedTours: "",
    rating: "",
    tourTitle: "",
    tourDescription: "",
    toursOperator: "",
    ageRange: "",
    tourId: "",
    maxMembers: "",
    coupleDocuments: "",
    tourDuration: "",
    restPlaces: "",
    vehicleType: "",
    foodArrangements: "",
    perMemberCost: "",
  });

  const handleNext = () => {
    if (step === 1) {
      if (
        !formData.companyName ||
        !formData.companyContact ||
        !formData.address
      ) {
        return;
      }
    } else if (step === 2) {
      if (
        !formData.toursOperator ||
        !formData.ageRange ||
        !formData.maxMembers ||
        !formData.coupleDocuments ||
        !formData.tourDuration ||
        !formData.vehicleType ||
        !formData.perMemberCost
      ) {
        return;
      }
    } else if (step === 3) {
      // Validate fields for step 3 if needed
      // For example, you can add validation logic for step 3 fields here
      // if (!formData.someFieldForStep3) {
      //   return;
      // }
    }
    nextStep();
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  return (
    <Wrapper>
      <StepWrapper active={step === 1}>
        <div className="container">
          <CompanyInformation formData={formData} handleChange={handleChange} />
          <Button onClick={handleNext}>
            Next <FaArrowRight />
          </Button>
        </div>
      </StepWrapper>
      <StepWrapper active={step === 2}>
        <div className="container">
          <TourDetailss formData={formData} handleChange={handleChange} />
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button onClick={prevStep}>
              <FaArrowLeft /> Back
            </Button>
            <Button onClick={handleNext}>
              Next <FaArrowRight />
            </Button>
          </div>
        </div>
      </StepWrapper>
      <StepWrapper active={step === 3}>
        <div className="container">
          <TourPlan formData={formData} handleChange={handleChange} />
          <Button onClick={prevStep}>
            <FaArrowLeft /> Back
          </Button>
        </div>
      </StepWrapper>
    </Wrapper>
  );
};

export default GroupTourForm;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

const StepWrapper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  padding: 2rem;
  margin-bottom: 2rem;
  .container {
    max-width: 800px;
    margin: auto;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 10px;
  background-color: ${COLORS.primaryColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  svg {
    margin-left: 8px;
  }

  &:hover {
    background-color: #0056b3;
  }
`;
