import React, { useState } from "react";
import { Avatar, TextField } from "@material-ui/core";
import logo from "../../images/iPhone.png";
import styled from "styled-components";
import CustomButton from "../UI/Button";
import { validatePasswordResetForm } from "../../Utils";
function ResetPassword({ submitPasswordResetForm, isLoading }) {
  const [state, setState] = useState({
    email: "",
    currentPassword: "",
    newPassword: "",
  });

  let errorsobj = {
    email: "",
    currentPassword: "",
    newPassword: "",
  };

  const [error, setError] = useState(errorsobj);
  const onSubmit = (e) => {
    e.preventDefault();
    const passwordResetErrorObj = validatePasswordResetForm(state);
    setError(passwordResetErrorObj.errorObj);

    if (passwordResetErrorObj.hasError) {
      return;
    }
    submitPasswordResetForm(state);
  };

  return (
    <MainContainer>
      <LogoContainer>
        <Avatar src={logo} style={{ height: "60px", width: "60px" }} />
        <h2 style={{ color: "#3d485d" }}>Reset Password</h2>
      </LogoContainer>

      <FormContainer>
        <form onSubmit={onSubmit}>
          <TextField
            id="filled-email-input"
            label="Email"
            type="email"
            required
            fullWidth
            autoComplete="current-email"
            variant="outlined"
            onChange={(e) =>
              setState({
                ...state,
                email: e.target.value,
              })
            }
            error={error.email != ""}
            helperText={error.email}
          />

          <TextField
            id="filled-password-input"
            label="current-password"
            type="current-password"
            fullWidth
            required
            autoComplete="current-password"
            variant="outlined"
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
            error={error.currentPassword != ""}
            helperText={error.currentPassword}
          />
          <TextField
            id="filled-password-input"
            label="new-password"
            type="new-password"
            fullWidth
            requiredS
            autoComplete="new-password"
            variant="outlined"
            onChange={(e) =>
              setState({
                ...state,
                confirmPassword: e.target.value,
              })
            }
            helperText={error.newPassword}
            error={error.newPassword != ""}
          />

          <ButtonContainer>
            <CustomButton
              label={isLoading ? "Please wait" : "Password Reset"}
              type="Submit"
            />
          </ButtonContainer>
        </form>
      </FormContainer>
    </MainContainer>
  );
}

export default ResetPassword;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
  box-shadow: 2px 2px 10px #888888;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  align-items: center;
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  > form > .MuiTextField-root {
    text-align: center;
    color: white;
    margin-bottom: 5px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  min-height: 50px;
  > a {
    text-decoration: none;
    color: grey;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
`;
