import React, { useState } from "react";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import Avatar from "@material-ui/core/Avatar";
import CancelButton from "../UI/CancelButton";

const Container = styled.div`
  max-width: 500px;
  min-width: 360px;
  margin: 0 auto;
  border: 1px solid lightgray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  p {
    color: ${COLORS.secondaryTextColor};
    font-size: small;
    text-align: center;
    max-width: 400px;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  .exp-level {
    font-weight: bold;
  }
`;

const Star = styled(FaStar)`
  cursor: pointer;
  font-size: 24px;
  color: ${({ selected, theme }) => (selected ? "#FFD700" : "beige")};
`;

const ReviewContainer = styled.div`
  margin-bottom: 20px;
  textarea {
    border-color: lightgray;
    padding: 5px;
    border-radius: 4px;
    width: 300px;
    :focus {
      outline: 1px solid ${COLORS.primaryColor};
    }
  }
`;

const SubmitButton = styled.button`
  background-color: ${({ theme }) => COLORS.primaryColor};
  color: white;
  padding: 10px 45px;
  border: none;
  cursor: pointer;
  background: ${COLORS.primaryColor};
  border-radius: 4px;
`;
const ActionButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const FeedbackForm = ({
  traveller,
  rideId,
  rideStatus,
  iAmDriver,
  onSubmitFeedback,
  doItLater,
}) => {
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");

  const handleRatingClick = (value) => {
    setRating(value);
  };

  const handleSubmit = () => {
    onSubmitFeedback(review, rating);
  };
  const calculateExperience = (rating) => {
    switch (rating) {
      case 1:
        return "Worst";
      case 2:
        return "Bad";
      case 3:
        return "Satisfactory";
      case 4:
        return "Good";
      case 5:
        return "Excellent";
    }
  };
  return (
    <Container>
      <Avatar
        src={
          iAmDriver ? traveller?.requestedBy?.avatar_url : traveller?.avatar_url
        }
        style={{ width: "70px", height: "70px" }}
      />
      <h2>
        {iAmDriver ? traveller?.requestedBy?.first_name : traveller?.first_name}
        &nbsp;
        {iAmDriver ? traveller?.requestedBy?.last_name : traveller?.last_name}
      </h2>
      <p>
        Your feedback is of paramount importance to improve the quality of
        service we provide. We hope you have had a great experience.
      </p>
      <RatingContainer>
        <div>
          {[1, 2, 3, 4, 5].map((value) => (
            <Star
              key={value}
              selected={value <= rating}
              onClick={() => handleRatingClick(value)}
            />
          ))}
        </div>
        <p className="exp-level">{calculateExperience(rating)}</p>
      </RatingContainer>

      <ReviewContainer>
        <textarea
          placeholder="Write your review here..."
          value={review}
          onChange={(e) => setReview(e.target.value)}
          rows="5"
          cols="30"
        />
      </ReviewContainer>
      <ActionButtonContainer>
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        <CancelButton label={"Do it later"} onClick={doItLater} />
      </ActionButtonContainer>
    </Container>
  );
};

export default FeedbackForm;
