import * as types from "../constants";
const initialState = {
  isLoading: false,
  success: false,
  error: "",
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_REQUEST:
      return { ...state, isLoading: true };
    case types.REGISTER_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case types.REGISTER_CLEAN_UP:
      return { ...state, isLoading: false, success: false, error: "" };
    case types.REGISTER_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default registerReducer;
