import styled from "styled-components";
import { COLORS } from "../../assets/colors";

export const NoDataIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: "gainsboro" }}
    viewBox="0 0 24 24"
    id="not-available"
  >
    <path d="M19,6H18a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V13h3v4a1,1,0,0,0,2,0V9A3,3,0,0,0,19,6Zm1,5H17V9a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1ZM8,6A1,1,0,0,0,7,7v5.76L3.89,6.55A1,1,0,0,0,2,7V17a1,1,0,0,0,2,0V11.24l3.11,6.21A1,1,0,0,0,8,18a.91.91,0,0,0,.23,0A1,1,0,0,0,9,17V7A1,1,0,0,0,8,6Zm4-2a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,12,4Z"></path>
  </svg>
);

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  margin-top: 3rem;
  img {
    margin-bottom: 1rem;
  }
  @media (max-width: 546px) {
    margin-top: 0.5rem;
    padding-bottom: 5rem;
  }
`;

export const Icon = styled(NoDataIcon)`
  fill: #ccc;
  margin-bottom: 0.5rem;
`;
export const Title = styled.h3`
  font-size: x-large;
  font-weight: bold;
  color: ${COLORS.primaryTextColor};
  text-align: center;
  @media (max-width: 546px) {
    font-size: medium;
  }
`;
export const Message = styled.p`
  font-size: 1.2rem !important;
  color: ${COLORS.secondaryTextColor} !important;
  text-align: center !important;
  max-width: 600px !important;
  margin-top: 8px !important;
  font-weight: 500 !important;
  @media (max-width: 546px) {
    font-size: small !important;
  }
`;
