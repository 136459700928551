import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import api from "../../services/api";
import { FaStar } from "react-icons/fa";
import CircularLoader from "../UI/Loader";

function FeedbackList({ rideId }) {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchFeedback(rideId);
  }, [rideId]);

  async function fetchFeedback(rideId) {
    try {
      setLoading(true);
      const response = await api.get(`/feedback/feedbackByRideId/${rideId}`);
      setFeedback(response.data.data.rows);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }
  if (loading) {
    return <CircularLoader isLoading={loading} />;
  }

  return (
    <Container>
      <div className="body">
        <div className="cotraveller">
          <h4 className="travellerheading"> Feedback</h4>
          {feedback?.length == 0 && <p>No reviews yet.</p>}
          {feedback?.length > 0 &&
            feedback.map((traveller) => {
              console.log("Traveller", traveller);
              return (
                <div className="passanger">
                  <div
                    className="avatarcontainer"
                    onClick={() => {
                      navigate(`/profile/${traveller.reviewerInfo.id}`);
                    }}
                  >
                    <h2 className="avatar">
                      {traveller.reviewerInfo.first_name}&nbsp;
                    </h2>
                  </div>
                  <div className="review-text">{traveller.review_text}</div>
                  <div>
                    <div className="stars">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <Star
                          key={value}
                          selected={value <= traveller.rating}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Container>
  );
}

export default FeedbackList;
const Star = styled(FaStar)`
  font-size: small;
  color: ${({ selected, theme }) => (selected ? "black" : "gray")};
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 546px) {
    justify-content: flex-start;
  }
  .body {
    border-radius:1rem;
    width: 75%;
    @media(max-width:600px){
      width: 100%;

    }
    padding: 1rem;
    font-size: 12px;
    color: ${COLORS.primaryColor};
        .cotraveller {
      .travellerheading {
        color: ${COLORS.primaryColor};
        font-size:1rem;
        margin-bottom: 0.5rem;
      }
      p {
        color: ${COLORS.secondaryTextColor};
        font-size:14px;
      }
      .passanger{
        display: flex;
        align-items: start;
        justify-content:space-between;
        cursor: pointer;
        flex-direction: column;
        border-radius: 5px;
        padding: 0.5rem;
        background-color: #f6fafa;
        margin-bottom:1rem;
        .add-review {
          border: 1px solid;
          padding: 4px 8px;
          border-radius: 18px; 
           :hover {
               background-color: ${COLORS.lightBackgroundColor};
               border: 2px solid;

          }
        }

        .avatarcontainer {
        display: flex;
        align-items: center;
        :hover {
          text-decoration: underline;
        }
        .avatar{
          font-size:14px;
          color:black;
          text-transform: capitalize;
        }
        
        .img{
          width:31px;
          height:30px;
          background:#054752;
        }

        }
        .review-text {
          margin: 0.5rem 0;
        }
    }
      }
    }
  }
`;
