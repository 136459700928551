import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SignUp from "../../components/signup";
import api from "../../services/api";
import { useState } from "react";
import ReactGA from "react-ga4";
import signupImg from "../../images/signup.svg";
import { LOGIN_SUCCESS, SET_CURRENT_USER } from "../../redux/constants";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitRegisterForm = async (data) => {
    setIsLoading(true);
    try {
      if (data.password !== data.confirmPassword) {
        return;
      }
      ReactGA.event({
        category: "Button Click",
        action: "Clicked on Register submit",
        label: "Register Page",
      });
      await api
        .post(`/auth/register`, {
          first_name: data.firstName,
          last_name: data.lastName,
          mobile_number: data.mobileNumber,
          email: data.email,
          password: data.password,
          gender: data.selectedGender,
          dob: data.dob,
        })
        .then((result) => {
          if (result.status === 200) {
            setIsLoading(false);
            const token = result.data.token;
            localStorage.setItem("authToken", token);
            const decoded = jwt_decode(token);

            dispatch({
              type: SET_CURRENT_USER,
              payload: { ...decoded, token },
            });
            dispatch({
              type: LOGIN_SUCCESS,
            });
            navigate("/otp");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.response.data);
        });
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data);
    }
  };

  return (
    <RegisterContainer>
      <div className="left">
        <img src={signupImg} height={"200px"} width={"auto"} />
        <h2>Make your travelling less expensive</h2>
        <p>- Publish a ride and share your car seats</p>
        <p>- Book a ride online and share the cost with other passengers</p>
        <p>
          - Safety of driver and passengers is ensured by regular verification
        </p>
      </div>
      <SignUp
        submitRegisterForm={submitRegisterForm}
        isLoading={isLoading}
        signupError={error}
      />
    </RegisterContainer>
  );
};

export default Register;

const RegisterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 5rem;
  .left {
    @media (max-width: 768px) {
      display: none;
    }
    padding: 2rem;
    height: 60vh;
    h2 {
      color: rgb(5, 70, 82);
      margin-bottom: 1rem;
    }
    p {
      color: rgb(112, 140, 145);
    }
  }
`;
