import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import { IconButton } from "@material-ui/core";
import { FaMinus, FaPlus } from "react-icons/fa";
import moment from "moment";
import PayNowButton from "../../components/UI/Paynow";
import { GlobalContext } from "../../contexts/globalContext";
import CancelButton from "../../components/UI/CancelButton";

function Summary({ ride, submitPayment, loading }) {
  const navigate = useNavigate();
  const { searchFields, setSearchFields } = useContext(GlobalContext);

  const ticketPrice = ride?.ticket;
  const commissionPercentage = 7;
  const serviceChargesPercentage = 3;
  const gosawariCommission = Math.ceil(
    (ticketPrice * commissionPercentage) / 100
  );
  const serviceCharges = Math.ceil(
    (ticketPrice * serviceChargesPercentage) / 100
  );
  const totalAmountToPay =
    (serviceCharges + gosawariCommission) *
    parseInt(searchFields.seats ? searchFields.seats : "1");

  const increaseSeats = (seatsRequired) => {
    let seatsNeeded = parseInt(seatsRequired);
    if (ride?.seats_available <= seatsNeeded) {
      return;
    }
    setSearchFields({ ...searchFields, seats: seatsNeeded + 1 });
  };
  const decreaseSeats = (seatsRequired) => {
    let seatsNeeded = parseInt(seatsRequired);
    if (seatsNeeded <= 1) {
      return;
    }
    setSearchFields({ ...searchFields, seats: seatsNeeded - 1 });
  };

  return (
    <Rightcontainer>
      <div className="rightcard">
        <div className="card">
          <p className="subtitle">
            You will need to pay{" "}
            <strong>
              Rs.
              {ride.ticket *
                parseInt(searchFields.seats ? searchFields.seats : "1")}
              .00
            </strong>{" "}
            for {parseInt(searchFields.seats ? searchFields.seats : "1")}{" "}
            seat(s) in cash to the driver in the car. Please note that we charge
            only the service commission.We kindly ask that you bring the exact
            change to facilitate the payment to the driver during the ride.
          </p>
          <p className="subtitle">
            After completing your payment, you will receive the driver's contact
            details on your bookings page. Alternatively, you can communicate
            directly with the driver using the GoSawari Chat System.
          </p>
          <div className="ride-details">
            <div className="summary">
              <SummaryItem>
                <span>Cash Payment:</span>{" "}
                <strong>
                  Rs.
                  {ride?.ticket *
                    parseInt(searchFields.seats ? searchFields.seats : "1")}
                  .00
                </strong>
              </SummaryItem>
              <SummaryItem>
                <span>Seats required:</span>{" "}
                <div className="seat-required">
                  <div>
                    <IconButton
                      size="small"
                      style={{ background: "#f3f3f3", padding: "0.5rem" }}
                      onClick={() =>
                        decreaseSeats(
                          searchFields.seats ? searchFields.seats : "1"
                        )
                      }
                    >
                      <FaMinus />
                    </IconButton>
                  </div>

                  <strong>
                    <span className="seats">
                      {searchFields.seats ? searchFields.seats : "1"}
                    </span>
                  </strong>
                  <IconButton
                    size="small"
                    style={{ background: "#f3f3f3", padding: "0.5rem" }}
                    onClick={() =>
                      increaseSeats(
                        searchFields.seats ? searchFields.seats : "1"
                      )
                    }
                  >
                    <FaPlus />
                  </IconButton>
                </div>
              </SummaryItem>
              <SummaryItem>
                <span>Pickup location:</span>
                <span className="info">
                  {ride?.leaving_from},{ride?.pickup_location}
                </span>
              </SummaryItem>
              <SummaryItem>
                <span>Drop location:</span>
                <span className="info">
                  {ride?.heading_to},{ride?.drop_location}
                </span>
              </SummaryItem>
              <SummaryItem>
                <span>Departure Date:</span>{" "}
                <span className="info">
                  {moment(ride?.departure_date).format("MMMM Do, YYYY")}
                </span>
              </SummaryItem>
              <SummaryItem>
                <span>Departure time:</span>{" "}
                <span className="info">
                  {moment(ride?.departure_time).format("h:mm A")}
                </span>
              </SummaryItem>
            </div>
          </div>
        </div>

        <div className="subtotalcontainer">
          <div className="innercontainer">
            <p className="itemname">GoSawari commision</p>
            <p className="itemprice">
              Rs:&nbsp;{" "}
              {gosawariCommission *
                parseInt(searchFields.seats ? searchFields.seats : "1")}
            </p>
          </div>
          <div className="innercontainer">
            <p className="itemname">Service charges</p>
            <p className="itemprice">
              Rs:&nbsp;{" "}
              {serviceCharges *
                parseInt(searchFields.seats ? searchFields.seats : "1")}
            </p>
          </div>
        </div>
      </div>

      <div className="totalsum">
        <p className="total">Total GoSawari Charges</p>
        <p className="price">
          <strong>Rs:&nbsp; {totalAmountToPay}</strong>
        </p>
      </div>
      <div className="button-grp">
        <CancelButton
          label={"Go Back"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <PayNowButton
          loading={loading}
          label={`Pay Rs.${totalAmountToPay}`}
          onClick={(e) => submitPayment(totalAmountToPay)}
        />
      </div>
    </Rightcontainer>
  );
}

export default Summary;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.25rem;
  .info {
    color: gray;
  }
  .seat-required {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    max-width: 150px;
    align-items: center;
    margin-top: 0.5rem;
    .seats {
      border: 2px solid skyblue;
      padding: 0.5rem 1rem;
      border-radius: 8px;
    }
  }
`;
const Rightcontainer = styled.div`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 600px;
  @media (max-width: 767px) {
    padding-bottom: 4rem;
  }
  margin: auto;
  .rightcard {
    .heading {
      font-size: 18px;
      color: ${COLORS.secondaryColor};
      margin-top: 2rem;
    }
    .card {
      .summary {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        div {
          span {
            // font-weight: bold;
          }
        }
      }
      .subtitle {
        font-size: 0.9rem;
        margin: 1.5rem 0;
        color: ${COLORS.gray};
      }
      .ride-details {
        height: auto;
        width: 100%;
        padding: 1rem;
        border: 1px solid ${COLORS.borderColor};
        border-radius: 8px;
        .info {
          font-size: 14px;
          justify-content: flex-start;
          display: flex;
          align-items: center;
          span {
            svg {
              font-size: 20px;
            }
          }
        }
      }
    }
    .subtotalcontainer{
      border-bottom: 1px solid ${COLORS.borderColor};
      border-top: 1px solid ${COLORS.borderColor};
      
      margin-top: 1rem;
      height: auto;
      padding: 1rem 0;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      .innercontainer{
        display: flex;
        justify-content: space-between;
    }

        .itemname{
          font-size:13px;
          font-weight:600;
          text-transform:capitalize;
          color: ${COLORS.primaryColor};
        }
        .itemprice{
          font-size:13px;
          font-weight:600;
          text-transform:capitalize;
          color: rgb(61, 72, 93);
        }

      }
    }
  }
  .totalsum{
    display:flex;
    justify-content:space-between;
    margin-top: 1rem;
    .total{
      font-size:13px;
      font-weight:600;
      text-transform:capitalize;
      color: ${COLORS.primaryColor};
    }
    .price {
      font-size:18px;
      font-weight:600;
      text-transform:capitalize;
      color: rgb(61, 72, 93);
    }
  }

  .button-grp{
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top:1rem;
    @media(max-width: 600px){
      flex-direction:column-reverse;
    }
  }
`;
