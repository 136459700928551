import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import CustomButton from "../UI/Button";
import InputField from "../UI/InputField";
import useInput from "../../hooks/useInput";
import api from "../../services/api";
import jwt_decode from "jwt-decode";
import { LOGOUT_CURRENT_USER, SET_CURRENT_USER } from "../../redux/constants";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { COLORS } from "../../assets/colors";
import { useNavigate } from "react-router-dom";
import { socket } from "../../socket";

function AccountSettingForm({ user }) {
  const firstName = useInput(user?.first_name);
  const lastName = useInput(user?.last_name);
  const [dob, setDob] = useState(user?.dob ? new Date(user.dob) : new Date());
  const [selectedGender, setSelectedGender] = useState(user?.gender);
  const [deleteText, setDeleteText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.patch(`user/updateAccount/${user.id}`, {
        first_name: firstName.value,
        last_name: lastName.value,
        // gender: selectedGender,
        dob: dob,
      });
      const token = response.data.data;
      localStorage.setItem("authToken", token);
      const decoded = jwt_decode(token);
      dispatch({
        type: SET_CURRENT_USER,
        payload: { ...decoded, token },
      });
      toast.success("Account updated successfully");
    } catch (er) {
      console.log(er);
    }
  };

  const deleteMyAccount = async () => {
    try {
      const response = await api.delete("user/delete-account");
      if (response.status === 200) {
        toast.success("account deleted");
        socket.emit("logout", user?.id);
        dispatch({
          type: LOGOUT_CURRENT_USER,
        });
        localStorage.removeItem("authToken");
        window.location.replace("/");
      }
    } catch (e) {
      toast.error("Failed to delete your account");
    }
  };
  return (
    <MainContainer>
      <Typography variant="h6" color="textPrimary">
        Update account
      </Typography>

      <Typography variant="subtitle2" color="textSecondary">
        Update your account information
      </Typography>

      <FormContainer>
        <form onSubmit={handleSubmit}>
          <InputField
            label="First Name"
            type="text"
            fullWidth={true}
            variant="outlined"
            required={true}
            maxLength={20}
            pattern={"[A-Za-z\\s]*"}
            {...firstName}
          />

          <InputField
            label="Last Name"
            type="text"
            fullWidth={true}
            variant="outlined"
            required={true}
            maxLength={20}
            pattern={"[A-Za-z\\s]*"}
            {...lastName}
          />
          <div className="dobcontainer">
            <DatePicker
              className="datepicker"
              selected={dob}
              onChange={(date) => setDob(date)}
              placeholderText={"DOB e.g 1994-12-15"}
              isClearable
              showYearDropdown
              useShortMonthInDropdown
            />
            {/* {error.dob && <p className="error">{error.dob}</p>} */}
          </div>

          {/* <div className="radioContainer">
            <p>Gender:</p>
            <RadioLabel>
              <RadioInput
                type="radio"
                name="gender"
                value="male"
                checked={selectedGender === "male"}
                onChange={(e) => setSelectedGender(e.target.value)}
              />
              Male
            </RadioLabel>

            <RadioLabel>
              <RadioInput
                type="radio"
                name="gender"
                value="female"
                checked={selectedGender === "female"}
                onChange={(e) => setSelectedGender(e.target.value)}
              />
              Female
            </RadioLabel>
          </div> */}

          <BtnContainer>
            <CustomButton label="save" type="submit" />
          </BtnContainer>
        </form>
      </FormContainer>
      <AccountZone>
        <Typography variant="h6" color="textPrimary">
          Deactivate your Account
        </Typography>
        <p>
          Type <strong>DELETE ACCOUNT</strong> to delete your account
        </p>
        <InputField
          label="Delete"
          type="text"
          variant="outlined"
          required={true}
          maxLength={20}
          pattern={"[A-Za-z\\s]*"}
          onChange={(e) => setDeleteText(e.target.value)}
        />
        <button
          disabled={deleteText !== "DELETE ACCOUNT"}
          className="delete-btn"
          onClick={deleteMyAccount}
        >
          Confirm Delete
        </button>
      </AccountZone>
    </MainContainer>
  );
}

export default AccountSettingForm;
const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding-bottom: 3rem;
  background-color: smokeWhite;
  flex-direction: column;
  > p:nth-child(2) {
    font-size: 12px;
    color: gray;
    font-weight: 900;
  }
`;

const AccountZone = styled.div`
  max-width: 466px;
  width: 100%;
  border: 2px dashed red;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  border-radius: 4px;
  .delete-btn {
    padding: 1rem;
    background: #c63737;
    color: white;
    border: navajowhite;
    border-radius: 8px;
    max-width: 150px;
    cursor: pointer;
    :disabled {
      background: gray;
      cursor: not-allowed;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  padding-top: 20px;
  

  > form > .MuiTextField-root {
    margin: 10px 0px;
  }
  .radioContainer {
    display:flex;
    margin: 1rem 0;
    label {
      cursor: pointer;
      input {
        cursor: pointer;
      }
    }
    .error {
       color:${COLORS.errorColor};
       padding: 5px 0;
       text-align: left;
   }
    p{
     margin-right:1rem;
    }
   }
   .datepicker{
    width:100%;
    height: 3rem;
    padding: 0 8px;
    font-size: 16px;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  .react-datepicker__navigation--next {
    background-color: #054752;
    color: #054752;
  }
  .react-datepicker__navigation--previous {
    background-color: #054752;
    color: #054752;
  }
  .react-datepicker__year-read-view--down-arrow {
    color: #054752;
  }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #054752;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: #054752;
    color: #fff;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: #054752;
    color: #fff;
  }
  .error {
    color:${COLORS.errorColor}
    padding: 5px 0;
    text-align: left;
  }
}
  
`;

const BtnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: end;
  > div:nth-child(1) {
    margin-right: 21px;
  }
`;
const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const RadioInput = styled.input`
  margin-right: 16px;
  margin-top: -6px;

  /* Default color */
  &:checked {
    &::before {
      background-color: ${COLORS.primaryColor};
    }
  }

  /* Disabled color */
  &:disabled {
    &::before {
      background-color: lightgray;
    }
  }

  /* Radio button appearance */
  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${COLORS.primaryColor};
    background-color: white;
    transition: background-color 0.2s ease-in-out;
  }
`;
