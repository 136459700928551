import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import RideV2 from "../RideV2";
import PaginationComp from "../Pagination";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import CircularLoader from "../UI/Loader";
import { FaArrowRight } from "react-icons/fa";

const ArrowRightIcon = styled(FaArrowRight)`
  padding-top: 5px;
`;
const useStyles = makeStyles({
  Containerstles: {
    minHeight: "50vh",
  },
  typoStyles: {
    color: "rgb(112, 140, 145)",
  },
});

export default function RideList({
  count,
  rides,
  leavingFrom,
  goingTo,
  setCurrentPage,
  isLoading,
  currentPage,
}) {
  const classes = useStyles();
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1,
  });
  if (isLoading) {
    return <CircularLoader isLoading={isLoading} />;
  }
  return (
    <Container className={classes.Containerstles} maxWidth="sm">
      <Box className={classes.Boxstyles} p={2}>
        <Grid container className={classes.Gridcontainerstyles}>
          {leavingFrom && goingTo && (
            <Grid item xs={12} sm={10}>
              <Typography variant="p" className={classes.typoStyles}>
                {leavingFrom ? leavingFrom : null}{" "}
                <span>
                  <ArrowRightIcon />
                </span>{" "}
                {goingTo ? goingTo : null}:
                <br />
                {count} {count == 1 ? "ride" : "rides"} available
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <StyledRideContainer>
        {rides.map((ride) => (
          <animated.div style={fade}>
            <RideV2 ride={ride} />
          </animated.div>
        ))}
      </StyledRideContainer>

      {rides?.length > 1 && (
        <PaginationComp
          count={count < 20 ? 1 : Math.ceil(count / 20)}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </Container>
  );
}

const StyledRideContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
