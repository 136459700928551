import React from 'react';
import styled from 'styled-components'; 

const EmployeeContent = ({title, name, department, age, discipline, status }) => {
  return (
    <EmployeeWrapper>
      <h2>{title}</h2>
      <table>
        <tbody className='table-body'>
        <tr>
            <td><strong>Name:</strong></td>
            <td>{name}</td>
          </tr>
          <tr>
            <td><strong>Department:</strong></td>
            <td>{department}</td>
          </tr>
          <tr>
            <td><strong>Age:</strong></td>
            <td>{age}</td>
          </tr>
          <tr>
            <td><strong>Discipline:</strong></td>
            <td>{discipline}</td>
          </tr>
          <tr>
            <td><strong>Status:</strong></td>
            <td>{status}</td>
          </tr>
        </tbody>
      </table>
    </EmployeeWrapper>
  );
}

export default EmployeeContent;

const EmployeeWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    display:flex;

  }
  .table-body{
    width: 100%;
    display: flex;

  }

  td {
    width: 100%;

    padding: 8px;
    display: flex;
    flex-direction: column;

  }

  td:first-child {
    width: 120px;
    font-weight: bold;
  }
`;
