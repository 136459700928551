export const COLORS = {
  headerColor: "#282C35",
  borderColor: "#ebe9e9",
  primaryColor: "#054752",
  primaryLight500: "#006C6D",
  primaryLight100: "#1B937A",
  secondaryColor: "rgb(61, 72, 93)",
  lightBackgroundColor: "#e8ebeb",
  secondaryTextColor: "rgb(112, 140, 145)",
  primaryTextColor: "rgb(5, 70, 82)",
  lightYellowColor: "#FFFFE0",
  linkColor: "#00b5ff",
  errorColor: "#ff0000d9",
  backgroundBoxColor: "#054752d1",
  backgroundGradient: `linear-gradient( 90deg, rgb(7 30 28) 0%, rgba(5,71,82,1) 60%, rgb(23 104 120) 100% )`,
  gray: "gray",
};
