import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CoTraveller from "../../components/CoTraveller";
import DriverDetail from "../../components/Driverdetails";
import { animated, useSpring } from "react-spring";
import ConfirmModal from "../../components/models/ConfirmModal";
import { toast } from "react-toastify";
import api from "../../services/api";
import { getFormattedTime, renderStatusText } from "../../Utils";
import { COLORS } from "../../assets/colors";
import TripTimeline from "../../components/UI/TripTimeline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexFlow: 1,
    justifyContent: "center",
    minHeight: "60vh",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  timelineStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainText: {
    fontSize: "42px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  TimelineConnectorStyles: {
    width: "5px",
    height: "60px",
    backgroundColor: "rgb(5, 71, 82)",
  },

  dividerStyles: {
    maxWidth: "600px",
    height: "8px",
  },
  dividerBoxStyles: {
    display: "flex",
    justifyContent: "center",
    minHeight: "4vh",
    border: "1px solid green",
  },
  PriceBoxStyles: {
    minHeight: "8vh",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fareText: {
    color: "rgb(5, 71, 82)",
    fontWeight: 900,
    lineHeight: 1.06,
    fontSize: "1.5rem",
    margin: "10px 0",
    display: "inline-block",
  },
  passText: {
    color: COLORS.secondaryTextColor,
    fontSize: "18px",
  },
}));
export default function Trip() {
  const classes = useStyles();
  const { rideId } = useParams();
  const [currentRide, setCurrentRide] = useState({});
  const [open, setOpen] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const navigate = useNavigate();

  const { currentUser, isAuthenticated } = useSelector((state) => {
    return state.auth;
  });
  const handleClickOpen = () => {
    if (!isAuthenticated) {
      toast.info("Please login to book your seat");
      navigate(`/login/?redirectUrl=ride/${rideId}`);
      return;
    }
    navigate("payment");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (rideId) {
      getRideById(rideId);
    }
  }, [rideId]);

  useEffect(() => {
    if (currentUser.id) {
      isBookingRequested(currentUser.id);
    }
  }, [currentUser]);

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  async function isBookingRequested(userId) {
    let requestedBy = userId;
    const response = await api.get(
      `rideRequests/status/${rideId}/${requestedBy}`
    );
    setBookingStatus(parseInt(response.data.data.status));
  }

  async function getRideById(rideId) {
    const response = await api.get(`ride/${rideId}`);
    setCurrentRide(response.data.data);
  }

  const onBookingRequest = async () => {
    try {
      const payload = {
        ride_id: parseInt(rideId),
        requested_by: currentUser?.id,
        requested_to: currentRide?.User?.id,
      };
      await api
        .post("rideRequests", payload)
        .then((response) => {
          if (response.status === 200) {
            handleClose();
            toast.success("Request sent successfully");
            navigate("/booking");
          }
        })
        .catch((error) => {
          handleClose();
          toast.error(error.response.data.msg);
        });
    } catch (err) {}
  };
  const onCancelBookingRequest = async () => {
    try {
      setCancelling(true);
      const payload = {
        requestedBy: currentUser?.id,
        requestedTo: currentRide?.User?.id,
      };
      await api
        .post(`rideRequests/cancel`, payload)
        .then((response) => {
          if (response.status === 200) {
            setCancelling(false);
            setOpenCancelModal(false);
            toast.success("Booking Request Cancelled");
            setBookingStatus(null);
            renderStatusText(bookingStatus);
          }
        })
        .catch((error) => {
          setCancelling(false);
          setOpenCancelModal(false);
          toast.error(error.response.data.msg);
        });
    } catch (err) {
      setCancelling(false);
      setOpenCancelModal(false);
    }
  };

  const isMe = currentRide?.User?.id === currentUser?.id;
  const isFull = currentRide?.total_seats === currentRide?.seats_booked;

  return (
    <animated.div style={fade}>
      <CssBaseline />
      <Container className={classes.root}>
        <Box maxWidth="sm" className={classes.timelineStyles}>
          <Box alignContent="center" textAlign="center">
            <Typography
              color="textPrimary"
              variant="h2"
              className={classes.mainText}
            >
              {moment(currentRide?.departure_date).format("MMMM Do, YYYY")}
            </Typography>
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={12}>
                <TripTimeline
                  fromTime={currentRide?.departure_time}
                  toTime={currentRide?.arrival_time}
                  headingTo={currentRide?.heading_to}
                  leavingFrom={currentRide?.leaving_from}
                  drop={currentRide?.drop_location}
                  pick={currentRide?.pickup_location}
                  startLat={currentRide?.start_lat}
                  startLng={currentRide?.start_lng}
                  endLat={currentRide?.end_lat}
                  endLng={currentRide?.end_lng}
                  rideId={currentRide?.id}
                />
              </Grid>
              <Grid item xs={12} sm={3}></Grid>
            </Grid>
          </Box>
        </Box>
        <Container maxWidth="sm">
          <Divider variant="middle" light className={classes.dividerStyles} />
        </Container>

        <Container className={classes.PriceBoxStyles} maxWidth="xs">
          <Typography variant="p" align="left" className={classes.passText}>
            Total price for 1 passanger
          </Typography>
          <Typography variant="h4" align="right" className={classes.fareText}>
            Rs.{currentRide?.ticket}
          </Typography>
        </Container>
        <Container maxWidth="sm">
          <Divider variant="middle" light className={classes.dividerStyles} />
        </Container>
        <DriverDetail
          driver={currentRide?.User}
          vehicle={currentRide?.Vehicle}
          smoking={currentRide?.smoking}
          autoApproval={currentRide?.automatic_approval}
        />
        <Container maxWidth="sm">
          <Divider variant="middle" light className={classes.dividerStyles} />
        </Container>
        {isAuthenticated && (
          <CoTraveller travellers={currentRide.RideRequests} />
        )}
        <Container maxWidth="sm">
          <Divider variant="middle" light className={classes.dividerStyles} />
        </Container>
        {!isMe && (
          <Container maxWidth="sm">
            <Box p={4} display="flex" justifyContent="space-between">
              <Box>
                {bookingStatus === 1 && (
                  <Link
                    style={{ textDecoration: "none" }}
                    onClick={() => setOpenCancelModal(true)}
                  >
                    <Typography style={{ color: "red" }} align="left">
                      {" "}
                      Cancel Booking Request?
                    </Typography>
                  </Link>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={bookingStatus !== null || isFull}
                  onClick={handleClickOpen}
                >
                  <Typography variant="body">
                    {isFull ? "FULL" : renderStatusText(bookingStatus)}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Container>
        )}
      </Container>
      {open && (
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          cancelBtnText={"Cancel"}
          onConfirm={onBookingRequest}
          confirmBtnText={"Send Request"}
          title={"Send booking request?"}
          content={
            "We will send a request to the rider to confirm your seat and you will be informed about your request status."
          }
        />
      )}
      {openCancelModal && (
        <ConfirmModal
          open={openCancelModal}
          setOpen={setOpenCancelModal}
          handleClose={() => setOpenCancelModal(false)}
          cancelBtnText={"Cancel"}
          onConfirm={onCancelBookingRequest}
          confirmBtnText={cancelling ? "cancelling..." : "Confirm"}
          title={"Cancel booking request?"}
          content={"Are you sure you want to cancel your booking request?"}
        />
      )}
    </animated.div>
  );
}
