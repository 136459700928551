import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid ${COLORS.primaryColor};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CircularLoader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

export default CircularLoader;
