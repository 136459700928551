import styled from "styled-components";
import React from "react";

const ImageCard = ({ src, alt }) => {
  return (
    <Wrapper>
      <div className="image-card">
        <img src={src} alt={alt} className="image" />
      </div>
    </Wrapper>
  );
};

export default ImageCard;
const Wrapper = styled.div`
  .image-card {
    position: relative;
    border-radius: 10px;
    width: 200px;

    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }

  .image-card:hover {
    transform: scale(1.05);
    // box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  .image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;
