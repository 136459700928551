import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import logo from "../../images/gosawariLogo.png";
import styled from "styled-components";
import CustomButton from "../UI/Button";
import api from "../../services/api";
import { COLORS } from "../../assets/colors";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function ChangePasswordForm() {
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      toast.error("Password should be minimum 8 characters");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      const response = await api.post("user/changePassword", {
        password,
        forget_pass_code: params.token,
      });
      if (response.status === 200) {
        toast.success("Changed password successfully");
        navigate("/login");
      }
    } catch (e) {
      setError(e.response.data.message);
    }
    setLoading(false);
  };
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <MainContainer>
      <LogoContainer>
        <Avatar src={logo} />
        <h2 style={{ color: "#3d485d" }}>Create new password</h2>
      </LogoContainer>

      <FormContainer>
        <form onSubmit={handleChangePassword}>
          <TextField
            id="filled-password-input"
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="filled-password-input"
            label="Confirm New Password"
            type="password"
            fullWidth
            required
            variant="outlined"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <ButtonContainer>
            <CustomButton
              label={loading ? "Please wait" : "Submit"}
              type={"submit"}
            />
          </ButtonContainer>
          {error && <Alert severity="error">{error}</Alert>}
        </form>
        <LinkContainer>
          <Link to="/register">
            Don't have account? <span>Register</span>
          </Link>
          <Link to="/login">
            Already have account? <span>Login</span>
          </Link>
        </LinkContainer>
      </FormContainer>
    </MainContainer>
  );
}

export default ChangePasswordForm;

const MainContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px #888888;
  margin-top: 2rem;
  padding: 1rem;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  p {
    color: ${COLORS.secondaryColor};
    font-size: small;
    text-align: left;
    padding: 0 2rem;
  }
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  > form {
    width: 100%;
    /* max-width: 400px; */
    padding: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    > .MuiTextField-root {
      text-align: center;
      color: white;
      margin-bottom: 5px;
    }
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  min-height: 50px;
  > a {
    text-decoration: none;
    color: grey;
    span {
      color: ${COLORS.linkColor};
    }
    :hover {
      text-decoration: underline;
    }
  }
`;
const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
  max-width: 250px;
  width: 100%;
`;
