import * as types from "../constants";

const initialState = {
    isLoading: false,
    success: false,
    error: "",
    data: [],
    count: 0,
};

const ridesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_RIDES_REQUEST:
            return {
                ...state, isLoading: true
            }
        case types.GET_ALL_RIDES_SUCCESS:
            return { ...state, isLoading: false, success: true, data: action.payload.data, count: action.payload.count }

        case types.GET_ALL_RIDES_FAIL:
            return { ...state, isLoading: false, success: false, success: false, error: action.payload }
        case types.DELETE_RIDE:
            return {...state, data: state.data.filter(item => item.id != action.payload.rideId)}

        default: return state;
    }

}
export default ridesReducer;