import React from "react";
import styled from "styled-components";

function Card({ title, content, descriptions, styles }) {
  return (
    <CardWrapper style={styles}>
      <p style={{ color: styles.color }}>{title}</p>
      <h2 style={{ color: styles.color }}>{content}</h2>
      <p style={{ color: styles.color }} className="description">
        {descriptions}
      </p>
    </CardWrapper>
  );
}

export default Card;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 15.5625rem;
  height: 8.6875rem;
  background-color: #ffffff;
  flex-shrink: 0;
  border-radius: 6px;
  border: 2px solid #eceef6;

  p {
    color: #343434;
    font-family: Product Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  .description {
    color: #949494;
    font-family: Product Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  h2 {
    color: #232323;
    font-family: Product Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
  }
`;
