import styled, { keyframes } from "styled-components";
import { COLORS } from "../../assets/colors";
import mixins from "../../mixins";
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 4rem;
  }
`;

export const Header = styled.header`
  height: 80px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

export const Sider = styled.aside`
  width: 200px;
  background-color: #f5f5f5;
  padding: 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const Content = styled.div`
  flex: 4;
  .container {
    max-width: 864px;
    margin: auto;
  }
  .headerText {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    background: ${COLORS.backgroundGradient};
    height: fit-content;
    gap: 1rem;
    @media (max-width: 546px) {
      height: auto;
    }
    h1 {
      color: #ffff;
      font-weight: 400;
      @media (max-width: 546px) {
        font-size: 24px;
      }
      @media (max-width: 466px) {
        font-size: larger;
        font-weight: bold;
      }
    }
    p {
      color: #ffff;
      margin-top: 8px;
    }
    .tabs {
      height: auto;
      width: fit-content;
      display: flex;
      overflow-x: auto;
      @media (max-width: 546px) {
        padding: 0.5rem;
        width: 350px;
      }
      .active {
        background: #054752;
        border: 2px solid;
      }
      div {
        height: 2rem;
        cursor: pointer;
        width: fit-content;
        padding: 1rem;
        background: #40808b;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        color: white;
        border-radius: 20px;
        margin: 5px;
        color: white;
        :hover {
          background: #054752;
        }
        @media (max-width: 546px) {
          padding: 0.5rem;
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  .pagination {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  .view-link {
    text-decoration: none;
    color: ${COLORS.primaryColor};
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const Card = styled.div`
  -webkit-transition: box-shadow 200ms ease-in 0s;
  background-color: #f7f7f9;
  width: 48%;
  margin-bottom: 20px;
  border-radius: 5px;
  -webkit-animation: jWuYRJ 0.5s ease-in-out;
  animation: jWuYRJ 0.5s ease-in-out;
  animation: ${fadeIn} 0.5s ease-in-out;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    .title-container {
      display: flex;
      align-items: center;
      width: 100%;
      background: white;
      background: #06292a;
      padding: 1rem;
      justify-content: center;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      gap: 1rem;
      h3 {
        color: white;
      }
      p {
        color: #f1e0e0;
        font-size: 0.8rem;
      }
      svg {
        fill: white;
      }
    }

    h3 {
      color: black;
      svg {
        margin-bottom: -3px;
        fill: black;
      }
    }
    .view-link {
      text-decoration: underline;
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    .link-container {
      width: 100%;
      flex-direction: column;
      height: 2.5rem;
      align-items: center;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: self-end;
      margin-top: 1rem;
      a {
        :hover {
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const CardDate = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${COLORS.secondaryColor};
  display: flex;
  flex-direction: column;
  span {
    color: ${COLORS.secondaryTextColor};
  }
`;

export const CardStatus = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;

  span {
    color: ${COLORS.secondaryTextColor};
    font-weight: normal;
  }
  color: ${({ isComplete }) => (isComplete ? "gray" : "inherit")};
`;

export const CardButton = styled.button`
  display: block;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #c52121bf;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: red;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.primaryColor};
  color: white;
  padding: 1rem;
  h4 {
    color: white;
    font-size: 1.5rem;
    border-bottom: 1px solid;
  }
  ul {
    @media (max-width: 600px) {
      display: flex;
    }
  }
`;

export const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  color: ${({ active }) => (active ? `${COLORS.primaryColor}` : "normal")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  border-bottom: ${({ active }) =>
    active ? `2px solid ${COLORS.primaryColor}` : "none"};
`;
