import { IconButton } from "@material-ui/core";
import { MoreVertRounded } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PiUserCircle } from "react-icons/pi";
import api from "../../../services/api";
import { toast } from "react-toastify";

const tableHeaders = [
  "ID",
  "avatar",
  "First name",
  "Last name",
  "E-mail",
  "Phone",
  "Dob",
  "Gender",
  "Banned by",
  "Reason",
  "Action",
];

const TableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  .isActive {
    border: 1px solid #c3c5c9;
  }
  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  font-size: 14px;
  color: grey;
  cursor: pointer;
`;
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;

const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const BannedUserTable = ({ customers, fetchBannedUsers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const ddref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (event) => {
    if (ddref.current && !ddref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = (item) => {
    setIsOpen(!isOpen);
    setActiveItem(item);
  };

  const onUnBanUser = async (userId) => {
    const response = await api.delete(`/admin/user/unban/${userId}`);
    if (response.status === 200) {
      toast.success(response.data.message);
      fetchBannedUsers();
    }
    setIsOpen(false);
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {customers?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={row.id == activeItem?.id && isOpen ? "isActive" : null}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <img
                  src={
                    row.bannedUser.avatar_url ||
                    `https://ui-avatars.com/api/?name=${row?.bannedUser.first_name}`
                  }
                  height={"30px"}
                  width={"30px"}
                />
              </TableCell>
              <TableCell>{row.bannedUser.first_name}</TableCell>
              <TableCell>{row.bannedUser.last_name}</TableCell>
              <TableCell>{row.bannedUser.email}</TableCell>
              <TableCell>{row.bannedUser.mobile_number}</TableCell>
              <TableCell>
                {moment(row.bannedUser.dob).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell>{row.bannedUser.gender}</TableCell>
              <TableCell>{row.bannedBy.first_name}</TableCell>
              <TableCell>{row.reason}</TableCell>
              <TableCell>
                <IconButton onClick={() => toggleDropdown(row)}>
                  <MoreVertRounded />
                </IconButton>
              </TableCell>
              {activeItem?.id === row.id && (
                <DropdownWrapper ref={ddref}>
                  <DropdownMenu isOpen={isOpen}>
                    <MenuItem onClick={() => onUnBanUser(row.bannedUser.id)}>
                      <span>
                        {" "}
                        <PiUserCircle size={24} color="green" />
                      </span>{" "}
                      Unban {row.bannedUser.first_name}
                    </MenuItem>
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default BannedUserTable;
