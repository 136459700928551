import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import api from "../../../services/api"; // Make sure this is the correct path to your API service

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly Requests",
    },
  },
};

function BookingRequestsChart() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Requests",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 2,
        fill: false,
      },
    ],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get("/admin/dashboard/get-expired-requests");
        const data = response.data.data;

        const months = data.map((item) =>
          new Date(item.month).toLocaleString("default", { month: "long" })
        );
        const expiredCount = data.map((item) => item.expiredCount);
        const pendingCount = data.map((item) => item.pendindCount);
        const acceptedCount = data.map((item) => item.acceptedCount);

        setChartData({
          labels: months,
          datasets: [
            {
              label: "Accepted",
              data: acceptedCount,
              borderColor: "rgba(0, 204, 153, 1)",
              backgroundColor: "rgba(0, 204, 153, 1)",
              borderWidth: 2,
              fill: false,
            },
            {
              label: "Pending",
              data: pendingCount,
              borderColor: "rgba(153, 0, 204, 1)",
              backgroundColor: "rgba(153, 0, 204, 1)",
              borderWidth: 2,
              fill: false,
            },
            {
              label: "Expired",
              data: expiredCount,
              borderColor: "red",
              backgroundColor: "red",
              borderWidth: 2,
              fill: false,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching chart data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <div style={{ width: "100%" }}>
      {chartData.labels.length > 0 ? (
        <Bar options={options} data={chartData} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default BookingRequestsChart;
