import * as types from "../constants";
const initialState = {
  isLoading: false,
  success: false,
  error: "",
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: "",
      };

    case types.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    case types.LOGIN_CLEAN_UP:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
      };
    default:
      return state;
  }
};

export default loginReducer;
