import React from "react";
import notfound from "../../assets/images/notfound.svg";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <div className="image-container">
        <img src={notfound} alt="page not found" />
      </div>
      <h2>Ooops! The page you are looking for does not exist</h2>
      <button onClick={() => navigate("/")}>
        <FaArrowLeft />
        Go to Home
      </button>
    </StyledWrapper>
  );
};

export default NotFound;
const StyledWrapper = styled.div`
  height: 70vh;
  text-align: center;
  color: gray;
  button {
    background: ${COLORS.backgroundGradient};
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border: none;
    border-radius: 16px;
    margin-top: 1rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1pt 4pt, rgba(0, 0, 0, 0.08) 0px 2pt 8pt;
    :hover {
      background: ${COLORS.primaryColor};
    }
    svg {
      margin-right: 12px;
      margin-bottom: -3px;
    }
  }
  h2 {
    @media (max-width: 720px) {
      font-size: 18px;
    }
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    img {
      height: 300px;
      widht: auto;
    }
  }
`;
