// Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_CLEAN_UP = "LOGIN_CLEAN_UP";
//hero section


export const GET_ALL_CITIES_REQUEST='GET_ALL_CITIES_REQUEST';
export const GET_ALL_CITIES_SUCCESS='GET_ALL_CITIES_SUCCESS';
export const GET_ALL_CITIES_FAILED='GET_ALL_CITIES_FAILED';

//Ride Shareing
export const GET_ALL_RIDES_REQUEST= "GET_ALL_RIDES_REQUEST"; 
export const GET_ALL_RIDES_SUCCESS= "GET_ALL_RIDES_SUCCESS";
export const GET_ALL_RIDES_FAIL= "GET_ALL_RIDES_FAIL";
export const DELETE_RIDE="DELETE_RIDE";
// vehicle information
export const ADD_VEHICLE_REQUEST = "ADD_VEHICLE_REQUEST";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";
export const ADD_VEHICLE_FAILED = "ADD_VEHICLE_FAILED";
export const ADD_VEHICLE_CLEAN_UP = " ADD_VEHICLE_CLEAN_UP";
 
//public rides information
export const PUBLISH_RIDE_REQUEST = "PUBLISH_RIDE_REQUEST";
export const PUBLISH_RIDE_SUCCESS = "PUBLISH_RIDE_SUCCESS";
export const PUBLISH_RIDE_FAILED = "PUBLISH_RIDE_FAILED";
export const PUBLISH_RIDE_CLEAN_UP = "PUBLISH_RIDE_CLEAN_UP";
export const UPDATE_RIDE = "UPDATE_RIDE";

/// auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const SET_USER_AVATAR = "SET_USER_AVATAR";

//otp
export const OTP_REQUEST = "OTP_REQUEST";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILED = "OTP_FAILED";
export const OTP_CLEAN_UP = "OTP_CLEAN_UP";

///Register 
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_CLEAN_UP = "REGISTER_CLEAN_UP";
export const REGISTER_ERROR = "REGISTER_ERROR";

///PasswordReset
export const PASSWORDRESET_REQUEST = 'PASSWORDRESET_REQUEST'

// publish Ride info
export const PUBLISH_RIDE_INFO_REQUEST = "PUBLISH_RIDE_INFO_REQUEST";
export const PUBLISH_RIDE_INFO_SUCCESS = "PUBLISH_RIDE_INFO_SUCCESS";
export const PUBLISH_RIDE_INFO_FAILED = "PUBLISH_RIDE_INFO_FAILED";
export const UPDATE_CURRENT_RIDE_INFO= "UPDATE_CURRENT_RIDE_INFO";
export const ADD_NEW_VEHICLE_INFO ="ADD_NEW_VEHICLE_INFO"

// My Bookings
export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAILED = "GET_BOOKINGS_FAILED";
export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST";

// My Ride Requests
export const GET_MY_RIDE_REQUEST = "GET_RIDE_REQUEST";
export const GET_MY_RIDE_SUCCESS = "GET_RIDE_SUCCESS";
export const GET_MY_RIDE_FAILED = "GET_RIDE_FAILED";
export const ACCEPT_RIDE_REQUEST = "ACCEPT_RIDE_REQUEST";
export const REJECT_RIDE_REQUEST = "REJECT_RIDE_REQUEST";
