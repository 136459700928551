import React from "react";
import styled from "styled-components";
import GroupBtn from "../../UI/GroupBtn";
import TourCard from "../card";
import { useNavigate } from "react-router-dom";

const destinations = [
  "Muree",
  "Naran",
  "Kaghan",
  "Swat Valley",
  "Hunza Valley",
  "Skardu",
  "Neelum Valley",
  "Fairy Meadows",
  "Shogran",
  "Saif-ul-Muluk Lake",
  "Lulusar Lake",
  "Rawal Lake",
  "Khanpur Dam",
  "Daman-e-Koh",
  "Shakarparian",
  "Lake View Park",
  "Changa Manga",
];

const tours = [
  {
    title: "Hunza/Nagar/Naran",
    description:
      "Enjoy a relaxing vacation on the beautiful beaches of Hawaii.",
    price: 499,
    date: "2024-07-10",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    title: "Mountain Adventure",
    description: "Explore the majestic Rocky Mountains with a guided tour.",
    price: 699,
    date: "2024-08-15",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    title: "City Lights",
    description:
      "Discover the vibrant nightlife and cultural landmarks of New York City.",
    price: 299,
    date: "2024-09-20",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    title: "Hunza/Nagar/Naran",
    description:
      "Enjoy a relaxing vacation on the beautiful beaches of Hawaii.",
    price: 499,
    date: "2024-07-10",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    title: "Mountain Adventure",
    description: "Explore the majestic Rocky Mountains with a guided tour.",
    price: 699,
    date: "2024-08-15",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  {
    title: "City Lights",
    description:
      "Discover the vibrant nightlife and cultural landmarks of New York City.",
    price: 299,
    date: "2024-09-20",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVREWY-ZU9Mg5VZwjeptOsZASP6zeJMfl6Mw&s",
  },
  // Add more tour objects as needed
];

function Tours() {
  const navigate = useNavigate();

  const handleCreateGroup = () => {
    navigate("/group-tours/create");
  };

  return (
    <Wrapper>
      <HeaderSection>
        <Overlay />
        <HeaderText>
          <h3>
            <span className="find">Find or create</span> <br />
            <span> Group Tours </span> with GoSawari
          </h3>
          <GroupBtn onClick={handleCreateGroup} />
        </HeaderText>
      </HeaderSection>

      <TopDestinations>
        {destinations.map((destination, index) => (
          <div key={index} className="destination">
            {destination}
          </div>
        ))}
      </TopDestinations>

      <ContentContainer>
        <FilterSection />
        <CenterContent>
          {tours.map((tour, index) => (
            <TourCard key={index} tour={tour} />
          ))}
        </CenterContent>
      </ContentContainer>
    </Wrapper>
  );
}

export default Tours;

const Wrapper = styled.div`
  width: 100%;
`;

const HeaderSection = styled.div`
  position: relative;
  background-color: #054752;
  color: white;
  height: 50vh;
  padding-left: 4rem;
  display: flex;
  justify-content: flex-left;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HeaderText = styled.div`
  z-index: 2;
  // text-align: center;
  max-width: 800px;

  h3 {
    line-height: 1;
    font-size: 3.5rem;
    margin-bottom: 1rem;

    .find {
      font-size: 2rem;
      color: aquamarine;
    }
  }
`;

const TopDestinations = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .destination {
    background-color: teal;
    border-radius: 5px;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 0 1rem;
`;

const FilterSection = styled.div`
  width: 20%;
`;

const CenterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 1260px;
  width: 100%;
  padding: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
