import { Input, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "1rem",
    "& .MuiSvgIcon-root": {
      marginBlock: "-24px",
    },
  },
  BoxStyles: {
    borderRadius: "16px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    minHeight: "48px",
    marginTop: "80px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",

      padding: "0 10px 10px 10px",
    },
  },
  searchBox: {
    height: "100%",
    backgroundColor: "#054752;",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
      padding: "0 10px 0 10px",
    },
  },

  selectFocused: {
    backgroundColor: "white",
  },
  datepicker: {
    marginTop: "1rem",
    border: "none",
    height: '2rem',
    width: '100%'
  },
}));

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  animation: modal 0.3s forwards;
  width: 400px;
  margin: 1rem;


  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const ModalButton = styled.button`
  background-color: ${(props) => props.disabled ? COLORS.secondaryColor : COLORS.primaryColor};
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => props.disabled ? null : 'pointer'};
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: ${(props) => props.disabled ? COLORS.secondaryColor : '#063d49'};
  }
`;

const ModalCancelButton = styled.button`
  background-color: #ddd;
  color: #333;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #c3c3c3;
  }
`;

const Modal = ({ isOpen, setIsOpen, handleUpdateAboutMe }) => {
  const [aboutMe, setAboutMe] = React.useState(null);
  const classes = useStyles();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleEditAboutMe = (event) => {
    setAboutMe(event.target.value);
  };


  const aboutMeOps = [
    "I'am chatty when I am comfortable",
    "I am an Introvert-(Less Talkative)",
    "I am an Extrovert-(Talkative)",
  ];
  return (
    <>
      {isOpen && (
        <ModalWrapper onClick={handleCloseModal}>
          <ModalContent onClick={(event) => event.stopPropagation()}>
            <ModalTitle>What's true about you?</ModalTitle>
            <ModalForm>
              <InputLabel shrink="false" htmlFor="name-multiple"> I'am...</InputLabel>
              <Select
                fullWidth
                value={aboutMe}
                onChange={handleEditAboutMe}
                className={classes.selectStyles}
                classes={{ focused: classes.inputFocused }}
                disableUnderline
                variant="outlined"
              >

                {
                  aboutMeOps.map((opt, index) => {
                    return (
                      <MenuItem key={opt} value={opt}>
                        {opt}
                      </MenuItem>
                    );
                  })
                }
              </Select>
              <ModalButtonContainer>
                <ModalButton disabled={aboutMe === null} onClick={(e) => {
                  e.preventDefault();
                  handleUpdateAboutMe(aboutMe)
                }}>Save</ModalButton>
                <ModalCancelButton onClick={() => setIsOpen(false)}>
                  Cancel
                </ModalCancelButton>
              </ModalButtonContainer>
            </ModalForm>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};
export default Modal;
