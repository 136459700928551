import React from "react";
import { Pie } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { Chart as ChartJS, Title, Legend, Tooltip, ArcElement } from "chart.js";

ChartJS.register(Title, Legend, Tooltip, ArcElement);

const labels = [
  "Users",
  "Bookings",
  "Rides",
  "Drivers",
  "Passengers",
  "Vehicles",
  "Earnings",
  "Refunds",
  "Reports",
  "Banned Users",
];

const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: [
        "rgb(78, 199, 78)",
        "#054752",
        "#bb86fc",
        "#3498db",
        "rgba(255, 99, 132)",
        "rgba(53, 162, 235)",
        "green",
        "rgba(53, 162, 235)",
        "#f1c40f",
        "red",
      ],
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "GoSawari Statistics",
    },
  },
};

function PieChart() {
  return <Pie options={options} data={data} />;
}

export default PieChart;
