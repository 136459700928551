import {
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useRef } from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { FaPlusCircle } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "1rem",
    "& .MuiSvgIcon-root": {
      marginBlock: "-24px",
    },
  },
  BoxStyles: {
    borderRadius: "16px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    minHeight: "48px",
    marginTop: "80px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",

      padding: "0 10px 10px 10px",
    },
  },
  searchBox: {
    height: "100%",
    backgroundColor: "#054752;",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
      padding: "0 10px 0 10px",
    },
  },

  selectFocused: {
    backgroundColor: "white",
  },
  datepicker: {
    marginTop: "1rem",
    border: "none",
    height: "2rem",
    width: "100%",
  },
}));

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  animation: modal 0.3s forwards;
  width: 400px;
  margin: 1rem;

  .cnic {
    height: 150px;
    width: 100%;
    border: 2px dashed ${COLORS.borderColor};
    margin: 5px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 26px;
      color: darkslategray;
    }
    img {
      position: absolute;
    }
  }

  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const ModalButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? COLORS.secondaryColor : COLORS.primaryColor};
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? null : "pointer")};
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: ${(props) =>
      props.disabled ? COLORS.secondaryColor : "#063d49"};
  }
`;

const ModalCancelButton = styled.button`
  background-color: #ddd;
  color: #333;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #c3c3c3;
  }
`;

const CnicModal = ({
  isOpen,
  setIsOpen,
  onCnicUpload,
  handleCnicChange,
  cnicFront,
  cnicBack,
}) => {
  const frontRef = useRef(null);
  const backRef = useRef(null);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper onClick={handleCloseModal}>
          <ModalContent onClick={(event) => event.stopPropagation()}>
            <ModalTitle>Upload CNIC</ModalTitle>
            <p>CNIC front</p>

            <div className="cnic">
              {cnicFront && <img src={cnicFront} height="130px" width="auto" />}

              <IconButton
                style={{ background: "white" }}
                onClick={() => frontRef.current.click()}
              >
                <FaPlusCircle />
              </IconButton>
              <p style={{ color: "gainsboro" }}>CNIC front</p>
              <input
                style={{ display: "none" }}
                ref={frontRef}
                type="file"
                onChange={(e) => handleCnicChange(e, "front")}
              />
            </div>
            <p>CNIC back</p>
            <div className="cnic">
              {cnicBack && <img src={cnicBack} height="130px" width="auto" />}
              <IconButton
                style={{ background: "white" }}
                onClick={() => backRef.current.click()}
                size="medium"
              >
                <FaPlusCircle />
              </IconButton>
              <p style={{ color: "gainsboro" }}>CNIC back</p>
              <input
                style={{ display: "none" }}
                ref={backRef}
                type="file"
                onChange={(e) => handleCnicChange(e, "back")}
              />
            </div>
            <ModalForm>
              <ModalButtonContainer>
                <ModalButton
                  disabled={cnicBack === null || cnicFront === null}
                  onClick={() => setIsOpen(false)}
                >
                  Save
                </ModalButton>
                <ModalCancelButton onClick={() => setIsOpen(false)}>
                  Cancel
                </ModalCancelButton>
              </ModalButtonContainer>
            </ModalForm>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};
export default CnicModal;
