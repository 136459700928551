import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";

const CancellationPolicy = () => {
  return (
    <div>
      <StyledCancellation>
        <h3>Cancellation and Refund Policy</h3>
        <p>
          Passanger will pay ride fare in cash to the driver during the ride
          whereas the platform service charges are paid online. GoSawari charges
          only the service charges which can be refunded based on the
          cancellation and refunding policy
        </p>
        <div className="item">
          <h4>If the passanger cancels:</h4>
          <ul>
            <li>
              Full refund on cancellation within <strong>30 minutes </strong>of
              booking excluding service fee of 3% of the fare.
            </li>
            <li>
              Full refund excluding the service fee on cancellation more than{" "}
              <strong>24 hours</strong>&nbsp;before the departure time. Service
              fee is <strong>3% </strong> of the fare.
            </li>
            <li>
              Half refund excluding the service fee on cancellation less than{" "}
              <strong>24 hours</strong>&nbsp;before the departure time. Service
              fee is <strong>3% </strong> of the fare.
            </li>
            <li>
              If the passanger does not show up on time and misses the ride then
              there will be <strong>no refunding</strong>, so the passanger
              should reach on time.
            </li>
          </ul>
        </div>
        <div className="item">
          <h4>If the driver cancels:</h4>
          <ul>
            <li>100% refund to the passengers</li>
          </ul>{" "}
        </div>
        <div className="item">
          <h4>Booking request expires:</h4>
          <p>
            If the driver does not accept or decline the booking request before
            the expiration time then the passenger will get refunded.
          </p>
          <ul>
            <li>100% refund to the passengers</li>
            <li>
              Drivers ranking can be be affected on frequent cancellation and
              un-reponsiveness.
            </li>
          </ul>{" "}
        </div>
      </StyledCancellation>
    </div>
  );
};

export default CancellationPolicy;

const StyledCancellation = styled.div`
  padding: 1rem;
  border-radius: 4px;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  // margin-bottom: 6rem;

  h3 {
    color: ${COLORS.primaryColor};
  }
  p {
    color: darkslategray;
  }
  .item {
    h4 {
      color: ${COLORS.secondaryColor};
      margin-bottom: 0.25rem;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-left: 2rem;
      li {
        color: darkslategray;
      }
    }
  }
`;
