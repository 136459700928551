import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { useState } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const choices = [
  {
    id: 1,
    label: "A",
    value: "Change of plans",
    slug: "change_of_plans",
  },
  {
    id: 2,
    label: "B",
    value: "Cost concerns",
    slug: "cost_concerns",
  },
  {
    id: 3,
    label: "C",
    value: "Vehicle concerns",
    slug: "vehicle_concers",
  },
  {
    id: 4,
    label: "D",
    value: "Security concerns",
    slug: "security_issues",
  },
  {
    id: 5,
    label: "E",
    value: "Others",
    slug: "others",
  },
];

export default function ConfirmModal({
  open,
  handleClose,
  title,
  content,
  cancelBtnText,
  confirmBtnText,
  onConfirm,
  type,
  cancelBookingModal = false,
  loading = false,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const handleSelect = (id, slug) => {
    setSelectedId(id);
    setSelectedSlug(slug);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary">{title || "Confirm"}</DialogTitle>
        <DialogContent color="gray">
          <p style={{ color: "gray" }}> {content}</p>
          <DialogContentText id="alert-dialog-slide-description">
            {cancelBookingModal && (
              <Box display="flex" mt={2} flexDirection={"column"} gridGap={4}>
                {choices.map((item) => (
                  <Choice
                    id={item.id}
                    handleSelect={handleSelect}
                    selected={item.id == selectedId}
                    label={item.label}
                    value={item.value}
                    slug={item.slug}
                  />
                ))}
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelBtnText}</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onConfirm(selectedSlug)}
          >
            {loading ? "processing.." : confirmBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Choice = ({
  id = 1,
  label = "A",
  value = "Cost Concerns",
  selected = false,
  handleSelect = null,
  slug = "",
}) => {
  return (
    <ChoiceContainer
      onClick={() => handleSelect(id, slug)}
      selected={selected}
      className="flex group relative rounded-md cursor-pointer border-gray-100 border-2 p-4 transition-colors"
    >
      <div className="flex items-center h-5 pr-4">
        <ChoiceInput />
        <ChoiceOption selected={selected}>{label}</ChoiceOption>
      </div>
      <ChoiceLabel>
        <ChoiceValue selected={selected}>{value}</ChoiceValue>
      </ChoiceLabel>
    </ChoiceContainer>
  );
};

const ChoiceContainer = styled.li`
  display: flex;
  position: relative;
  border-radius: 0.375rem;
  gap: 0.5rem;
  cursor: pointer;
  border: 2px solid ${(props) => (props.selected ? "#054752" : "#cbd5e0")};
  padding: 0.5rem;
  transition: border-color 0.2s;

  &:hover {
    border-color: #054752;
  }
`;

const ChoiceInput = styled.input.attrs({ type: "radio", name: "surveyChoice" })`
  display: none;
`;

const ChoiceLabel = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  pointer-events: none;
  margin-left: 0;
`;

const ChoiceOption = styled.span`
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.75rem;
  background-color: ${(props) => (props.selected ? "#054752" : "#edf2f7")};
  color: ${(props) => (props.selected ? "#ffffff" : "#4a5568")};
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    opacity: 0.75;
  }
`;

const ChoiceValue = styled.span`
  display: block;
  font-weight: medium;
  color: ${(props) => (props.selected ? "#054752" : "#4a5568")};
  font-size: 1rem;
`;
