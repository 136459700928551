import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Avatar, Divider } from "@material-ui/core";
import logo from "../../images/gosawariLogo.png";

import { AirlineSeatFlat, Settings } from "@material-ui/icons";
import AttachMoneySharpIcon from "@material-ui/icons/AttachMoneySharp";
import { COLORS } from "../../assets/colors";
import {
  FaBell,
  FaCar,
  FaDashcube,
  FaEnvelope,
  FaFire,
  FaHome,
  FaPlusCircle,
  FaSignInAlt,
  FaSignOutAlt,
  FaUser,
  FaUserPlus,
} from "react-icons/fa";
import { LOGOUT_CURRENT_USER } from "../../redux/constants";
import { useDispatch } from "react-redux";
import { socket } from "../../socket";
import MyRefunds from "../../pages/MyRefunds";

const Drawer = ({ isAdmin, isOpen, onClose, currentUser, isAuthenticated }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest(".drawer")) {
        onClose();
      }
    };

    if (isOpen) {
      document.body.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const logOut = () => {
    socket.emit("logout", currentUser.id);

    dispatch({
      type: LOGOUT_CURRENT_USER,
    });
    localStorage.removeItem("authToken");
    // window.location.replace("/");
  };

  return (
    <>
      {isOpen && <Backdrop />}
      <DrawerWrapper className={isOpen ? "open" : ""}>
        <DrawerContent>
          <div className="draweritems">
            {isAuthenticated && (
              <div className="auth-top">
                <Avatar
                  className="Avatar"
                  style={avatarStyle}
                  src={currentUser?.avatar_url}
                  alt={"logo"}
                />
                <span className="user-info">
                  <span className="user-name">
                    {currentUser?.first_name} {currentUser?.last_name}
                  </span>
                  <span className="user-email">{currentUser?.email}</span>
                </span>
              </div>
            )}

            {isAdmin && isAuthenticated ? (
              <NavLink to={"admin"} activeClassName="active" exact>
                <FaDashcube className="icon" size={24} />
                <h3>Dashboard </h3>
              </NavLink>
            ) : (
              <>
                {isAuthenticated ? (
                  <>
                    <NavLink
                      to={`profile/${currentUser?.id}`}
                      activeClassName="active"
                      exact
                    >
                      <FaUser className="icon" size={22} />
                      <h3>Profile </h3>
                    </NavLink>
                    <NavLink to={"carpool"} activeClassName="active" exact>
                      <FaFire color="#e25822" className="icon" size={22} />
                      <h3>Carpool </h3>
                    </NavLink>
                    <NavLink to={"inbox"} activeClassName="active" exact>
                      <FaEnvelope
                        color="steelblue"
                        className="icon"
                        size={22}
                      />
                      <h3>Inbox</h3>
                    </NavLink>

                    <NavLink
                      to={"ride-requests"}
                      activeClassName="active"
                      exact
                    >
                      <FaUserPlus className="icon" size={22} />

                      <h3>Requests </h3>
                    </NavLink>
                    <NavLink to={"my-bookings"} activeClassName="active" exact>
                      <AirlineSeatFlat
                        color="orange"
                        className="icon"
                        size={22}
                      />

                      <h3>Bookings </h3>
                    </NavLink>
                    <NavLink to={"your-rides"} activeClassName="active" exact>
                      <FaCar className="icon" size={22} />

                      <h3>Rides</h3>
                    </NavLink>
                    <NavLink to={"publish"} activeClassName="active" exact>
                      <FaPlusCircle className="icon" size={22} />
                      <h3>Create a Ride</h3>
                    </NavLink>

                    <NavLink
                      to={"notifications"}
                      activeClassName="active"
                      exact
                    >
                      <FaBell color="goldenrod" className="icon" size={22} />
                      <h3>Notifications</h3>
                    </NavLink>

                    <NavLink to={"/my-refunds"} activeClassName="active" exact>
                      <AttachMoneySharpIcon
                        color="darkslategray"
                        className="icon"
                        size={22}
                      />
                      <h3>Refund</h3>
                    </NavLink>

                    <NavLink to={"settings"} activeClassName="active" exact>
                      <Settings
                        color="darkslategray"
                        className="icon"
                        size={22}
                      />
                      <h3>Settings</h3>
                    </NavLink>
                  </>
                ) : (
                  <UnAuthWrapper>
                    {!isAuthenticated && (
                      <div className="top">
                        <img className="profile-img" src={logo} />
                        <span className="info"></span>
                      </div>
                      // top1//////////////
                    )}
                    <NavLink to={"carpool"} activeClassName="active" exact>
                      <FaFire className="icon" size={22} />
                      <h3>Trending</h3>
                    </NavLink>
                    <NavLink to={"/"} activeClassName="active" exact>
                      <FaHome className="icon" size={22} />
                      <h3>Home</h3>
                    </NavLink>
                    <NavLink to={"register"} activeClassName="active" exact>
                      <FaUser className="icon" size={22} />
                      <h3>Register</h3>
                    </NavLink>
                    <NavLink to={"login"} activeClassName="active" exact>
                      <FaSignInAlt className="icon" size={22} />
                      <h3>Login</h3>
                    </NavLink>
                  </UnAuthWrapper>
                )}
              </>
            )}
          </div>
          <Divider style={{ height: "2px", width: "100%" }} />
          {isAuthenticated && (
            <LogoutStyled onClick={() => logOut()}>
              <FaSignOutAlt className="icon" size={22} />
              <h3>Logout</h3>
            </LogoutStyled>
          )}
        </DrawerContent>
      </DrawerWrapper>
    </>
  );
};

export default Drawer;
const LogoutStyled = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  color: gray;
  background: #054752;
  margin: 1rem;
  color: white;
  border-radius: 4px;
  font-weight: 400;
  justified-content: center;
  margin-top: 3rem;
`;
const Backdrop = styled.div`
  position: fixed;
  z-index: 10;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DrawerWrapper = styled.div`
  position: fixed;
  top: 73px;
  left: 0;
  width: 18rem;
  background-color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  height: 100%;
  &.open {
    transform: translateX(0);
  }
  @media (max-width: 546px) {
    top: 0;
  }
`;
const UnAuthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  a {
    padding: 1rem !important;
  }
`;
const DrawerContent = styled.div`
  .draweritems {
    .auth-top {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      background-color: #054752;
      .user-info {
        gap: 7px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        .user-email {
          font-size: 15px;
          color: whiteSmoke;
          width: fit-content;
        }
        .user-name {
          text-transform: capitalize;
          font-size: 1.3rem;
          color: white;
          width: fit-content;
        }
      }
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    .top {
      height: auto;
      width: 100%;
      border-bottom: 1px solid ${COLORS.borderColor};
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: flex-start;
      align-items: start;
      padding: 1rem;
      background: linear-gradient(
        90deg,
        rgb(7 30 28) 0%,
        rgba(5, 71, 82, 1) 60%,
        rgb(23 104 120) 100%
      );
      color: white;

      .profile-img {
        height: auto;
        width: 8rem;
      }
      .info {
        display: flex;
        flex-direction: column;
        span:first-child {
          font-weight: bold;
          text-transform: capitalize;
          color: red;
        }
      }
    }
    a {
      text-decoration: none;
      color: #5c5a5a;
      display: flex;
      gap: 1rem;
      padding: 0.5rem;
      width: 100%;
      align-items: center;
      svg {
        margin-right: 0.25rem;
      }
    }

    h3 {
      font-weight: 400;
    }
    .Avatar {
      height: 1rem;
      width: 2rem;
    }
  }
`;

const avatarStyle = {
  width: "4rem",
  height: "auto",
};
