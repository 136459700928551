import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useSelector((state) => state.auth);

  const token = localStorage.getItem("authToken");
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  if (currentUser?.isAdmin) {
    return <Navigate to="/un-authorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
