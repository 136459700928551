/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { GlobalContext } from "../../../contexts/globalContext";
import { TripOrigin } from "@material-ui/icons";
import styled from "styled-components";

const styles = makeStyles((theme) => ({
  inputStyles: {
    fontSize: "1.2rem",
    border: "none",
    "& label": {
      [theme.breakpoints.down("sm")]: {
        color: "gray",
        paddingLeft: "1rem",
      },
    },
    "& fieldset": {
      border: "none",
      [theme.breakpoints.down("sm")]: {},
    },
  },
}));

export default function AutoComplete({
  data,
  label,
  handleOnChange,
  value,
  page = null,
}) {
  const classes = styles();
  return (
    <Autocomplete
      id="auto-complete"
      options={data}
      getOptionLabel={(option) => option.name}
      fullWidth
      onChange={(event, va) => handleOnChange(va, event)}
      value={value}
      renderInput={(params) => {
        return (
          <StyledInput className="searchBox">
            <TripOrigin color="primary" />
            <TextField
              size="small"
              {...params}
              label={label}
              className={page ? classes.inputStyles : null}
              variant={window?.innerWidth < 746 ? "outlined" : "outlined"}
            />
          </StyledInput>
        );
      }}
    />
  );
}

const StyledInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
