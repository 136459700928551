import React, { useState } from "react";
import styled from "styled-components";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import CustomButton from "../UI/Button";
import CancelButton from "../UI/CancelButton";
import InputField from "../UI/InputField";
import useInput from "../../hooks/useInput";
import { toast } from "react-toastify";
import api from "../../services/api";

function SecurityForm({ user }) {
  const [passwordError, setPasswordError] = useState("");
  const password = useInput("");
  const confirmPassword = useInput("");
  const mobileNumber = useInput("");

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    try {
      if (hasErrorInPassword()) {
        return;
      } else {
        const response = await api.patch(`user/updatePassword/${user.id}`, {
          password: password.value,
        });
        if (response.status === 200) {
          toast.success("Password updated successfully");
        }
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const hasErrorInPassword = () => {
    let hasError = false;
    if (password.value !== confirmPassword.value) {
      toast.error("passwords do not match");
      hasError = true;
    }
    if (password.value.length < 8) {
      toast.error("password length should be minimum 8 characters");
      hasError = true;
    }
    return hasError;
  };
  return (
    <MainContainer>
      <Typography variant="h6" color="textPrimary">
        Change Password
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        Use a strong password by using special characters, numbers, uppercase{" "}
        <br />
        and lowercase letters
      </Typography>
      <FormContainer>
        <form onSubmit={handleSubmitPassword}>
          <InputField
            label="Enter new Password"
            type="password"
            fullWidth={true}
            variant="outlined"
            required={true}
            maxLength={20}
            minLenght={8}
            {...password}
          />

          <InputField
            label="Confirm new Password"
            type="password"
            fullWidth={true}
            variant="outlined"
            required={true}
            maxLength={20}
            minLenght={8}
            {...confirmPassword}
          />
          <BtnContainer>
            <CustomButton label="save" type="submit" />
          </BtnContainer>
        </form>
      </FormContainer>
    </MainContainer>
  );
}

export default SecurityForm;
const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  padding-top: 20px;

  > form > .MuiTextField-root {
    margin: 10px 0px;

  }
  ,
  
`;

const BtnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: end;
  > div:nth-child(1) {
    margin-right: 21px;
  }
  @media (max-width: 546px) {
    margin-bottom: 6rem;
  }
`;
