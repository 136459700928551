/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ConfirmModal from "../../components/models/ConfirmModal";
import NoData from "../../components/NoData";
import { getMyRideRequests } from "../../redux/actions";
import {
  ACCEPT_RIDE_REQUEST,
  REJECT_RIDE_REQUEST,
} from "../../redux/constants";
import api from "../../services/api";
import { capitalizeFirstLetter, renderStatusText } from "../../Utils";
import { Container, TableWrapper } from "./styles";
import rideRequestImg from "../../assets/images/add.svg";
import HorizontalCard from "../../components/HorizontalCard";
import moment from "moment";
import { BOOKING_REQUEST_STATUS } from "../../Utils";
import { Pagination } from "@material-ui/lab";

const RideRequests = () => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [currentRideRequestId, setCurrentRideId] = useState({});
  const [activeTab, setActiveTab] = useState("all");
  const [pageSize, setPageSize] = useState(4);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const limit = 4;
  const offset = (page - 1) * pageSize;
  const dispatch = useDispatch();

  const { isLoading, data, count } = useSelector(
    (state) => state.myRideRequests
  );
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      dispatch(getMyRideRequests(activeTab, limit, offset));
    }
  }, [currentUser, activeTab, page]);
  useEffect(() => {
    if (count > 0) {
      setPages(Math.ceil(count / pageSize));
    }
  }, [count]);

  const onAcceptBooking = async () => {
    const payload = {
      id: currentRideRequestId,
    };
    try {
      setLoading(false);
      await api
        .post("rideRequests/accept", payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ACCEPT_RIDE_REQUEST,
              payload: {
                id: currentRideRequestId,
              },
            });
            setLoading(false);
            setShowAcceptModal(false);
            toast.success("Ride Request Accepted");
          }
        })
        .catch((error) => {
          setLoading(false);
          setShowAcceptModal(false);
          toast.error(error.response.data.msg);
        });
    } catch (err) {}
  };
  const onRejectBooking = async () => {
    const payload = {
      id: currentRideRequestId,
    };
    try {
      setRejecting(true);
      await api
        .post("rideRequests/reject", payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: REJECT_RIDE_REQUEST,
              payload: {
                id: currentRideRequestId,
              },
            });
            setRejecting(false);

            setShowRejectModal(false);
            toast.success("Ride Request Rejected");
          }
        })
        .catch((error) => {
          setRejecting(false);

          setShowRejectModal(false);
          toast.error(error.response.data.msg);
        });
    } catch (err) {
      setShowRejectModal(false);
    }
  };
  const handleActionClick = (rideReqId, type) => {
    try {
      setCurrentRideId(rideReqId);
      if (type == "reject") {
        setShowRejectModal(true);
      } else if (type == "accept") {
        setShowAcceptModal(true);
      }
    } catch (e) {
      setShowAcceptModal(false);
      setShowRejectModal(false);
    }
  };
  const renderBookingHeader = (bookingStatus) => {
    if (!bookingStatus) {
      return "All";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.PENDING) {
      return "Pending";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.ACCEPTED) {
      return "Accepted";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.REJECTED) {
      return "Rejected";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.CANCELLED) {
      return "Canceled";
    } else {
      return "All";
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleTabs = (activeTab) => {
    setPage(1);
    setActiveTab(activeTab);
  };

  return (
    <Container>
      <div className="header">
        <h1>Requests to travel with you</h1>
        <div className="tabs">
          <div
            className={activeTab == "all" && "active"}
            onClick={() => handleTabs("all")}
          >
            All
          </div>
          <div
            className={activeTab == BOOKING_REQUEST_STATUS.PENDING && "active"}
            onClick={() => handleTabs(BOOKING_REQUEST_STATUS.PENDING)}
          >
            Pending
          </div>
          <div
            className={activeTab == BOOKING_REQUEST_STATUS.ACCEPTED && "active"}
            onClick={() => handleTabs(BOOKING_REQUEST_STATUS.ACCEPTED)}
          >
            Accepted
          </div>
          <div
            className={
              activeTab == BOOKING_REQUEST_STATUS.CANCELLED && "active"
            }
            onClick={() => handleTabs(BOOKING_REQUEST_STATUS.CANCELLED)}
          >
            Cancelled
          </div>
        </div>
      </div>

      {!isLoading && data.length === 0 && activeTab !== "all" && (
        <div className="no-data">
          <NoData
            showImage={true}
            button={false}
            message={"No records found"}
          />
        </div>
      )}
      {!isLoading && data.length === 0 && activeTab == "all" ? (
        <NoData
          imgSrc={rideRequestImg}
          showImage={true}
          button={true}
          buttonLink="/publish"
          buttonText={"Offer Ride"}
          message={
            "Create a ride and start receiving ride requests. Let's make this your least expensive journey ever."
          }
          title={"Are you Travelling in your car soon?"}
        />
      ) : (
        <TableWrapper>
          {!isLoading && data?.length > 0 && (
            <h1 style={{ margin: "1rem", fontSize: "x-large" }}>
              {renderBookingHeader(activeTab)} Requests
            </h1>
          )}
          {!isLoading &&
            data?.length > 0 &&
            data.map((rideRequest) => (
              <HorizontalCard
                avatarSrc={
                  rideRequest.requestedBy.avatar_url
                    ? rideRequest.requestedBy.avatar_url
                    : `https://ui-avatars.com/api/?name=${rideRequest.requestedBy.first_name}`
                }
                name={capitalizeFirstLetter(rideRequest.requestedBy.first_name)}
                from={rideRequest.Ride.leaving_from}
                to={rideRequest.Ride.heading_to}
                date={moment(rideRequest?.Ride?.departure_date).format(
                  "MMM Do, YYYY"
                )}
                rideId={rideRequest.ride_id}
                status={renderStatusText(
                  parseInt(rideRequest.status),
                  rideRequest.requestedBy?.first_name
                )}
                onActionClick={handleActionClick}
                statusType={rideRequest.status}
                requestId={rideRequest.id}
                seatsRequired={rideRequest.seats}
                expiresAt={rideRequest.expiration_time}
              />
            ))}
          <div className="pagination">
            {!isLoading && pages >= 1 && data?.length >= 1 && (
              <Pagination
                onChange={handleChange}
                size="large"
                page={page}
                count={pages}
                color="primary"
              />
            )}
          </div>
        </TableWrapper>
      )}
      {showAcceptModal && (
        <ConfirmModal
          loading={loading}
          open={showAcceptModal}
          setOpen={setShowAcceptModal}
          handleClose={() => setShowAcceptModal(false)}
          cancelBtnText={"Cancel"}
          onConfirm={onAcceptBooking}
          confirmBtnText={"Accept"}
          title={"Accept booking request?"}
          content={
            "Are you sure you want to confirm this ride request? This action can not be undone."
          }
        />
      )}
      {showRejectModal && (
        <ConfirmModal
          open={showRejectModal}
          setOpen={setShowRejectModal}
          handleClose={() => setShowRejectModal(false)}
          cancelBtnText={"Cancel"}
          onConfirm={onRejectBooking}
          confirmBtnText={"Confirm"}
          title={"Reject this booking request?"}
          content={
            "Are you sure you want to reject this booking request? Frequent cancellation can lower your ranking on GoSawari.When someone sends you the booking request means he already have paid the service charges/commision to the GoSawari."
          }
          loading={rejecting}
        />
      )}
    </Container>
  );
};

export default RideRequests;
