import React from "react";
import styled from "styled-components";
import AdminInformation from "../components/groupSection/AdminInformation";
import GroupInformation from "../components/groupSection/Groupinfo";

function GroupToursDetail() {
  return (
    <Wrapper>
      <div className="admin-AdminInformation">
        <AdminInformation />
      </div>

      <div className="groupInformation">
        <GroupInformation />
      </div>
    </Wrapper>
  );
}

export default GroupToursDetail;
const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1900px;
  margin-bottom: 5rem;

  .admin-AdminInformation {
    height: auto;
  }

  .groupInformation {
    height: fit-content;
  }
`;
