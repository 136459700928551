import styled from "styled-components";
import { COLORS } from "../../assets/colors";

export const DropdownButton = styled.button`
  background-color: ${COLORS.primaryColor};
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: 66px;
  right: 0;
  width: 200px;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 2;
  border-radius: 5px;
  animation: dropdown 0.3s forwards;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  @keyframes dropdown {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const DropdownMenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #eee;
  }
`;

export const DropdownMenuIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  background-color: ${COLORS.primaryColor};
  color: #fff;
  border-radius: 50%;
`;

export const DropdownMenuLink = styled.p`
  font-size: 1rem;
  text-decoration: none;
  color: #333;
`;
