import moment from "moment";
import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const RefundItem = ({
  rideId,
  leavingFrom,
  headingTo,
  amount,
  date,
  status,
  cancel_reason = "",
}) => {
  return (
    <div className="card-one">
      <div className="destination">
        <Link to={`/active-ride/${rideId}`}>
          {" "}
          <div className="text-icon">
            <span>{leavingFrom}</span>
            <span>
              <FaArrowRightLong />
            </span>
            <span>{headingTo}</span>
          </div>
        </Link>

        <p>Rs.{amount}</p>
      </div>
      <div className="date">
        <p>Cancelled at: {moment(date).format("MMMM Do, YYYY")}</p>
        <p className={`status ${status ? "active" : "pending"}`}>
          {!status ? "Pending" : status}
        </p>
      </div>
      {cancel_reason && (
        <p className="cancel-reason">
          Cancel reason: {cancel_reason.split("_").join(" ")}
        </p>
      )}
    </div>
  );
};

export default RefundItem;
