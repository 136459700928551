import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SettingItem from "../settingitem";
import AccountSettingForm from "./AccountSettingForm";
import BillForm from "./BillForm";
import NotificationForm from "./NotificationForm";
import RidesForm from "./RidesForm";
import SecurityForm from "./SecurityForm";
import { settingList } from "./SettingList";
import Documents from "./Documents";

function Setting({ currentUser }) {
  const [currentComponent, setCurrentComponent] = useState({
    name: "Account",
    component: <AccountSettingForm user={currentUser} />,
  });

  const renderFunction = (settingItem) => {
    if (settingItem.name == "Account") {
      setCurrentComponent({
        name: "Account",
        component: <AccountSettingForm user={currentUser} />,
      });
    } else if (settingItem.name == "Security") {
      setCurrentComponent({
        name: "Security",
        component: <SecurityForm user={currentUser} />,
      });
    } else if (settingItem.name == "Plan & Bill") {
      setCurrentComponent({
        name: "Plan & Bill",
        component: <BillForm />,
      });
    } else if (settingItem.name == "Notification") {
      setCurrentComponent({
        name: "Notification",
        component: <NotificationForm />,
      });
    } else if (settingItem.name == "Documents") {
      setCurrentComponent({
        name: "Documents",
        component: <Documents user={currentUser} />,
      });
    }
    if (settingItem.name == "Rides") {
      setCurrentComponent({
        name: "Rides",
        component: <RidesForm />,
      });
    }
  };

  const renderCurrentComponent = () => currentComponent.component;
  return (
    <MainContainer>
      <LeftContainer>
        <Header>
          <Typography
            style={{ fontWeight: 600, fontSize: "18px" }}
            variant="h5"
            color="primary"
          >
            Settings
          </Typography>
        </Header>
        <SettingList>
          {settingList.map((item) => (
            <SettingItem item={item} renderFunction={renderFunction} />
          ))}
        </SettingList>
      </LeftContainer>

      <RightContainer>
        <RightHeader>
          <Typography Typography variant="subtitle2" color="primary">
            {currentComponent.name}
          </Typography>
        </RightHeader>
        <ContentContainer>{renderCurrentComponent()}</ContentContainer>
      </RightContainer>
    </MainContainer>
  );
}

export default Setting;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: #054752;
  @media (max-width: 546px) {
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex: 0.2;
  flex-direction: column;
  box-shadow: 1px 1px #ebe9e9;
`;

const SettingList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: white;
  @media (max-width: 546px) {
    flex-direction: row;
  }
`;

const Header = styled.div`
  color: "black";
  display: flex;
  padding: 20px;
  width: 100%;
  height: 5rem;
  box-shadow: 1px 1px #ebe9e9;
`;

const RightContainer = styled.div`
  flex: 0.8;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 1px 1px #ebe9e9;
  padding: 10px 20px;
`;

const RightHeader = styled.div`
  display: flex;
  height: 44px;
  justify-content: center;
  flex-direction: column;
  // box-shadow: 1px 1px #ebe9e9;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 90%;
  // box-shadow: 1px 1px #ebe9e9;
`;
