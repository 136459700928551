import { Avatar, Badge, CircularProgress } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import api from "../../services/api";
import { COLORS } from "../../assets/colors";
import { useNavigate } from "react-router-dom";
import CircularLoader from "../UI/Loader";
import mixins from "../../mixins";
import { HourglassEmpty } from "@material-ui/icons";

function Notification({ currentUser, getNotificationCount }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getNotifications = async () => {
      setLoading(true);
      const response = await api.get("/notifications");
      setNotifications(response.data.data);
      setLoading(false);
    };
    getNotifications();
  }, []);
  const handleNotifClick = (item) => {
    switch (item.notification_type) {
      case 1:
        navigate("/ride-requests");
        break;
      case 2:
        navigate("/my-bookings");
        break;
      case 3:
        navigate("/my-bookings");
      case 4:
        navigate("/ride-requests");
        break;
      case 5:
        navigate(`/profile/${currentUser?.id}/reviews`);
        break;
      case 6:
        navigate("/my-bookings");
        break;
      case 7:
        navigate("/my-bookings");
        break;
      case 8:
        navigate("/ride-requests");
        break;
      case 9:
        navigate("/ride-requests");
        break;
      case 10:
        navigate("/my-refunds");
        break;
    }
  };
  const markAsRead = async (notificationId, read) => {
    if (read == false) {
      const response = await api.post("/notifications/markAsRead", {
        notificationId,
      });
      const filtered = notifications.filter(
        (item) => item.id !== notificationId
      );
      setNotifications([response.data.data, ...filtered]);
      getNotificationCount();
    }
  };
  if (loading) {
    return <CircularLoader />;
  }

  return (
    <Wrapper>
      {!loading && notifications.length == 0 && (
        <div className="no-notif">
          <p
            style={{
              color: "gray",
              textAlign: "center",
            }}
          >
            No notificaions
          </p>
        </div>
      )}
      {!loading &&
        notifications.map((item) => (
          <div className="item" onClick={() => markAsRead(item.id, item.read)}>
            {item.notification_type === 10 ? (
              <div className="expired" onClick={() => handleNotifClick(item)}>
                <Badge
                  variant="dot"
                  color={item.read ? "default" : "primary"}
                />
                <HourglassEmpty
                  style={{
                    color: item.read
                      ? `${COLORS.secondaryTextColor}`
                      : `#050505`,
                  }}
                />
                <p
                  style={{
                    color: item.read
                      ? `${COLORS.secondaryTextColor}`
                      : `#050505`,
                  }}
                >
                  Your {item.NotificationType.message}
                </p>
              </div>
            ) : (
              <Fragment>
                <Badge
                  variant="dot"
                  color={item.read ? "default" : "primary"}
                />
                <Avatar
                  src={
                    item.notificationSender.avatar_url ||
                    `https://ui-avatars.com/api/?name=${item.notificationSender.first_name}`
                  }
                />
                <p
                  style={{
                    color: item.read
                      ? `${COLORS.secondaryTextColor}`
                      : `#050505`,
                  }}
                  onClick={() => handleNotifClick(item)}
                >
                  <span>{item.notificationSender.first_name} </span>
                  {item.NotificationType.message}
                </p>
              </Fragment>
            )}
          </div>
        ))}
    </Wrapper>
  );
}

export default Notification;
const Wrapper = styled.div`
  overflow-y: auto;
  @media (min-width: 1024px) {
    max-height: 19rem;
  }
  .no-notif {
    padding: 1rem;
  }
  .item {
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    :hover {
      background: #f5f5f5;
    }
    span {
      font-weight: 900;
      text-transform: capitalize;
    }
    .expired {
      text-align: center;
      justify-content: flex-start;
      margin: 5px;
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
    }
  }
  ${mixins.scrollStyle};
`;
