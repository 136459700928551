import * as types from "../constants";

const initialState = {
  isLoading: false,
  success: false,
  error: "",
  count: 0,
  data: [],
};

const myBookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOOKINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        data: action.payload.data,
        count: action.payload.count,
      };

    case types.GET_BOOKINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        data: [],
      };
    case types.CANCEL_BOOKING_REQUEST:
      const filtered = state.data.filter(
        (booking) => booking.id !== action.payload.id
      );
      return { ...state, data: filtered };
    default:
      return state;
  }
};
export default myBookingsReducer;
