import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import CircularLoader from "../UI/Loader";
import { GlobalContext } from "../../contexts/globalContext";
import { ArrowForward } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  containerStyles: {
    backgroundColor: "#054752;",
    minHeight: "28vh",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  innerContainer: {
    minHeight: "15vh",
  },
  btn1: {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#e9f0ea",
    },
  },
  Typographystyles: {
    color: "white",
  },
  fontWeight: {
    fontWeight: "500",
  },
  fare: {
    color: "#054752;",
    fontSize: "22px",
  },
}));

export default function PopularRides() {
  const classes = useStyles();
  const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { searchFields, setSearchFields } = useContext(GlobalContext);

  useEffect(() => {
    showAllRides();
  }, []);
  const showAllRides = async () => {
    try {
      setLoading(true);
      const params = {
        limit: 6,
        offset: 0,
      };
      const response = await api.get(`ride/popular`, {
        params,
      });
      setRides(response.data.data);

      setLoading(false);
    } catch (e) {}
  };

  const handleSearchRide = (ride) => {
    setSearchFields({
      goingTo: ride.heading_to,
      leavingFrom: ride.leaving_from,
    });
    navigate(
      `/ride-sharing?leavingFrom=${ride.leaving_from}&goingTo=${ride.heading_to}`
    );
  };

  const renderRoute = (ride) => {
    if (ride?.leaving_from == ride?.heading_to) {
      return (
        <span
          style={{ display: "flex", alignItems: "center", fontSize: "10px" }}
        >
          {ride.pickup_location.substring(0, 30)}
          <span>
            <ArrowForward />
          </span>
          {ride.drop_location.substring(0, 30)}
        </span>
      );
    } else {
      return (
        <span style={{ display: "flex", gap: "5px" }}>
          {ride.leaving_from}{" "}
          <span>
            <ArrowForward />
          </span>{" "}
          {ride.heading_to}
        </span>
      );
    }
  };

  return (
    <Container className={classes.containerStyles} maxWidth="xl">
      <Container maxWidth="md" className={classes.innerContainer}>
        <Box maxWidth="md" p={2}>
          <Grid container xs={12}>
            <Grid item sm={4}>
              <Typography
                variant="h5"
                className={[classes.Typographystyles, classes.fontWeight]}
              >
                Where do you want to go?
              </Typography>
            </Grid>
            <Grid item sm={4}></Grid>
            <Grid item sm={4}>
              <Typography
                variant="subtitle"
                align="left"
                className={classes.Typographystyles}
              >
                <Link style={{ color: "white" }} to={"/carpool"}>
                  See our most popular rides
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid item container xs={12} spacing={1}>
          {loading && <CircularLoader isLoading={loading} />}
          {!loading &&
            rides?.length > 0 &&
            rides.map((ride) => {
              return (
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    fullWidth
                    className={classes.btn1}
                    onClick={() => handleSearchRide(ride)}
                  >
                    <Typography variant="subtitle">
                      {renderRoute(ride)}
                      <div>
                        <span className={classes.fare}>
                          <span style={{ fontSize: "small" }}>RS.</span>
                          <strong>{ride.ticket}</strong>
                          <span style={{ fontSize: "small" }}>.00</span>
                        </span>{" "}
                      </div>
                    </Typography>
                  </Button>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Container>
  );
}
