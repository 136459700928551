import {PUBLISH_RIDE_INFO_REQUEST,PUBLISH_RIDE_INFO_SUCCESS, PUBLISH_RIDE_INFO_FALSE, PUBLISH_RIDE_INFO_FAILED, UPDATE_CURRENT_RIDE_INFO, ADD_NEW_VEHICLE_INFO} from "../constants";
const initialState = {
    isLoading: false,
    currentRideInfo: {},
    userVehicles:[],
    error:""
}

const publishRideInfoReducer = (state = initialState, action) => {
switch(action.type){
 case PUBLISH_RIDE_INFO_REQUEST:
    return {
        ...state, isLoading:false
    }
case PUBLISH_RIDE_INFO_SUCCESS:
    return {
        ...state, isLoading:false, currentRideInfo: action.payload.currentRideInfo,userVehicles:action.payload.userVehicles
    }
case UPDATE_CURRENT_RIDE_INFO:
    return {
        ...state, isLoading:false, currentRideInfo: action.payload.currentRideInfo
    }
case ADD_NEW_VEHICLE_INFO:
    const vehicles = [...state.userVehicles, action.payload]
    return {
        ...state, isLoading:false, userVehicles: vehicles
    }
case PUBLISH_RIDE_INFO_FAILED:
    return {
            ...state, isLoading:false,error: action.payload.error
    }
default: return state
}
}

export default publishRideInfoReducer;