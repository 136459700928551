import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, TextField } from "@material-ui/core";

import logo from "../../images/gosawariLogo.png";
import styled from "styled-components";
import CustomButton from "../UI/Button";
import api from "../../services/api";
import { COLORS } from "../../assets/colors";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import CancelButton from "../UI/CancelButton";

function Forget() {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("user/forgotPassword", {
        emailOrPhone,
      });
      if (response.status === 200) {
        setSuccess("Sent password reset details on your email");
      }
    } catch (e) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmailOrPhone(e.target.value);
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <MainContainer>
      <LogoContainer>
        <Avatar src={logo} style={{ height: "70px", width: "70px" }} />
        <h2 style={{ color: "#3d485d" }}>Forget Password</h2>
        <p>
          Enter the email address associated with your account and we'll send
          you a link to reset your password
        </p>
      </LogoContainer>

      <FormContainer>
        <form onSubmit={handleForgotPassword}>
          <TextField
            label="Email or phone"
            placeholder="test@yopmail.com or 03211234567"
            type="text"
            required
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <ButtonContainer>
            <CustomButton
              label={loading ? "Please wait" : "Send Details"}
              type={"submit"}
            />
          </ButtonContainer>
          <ButtonContainer>
            <CancelButton label={"Back"} onClick={handleGoBack} />
          </ButtonContainer>
          {success && <Alert severity="success">{success}</Alert>}
        </form>
        <LinkContainer>
          <Link to="/register">
            Don't have account? <span>Register</span>
          </Link>
        </LinkContainer>
      </FormContainer>
    </MainContainer>
  );
}

export default Forget;

const MainContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  p {
    color: ${COLORS.secondaryColor};
    font-size: small;
    text-align: left;
  }
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0;
  > form {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    > .MuiTextField-root {
      text-align: center;
      color: white;
      // margin-bottom: 5px;
    }
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  // margin: 5px;
  min-height: 50px;
  > a {
    text-decoration: none;
    color: grey;
    span {
      color: ${COLORS.linkColor};
    }
    :hover {
      text-decoration: underline;
    }
  }
`;
const ButtonContainer = styled.div`
  max-width: 250px;
  width: 100%;
`;
