import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import { COLORS } from "./../assets/colors";
import { Link } from "react-router-dom";

const GroupSuccessPage = () => {
  return (
    <Wrapper>
      <Icon>
        <FaCheckCircle />
      </Icon>
      <Message>We will review your group tour</Message>
      <Notification>
        You will receive a notification once your form is verified.It will take
        a few hours top complete review process.
      </Notification>
      <Link style={{ color: COLORS.linkColor }} to={"/group-tours"}>
        See all group tours
      </Link>
    </Wrapper>
  );
};
1;
export default GroupSuccessPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  color: #3d485d;
  font-family: "Arial", sans-serif;
`;

const Icon = styled.div`
  font-size: 64px;
  color: ${COLORS.primaryColor};
`;

const Message = styled.h2`
  margin-top: 20px;
  font-size: 24px;
  color: ${COLORS.primaryColor};
`;

const Notification = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: gray;
`;
