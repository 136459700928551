export const VEHICLE_MODEL = [
  1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
  2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
  2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
];
export const vehicleColors = [
  "black",
  "white",
  "silver",
  "gray",
  "red",
  "blue",
  "yellow",
  "beige",
  "other",
];

export const majorRoads = [
  "Karakoram Highway(KKH)",
  "Indus Highway",
  "N-5 National Highway",
  "Grand Trunk (GT) Road",
  "M2 Motorway",
  "M1 Motorway",
  "N-25 National Highway",
  "N-10 National Highway",
  "N-70 National Highway",
  "N-55 National Highway",
  "N-65 National Highway",
  "Other",
];

export const vehicelTypes = {
  sedan: "sedan",
  satchback: "hatchback",
  pickup: "pickup",
  suv: "suv",
  van: "van",
  commercial: "commercial",
  mpv: "mpv",
  other: "Other",
};

export const carCompanies = [
  "Toyota",
  "Honda",
  "Suzuki",
  "KIA",
  "Hyundai",
  "FAW",
  "Renault",
  "MG",
  "United",
  "Prince",
];
