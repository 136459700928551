import React from "react";
import styled from "styled-components";
import Table from "./Table";
import { IconButton } from "@material-ui/core";
import { CloudDownloadOutlined, RefreshRounded } from "@material-ui/icons";
import api from "../../../services/api";
import { useState } from "react";
import { useEffect } from "react";
import CircularLoader from "../../UI/Loader";

function Request() {
  const [refunds, setRefunds] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    getRefundDetails();
  }, []);
  const getRefundDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/refunds/");
      if (response.status === 200) {
        setRefunds(response.data.data);
        setCount(response.data.count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <RequestWrapper>
      <div className="request-nav">
        <Nav>
          <NavContainer>
            <NavLogo>
              <Typography>Refund Details</Typography>
            </NavLogo>
            <NavMenu>
              <NavMenuItem>
                <IconButton>
                  <RefreshRounded style={{ color: "white" }} />
                </IconButton>
              </NavMenuItem>
              <IconButton>
                <CloudDownloadOutlined style={{ color: "white" }} />
              </IconButton>
            </NavMenu>
          </NavContainer>
        </Nav>
      </div>
      <div className="table">
        {loading && <CircularLoader isLoading={loading} />}
        {!loading && count === 0 && <p> No date</p>}
        {!loading && count > 0 && (
          <Table data={refunds} getRefundDetails={getRefundDetails} />
        )}
      </div>
    </RequestWrapper>
  );
}

export default Request;
const RequestWrapper = styled.div`
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 4px;
  height: auto;
  .table {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
  }
`;

const Nav = styled.nav`
  color: #fff;
  padding: 1rem;
  color: white;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgb(7 30 28) 0%,
    rgba(5, 71, 82, 1) 60%,
    rgb(23 104 120) 100%
  );
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavLogo = styled.div`
  font-size: 2.5rem;
  color: #054752;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 2rem;
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
`;

const NavMenuItem = styled.li`
  font-size: 1.5rem;
  color: #054752;
`;
const Typography = styled.div`
  font-family: sans-serif;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
`;
