import React from "react";
import styled from "styled-components";
import { TextField, Typography } from "@material-ui/core";
import CustomButton from "../UI/Button";
import CancelButton from "../UI/CancelButton";

function BillForm() {
  return (
    <MainContainer>
      <Typography style={{fontWeight:600,fontSize:"13px"}} variant="subtitle2" color="secondary">Update bill & plan</Typography>

      <Typography variant="subtitle1" style={{fontWeight:400,fontSize:"13px"}} color="secondary">Update your bill and plan information</Typography>

      <FormContainer>
        <form>
          <TextField
            id='filled-First-Name-input'
            label='First Name'
            type='text'
            fullWidth
            variant='outlined'
            required
          />

          <TextField
            id='filled-Last-Name-input'
            label='First Name'
            type='text'
            fullWidth
            variant='outlined'
          />
          <TextField
            id='filled-Current-Password-input'
            label='First Name'
            type='text'
            fullWidth
            variant='outlined'
            required
          />
          <TextField
            id='filled-last-name-input'
            label='First Name'
            type='text'
            fullWidth
            variant='outlined'
            required
          />
          <BtnContainer>
            <CancelButton label='cancel' />
            <CustomButton label='save' type='submit' />
          </BtnContainer>
        </form>
      </FormContainer>
    </MainContainer>
  );
}

export default BillForm;
const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  > p:nth-child(2) {
    font-size: 12px;
    color: gray;
    font-weight: 900;
  }
`;

const FormContainer = styled.div`
  display: flex;
  padding-top: 20px;
  

  > form > .MuiTextField-root {
    margin: 10px 0px;
  }
  ,
  
`;

const BtnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: end;
  > div:nth-child(1) {
    margin-right: 21px;
  }
`;
