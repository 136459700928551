import React from 'react';
import EmployeeContent from './Content';
import styled from "styled-components";


const EmployeeCard = () => {
  return (
    <EmployeeCardWrapper>
      <EmployeeContent
      title= "Employee Status"
        name="Fida Hussain"
        department="Drivers"
        age={30}
        discipline="excellent" 
        status="Active"
      />
    </EmployeeCardWrapper>
  );
}

export default EmployeeCard;
const EmployeeCardWrapper = styled.div`
max-width:39rem;
height: auto;
`