import React, { useState } from "react";
import Navigation from "../../Navigation";
import PropTypes from "prop-types";
import { StyledHeader, StyledMain } from "./styles";
import BottomNav from "../../BottomNav";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
const MainLayout = ({ children }) => {
  const width = window.innerWidth;
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const [isScrolling, setIsScrolling] = useState(false);
  const isInboxRoute = useMatch("/inbox");

  const handleScroll = () => {
    setIsScrolling(true);

    clearTimeout(scrollTimeout);
    const scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 4000);
  };

  return (
    <>
      <StyledHeader>
        <Navigation />
      </StyledHeader>
      <StyledMain role="main" width>
        <div className="children">{children}</div>
        {isAuthenticated && width < 546 && !isInboxRoute && (
          <BottomNav
            currentUser={currentUser}
            isAuthenticated={isAuthenticated}
          />
        )}
      </StyledMain>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
