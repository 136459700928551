import React from "react";
import styled from "styled-components";
import {
  FaUserTie,
  FaBirthdayCake,
  FaUsers,
  FaFileAlt,
  FaClock,
  FaCar,
  FaDollarSign,
} from "react-icons/fa";
import { COLORS } from "../../../assets/colors";

const TourDetailss = ({ formData, handleChange }) => {
  const [error, setError] = React.useState({
    toursOperator: "",
    ageRange: "",
    maxMembers: "",
    coupleDocuments: "",
    tourDuration: "",
    vehicleType: "",
    perMemberCost: "",
  });

  const validateField = (field, value) => {
    let errorText = "";
    if (field === "toursOperator" && value.trim() === "") {
      errorText = "Please enter tour manager/operator name";
    }
    if (field === "ageRange" && value.trim() === "") {
      errorText = "Please enter a valid age range e.g 18-60";
    }
    if (field === "maxMembers" && value.trim() === "") {
      errorText = "Please enter maximum size of group";
    }
    if (field === "vehicleType" && value.trim() === "") {
      errorText = "Please provide information of vehicle";
    }
    if (field === "tourDuration" && value.trim() === "") {
      errorText = "Please set tour duration";
    }
    if (field === "perMemberCost" && value.trim() === "") {
      errorText = "Please set cost of per member";
    }
    return errorText;
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    handleChange(field)(event);

    const errorText = validateField(field, value);
    setError({ ...error, [field]: errorText });
  };

  return (
    <Wrapper>
      <Title>Tour Details</Title>

      <FormGroup>
        <Label>
          <FaUserTie /> Tour manager
        </Label>
        <Input
          placeholder="Enter tour manager name"
          required
          type="text"
          value={formData.toursOperator}
          onChange={handleInputChange("toursOperator")}
          error={error.toursOperator !== ""}
        />
        {error.toursOperator && <ErrorText>{error.toursOperator}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <Label>
          <FaBirthdayCake /> Age Range
        </Label>
        <Input
          required
          placeholder="18 to 60"
          type="text"
          value={formData.ageRange}
          onChange={handleInputChange("ageRange")}
          error={error.ageRange !== ""}
        />
        {error.ageRange && <ErrorText>{error.ageRange}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <Label>
          <FaUsers /> Maximum Group Members
        </Label>
        <Input
          required
          placeholder="Maximum group members"
          type="number"
          value={formData.maxMembers}
          onChange={handleInputChange("maxMembers")}
          error={error.maxMembers !== ""}
        />
        {error.maxMembers && <ErrorText>{error.maxMembers}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <Label>
          <FaFileAlt /> Document Requirements for Couples
        </Label>
        <Input
          required
          placeholder="Document Requirements for Couples"
          type="text"
          value={formData.coupleDocuments}
          onChange={handleInputChange("coupleDocuments")}
          error={error.coupleDocuments !== ""}
        />
        {error.coupleDocuments && (
          <ErrorText>{error.coupleDocuments}</ErrorText>
        )}
      </FormGroup>
      <FormGroup>
        <Label>
          <FaClock /> Tour Duration
        </Label>
        <Input
          required
          placeholder="Tour Duration"
          type="text"
          value={formData.tourDuration}
          onChange={handleInputChange("tourDuration")}
          error={error.tourDuration !== ""}
        />
        {error.tourDuration && <ErrorText>{error.tourDuration}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <Label>
          <FaCar /> Vehicle Type
        </Label>
        <Input
          required
          placeholder="Vehicle Type"
          type="text"
          value={formData.vehicleType}
          onChange={handleInputChange("vehicleType")}
          error={error.vehicleType !== ""}
        />
        {error.vehicleType && <ErrorText>{error.vehicleType}</ErrorText>}
      </FormGroup>

      <FormGroup>
        <Label>
          <FaDollarSign /> Per Member Cost
        </Label>
        <Input
          required
          placeholder="Per Member Cost"
          type="text"
          value={formData.perMemberCost}
          onChange={handleInputChange("perMemberCost")}
          error={error.perMemberCost !== ""}
        />
        {error.perMemberCost && <ErrorText>{error.perMemberCost}</ErrorText>}
      </FormGroup>
    </Wrapper>
  );
};

export default TourDetailss;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  color: #3d485d;
  font-family: "Arial", sans-serif;

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 95%;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: ${COLORS.primaryColor};

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 8px;
  color: ${COLORS.primaryColor};

  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    margin-bottom: 6px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid lightgray;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${COLORS.primaryColor};
  }

  ${(props) =>
    props.error &&
    `
    border-color: ${COLORS.errorColor};
  `}

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const ErrorText = styled.p`
  color: ${COLORS.errorColor};
  margin-top: 4px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
