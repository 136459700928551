import React, { useState } from "react";
import styled from "styled-components";
import helpData from "../../data/Help";
import { COLORS } from "../../assets/colors";
function HelpSection() {
  const [textLen, setTextLen] = useState(245);
  const [currentId, setCurrentId] = useState();
  const handleShowMore = (item) => {
    setCurrentId(item.id);
  };
  return (
    <Container>
      <div className="heading">
        <h2>GoSawari Help Centre</h2>
      </div>
      <div className="innerContainer">
        {helpData.map((item, key) => {
          return (
            <div className="helpItem" key={key}>
              <h3>{item.title} </h3>
              <p>
                {item.description.substring(
                  0,
                  currentId === item.id ? item.description.length : textLen
                )}{" "}
                {currentId !== item.id &&
                  item.description.length > textLen &&
                  "..."}
              </p>
              {item.description.length > textLen && currentId != item.id && (
                <p onClick={() => handleShowMore(item)}>read more</p>
              )}
            </div>
          );
        })}
      </div>
    </Container>
  );
}

export default HelpSection;

const Container = styled.div`

     padding: 3rem;
     height:auto;
     background-color:ghostwhite;
     @media(max-width: 546px) {
        padding: 1rem 0.5rem;
     }
     .heading{
            display:flex;
            justify-content: center;
       h2{
          text-align:center;
          color: rgb(5, 71, 82);
          font-weight: 600;
          padding-bottom:3rem;
          padding-top:2rem;
          font-size:30px;
          
          @media(max-width: 546px) {
            font-size:22px;
          } }}
     
    .innerContainer {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;
        display: flex;
        align-items: center;
        @media(max-width: 546px) {
          flex-direction: column;
       }
    }
    .helpItem {
        width: 48%;
        display:  inline-block;
        padding: 1rem 1rem;
        border-bottom:1px solid ${COLORS.borderColor};
        h3 {
            margin-bottom: 1rem;
            font-weight: 600;
            color:${COLORS.primaryTextColor};
            width:fit-content;
        }
        p {
            color:${COLORS.secondaryTextColor};
            line-height: 1.4;
            text-align: left;
            &:nth-child(3) {
                color: #00b5ff !important;
                float:right;
                margin-right:1rem;
                cursor:pointer
            }
        }
        @media(max-width: 546px) {
          width: 100%;
       }

    }
}  
`;
