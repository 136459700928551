import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: config.api.url,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.message === "Token expired") {
        // Handle token expiration
        toast.error("Session has expired. Please login again.");
        localStorage.removeItem("authToken");
        // Optionally, you can redirect the user to the login page
        window.location.href = "/login";
      }
      if (error.response.data.message === "Invalid token") {
        // Handle token expiration
        toast.error("Invalid token. Please login again.");
        localStorage.removeItem("authToken");
        window.location.href = "/login";
      }
      if (error.response.status === 401) {
        // Handle token expiration
        toast.error("Invalid token. Please login again.");
        localStorage.removeItem("authToken");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
