import React, { useState } from "react";
import styled from "styled-components";
import { FaCreditCard, FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function TourPaymentForm() {
  const [seats, setSeats] = useState(1);
  const tourCost = 25000;
  const platformFee = 0.07;
  const serviceCharge = 0.03;
  const additionalFee = platformFee + serviceCharge;
  const totalCost = tourCost * (1 + additionalFee);
  const advancePayment = tourCost * 0.5;

  const navigate = useNavigate();

  const handleSeatChange = (change) => {
    setSeats((prevSeats) => Math.max(1, prevSeats + change));
  };

  const handlePaymentClick = () => {
    navigate("/payment-success");
  };

  const handleCancelPayment = () => {
    navigate("/payment-cancellation");
  };

  return (
    <Wrapper>
      <Title>Tour Payment Details</Title>
      <PaymentCard>
        <CostDetails>
          <DetailRow>
            <DetailLabel>Tour Cost per Seat:</DetailLabel>
            <DetailValue>Rs. {tourCost}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Platform Fee (7%):</DetailLabel>
            <DetailValue>Rs. {(tourCost * platformFee).toFixed(2)}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Service Charge (3%):</DetailLabel>
            <DetailValue>
              Rs. {(tourCost * serviceCharge).toFixed(2)}
            </DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Total Additional Fee (10%):</DetailLabel>
            <DetailValue>
              Rs. {(tourCost * additionalFee).toFixed(2)}
            </DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Total Cost per Seat:</DetailLabel>
            <DetailValue>Rs. {totalCost.toFixed(2)}</DetailValue>
          </DetailRow>
        </CostDetails>
        <SeatsSection>
          <SeatsLabel>Select Seats:</SeatsLabel>
          <SeatsControl>
            <ControlButton onClick={() => handleSeatChange(-1)}>
              <FaMinus />
            </ControlButton>
            <SeatsInput type="number" value={seats} readOnly />
            <ControlButton onClick={() => handleSeatChange(1)}>
              <FaPlus />
            </ControlButton>
          </SeatsControl>
        </SeatsSection>
      </PaymentCard>
      <TotalCostSection>
        <AdvanceSection>
          <DetailRow>
            <DetailLabel>Advance Payment (50%):</DetailLabel>
            <DetailValue>Rs. {advancePayment.toFixed(2)}</DetailValue>
          </DetailRow>
        </AdvanceSection>
        <TotalCost>
          <DetailRow>
            <DetailLabel>Total Cost for {seats} Seat(s):</DetailLabel>
            <DetailValue>Rs. {(totalCost * seats).toFixed(2)}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Total Advance Payment:</DetailLabel>
            <DetailValue>Rs. {(advancePayment * seats).toFixed(2)}</DetailValue>
          </DetailRow>
        </TotalCost>
        <ButtonGroup>
          <PayButton onClick={handlePaymentClick}>
            <FaCreditCard size={20} style={{ marginRight: "8px" }} />
            Proceed to Payment
          </PayButton>
          <CancelButton onClick={handleCancelPayment}>
            Cancel Payment
          </CancelButton>
        </ButtonGroup>
      </TotalCostSection>
    </Wrapper>
  );
}

export default TourPaymentForm;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #054752;
  margin-bottom: 1.5rem;
  font-family: "Roboto", sans-serif;
`;

const PaymentCard = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -24px;
  right: -24px;
  background-color: #fff;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const CostDetails = styled.div`
  margin-bottom: 1.5rem;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const DetailLabel = styled.span`
  font-size: 1rem;
  color: #333;
  font-weight: 500;
`;

const DetailValue = styled.span`
  font-size: 1rem;
  color: #054752;
  font-weight: 700;
`;

const SeatsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

const SeatsLabel = styled.label`
  font-size: 1rem;
  color: #333;
  font-weight: 500;
`;

const SeatsControl = styled.div`
  display: flex;
  align-items: center;
`;

const ControlButton = styled.button`
  background-color: #054752;
  color: #fff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 0.5rem;

  &:hover {
    background-color: #046a73;
  }
`;

const SeatsInput = styled.input`
  width: 60px;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  pointer-events: none;
`;

const TotalCostSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const AdvanceSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;

  p {
    font-size: 1.2rem;
    color: #333;
    margin: 0.5rem 0;
    font-family: "Roboto", sans-serif;

    strong {
      color: #054752;
    }
  }
`;

const TotalCost = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;

  p {
    font-size: 1.2rem;
    color: #333;
    margin: 0.5rem 0;
    font-family: "Roboto", sans-serif;

    strong {
      color: #054752;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PayButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #054752;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Roboto", sans-serif;

  &:hover {
    background-color: #046a73;
  }
`;

const CancelButton = styled.button`
  background-color: #d9534f;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Roboto", sans-serif;

  &:hover {
    background-color: #c9302c;
  }
`;
