import React from 'react'
import Tours from '../../components/groupSection/toursGroup'
import styled from "styled-components";

function GroupTours() {
  return (
    <Wrapper>
      <Tours/>
    </Wrapper>
  )
}

export default GroupTours
 
const Wrapper =styled.div`
max-width: 96rem;
height: auto;
padding-bottom:5rem;
` 