import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomButton from "../../components/UI/Button";
import { TextField } from "@material-ui/core";
import mobile from "../../images/mobile with hand.png";
import { validatePhoneNumber } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Alert } from "@material-ui/lab";

const GetPhoneNumber = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const maxDigits = 11;
  const handlePhoneNumberChange = (event) => {
    const input = event.target.value.replace(/\D/g, "");
    if (input.length <= maxDigits) {
      setPhoneNumber(input);
      if (error) {
        setError(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validatePhoneNumber(phoneNumber);
    if (!isValid) {
      setError(true);
      return;
    }
    try {
      const response = await api.post("/auth/generate-otp", {
        mobileNumber: phoneNumber,
      });
      if (response.status == 200) {
        navigate("/otp");
      }
    } catch (e) {
      toast.error(e.response.data);
      setErrorText(e.response.data);
      setError(true);
    }
  };

  return (
    <Wrapper>
      <div className="header-section">
        <form onSubmit={handleSubmit}>
          <div className="img-container">
            <img src={mobile} alt="logo" />
            <div className="heading-text">
              <h4>Enter your Phone Number</h4>
              <p>
                We will send you a phone verification code via text message{" "}
              </p>
            </div>
          </div>
          <div className="text-field">
            <TextField
              label="Mobile Number"
              placeholder="03xxxxxxxxx"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: maxDigits }}
              error={error}
              helperText={error ? "Enter a valid phone number" : null}
            />
          </div>
          <div className="btn">
            <CustomButton label="Generate OTP" onClick={handleSubmit} />
          </div>
          {errorText && (
            <Alert
              style={{ marginTop: "0.5rem", width: "100%" }}
              severity="error"
            >
              {errorText}
            </Alert>
          )}
        </form>
      </div>
    </Wrapper>
  );
};

export default GetPhoneNumber;

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 4rem;
  height: auto;
  @media (max-width: 600px) {
    padding-top: 3rem;
  }

  .header-section {
    width: 26rem;
    max-width: 100%;
    height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      img {
        width: 12rem;
        height: 10rem;
      }

      .heading-text {
        text-align: center;
        gap: 0.25rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        h4 {
          font-size: large;
        }
        p {
          font-size: 1rem;
          color: gray;
        }
      }
    }
    form {
      width: 100%;
      padding: 10px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text-field {
      margin-bottom: 1rem;
      width: 100%;
    }

    .btn {
      width: 60%;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
`;
