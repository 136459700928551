import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../services/api";
import Ride from "../RideV2";
import NoData from "../NoData";
import SearchField from "./Search";
import { Pagination } from "@material-ui/lab";
import { toast } from "react-toastify";
import mixins from "../../mixins";
import Filters from "../Filters";
import { Chip } from "@material-ui/core";
import { ClearAll, FilterListOutlined } from "@material-ui/icons";
import FilterModal from "../../components/models/FilterModal";
import { FaCar, FaPlus, FaSearch } from "react-icons/fa";
import CircularLoader from "../UI/Loader";

const SilkPort = ({ openSearchModal }) => {
  const [currentCities, setCurrentCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [rides, setRides] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [amenity, setAmenity] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const offset = (page - 1) * pageSize;

  const navigate = useNavigate();

  useEffect(() => {
    async function getDepartureCities() {
      setLoading(true);
      const response = await api.get("ride/leavingFrom");
      setCurrentCities(response.data.data);
      setLoading(false);
    }
    getDepartureCities();
  }, []);

  useEffect(() => {
    async function getRidesLeavingFrom() {
      setLoading(true);

      let params = {
        limit: limit,
        offset: offset,
      };
      if (sortBy) {
        params = { ...params, sortBy };
      }
      if (departureTime) {
        params = { ...params, departureTime };
      }
      if (amenity) {
        params = { ...params, amenity };
      }

      if (!selectedCity) {
        const response = await api.get(`ride/`, {
          params,
        });
        setRides(response.data.data);
        if (response.data.totalRides > 0) {
          setPages(Math.ceil(response.data.totalRides / pageSize));
        }
      } else {
        const response = await api.get(`ride/getRides/${selectedCity}`, {
          params,
        });
        setRides(response.data.data);
        if (response.data.totalRides > 0) {
          setPages(Math.ceil(response.data.totalRides / pageSize));
        }
      }
      setLoading(false);
    }
    getRidesLeavingFrom();
  }, [selectedCity, offset, sortBy, departureTime, amenity]);

  const handleCity = (city) => {
    if (!selectedCity) {
      setPage(1);
    }
    setSelectedCity(city);
  };

  const searchCity = async (event) => {
    const searchText = event.target.value;
    if (searchText === "") {
      setSearchTerm(searchText);
      setLoading(true);
      const response = await api.get("ride/leavingFrom");
      setCurrentCities(response.data.data);
      setLoading(false);
    } else {
      setSearchTerm(searchText);
      const filteredResults = currentCities.filter((item) =>
        item.leaving_from.toLowerCase().includes(searchText.toLowerCase())
      );
      setCurrentCities(filteredResults);
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const showAllRides = async () => {
    try {
      setLoading(true);
      setSelectedCity("");
      const params = {
        limit: limit,
        offset: offset,
      };
      const response = await api.get(`ride/`, {
        params,
      });
      setRides(response.data.data);
      if (response.data.totalRides > 0) {
        setPages(Math.ceil(response.data.totalRides / pageSize));
      }
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const clearSearch = async () => {
    setSearchTerm("");
    setSelectedCity("");
    const response = await api.get("ride/leavingFrom");
    setCurrentCities(response.data.data);
  };
  const handleSort = (sorter) => {
    if (sorter == sortBy) {
      setSortBy(null);
    } else {
      setSortBy(sorter);
    }
  };
  const handleDeparturetime = (dptime) => {
    if (departureTime == dptime) {
      setDepartureTime(null);
    } else {
      setDepartureTime(dptime);
    }
  };
  const handleAmenity = (_amenty) => {
    if (amenity == _amenty) {
      setAmenity(null);
    } else {
      setAmenity(_amenty);
    }
  };
  const clearAll = () => {
    setSortBy(null);
    setDepartureTime(null);
    setAmenity(null);
  };

  return (
    <StyledSilkPort>
      <div className="sider">
        <div className="sider__header">
          <SearchField
            onChange={searchCity}
            clearSearch={clearSearch}
            value={searchTerm}
            placeholder="Search departure..."
          />
        </div>
        <div className="sider__content">
          <ul>
            {currentCities?.length == 0 ? (
              <p style={{ color: "gainsboro", padding: "1rem" }}>No data</p>
            ) : (
              <>
                <li className={"departure"}>Departure</li>
                <li
                  className={selectedCity == "" ? "active" : null}
                  onClick={showAllRides}
                >
                  Latest rides
                </li>
              </>
            )}

            {currentCities?.length > 0 &&
              currentCities.map((city, index) => (
                <li
                  key={index}
                  className={
                    selectedCity === city.leaving_from ? "active" : null
                  }
                  onClick={() => handleCity(city.leaving_from)}
                >
                  {city.leaving_from}
                </li>
              ))}
          </ul>
          <div className="bottom"></div>
        </div>
      </div>
      <div className="content">
        <div className="content__header">
          <div className="content__header--left">
            <h2>
              {selectedCity
                ? `${selectedCity} to other locations`
                : "Latest rides"}
            </h2>
            {selectedCity ? (
              <p>
                Available rides from <strong>{selectedCity}</strong> to the
                other locations in Pakistan
              </p>
            ) : (
              <p>Find the rides leaving from your city</p>
            )}
          </div>
          <div className="content__header--right">
            {selectedCity && (
              <Button onClick={showAllRides}>
                <CarIcon />
                Show All
              </Button>
            )}
            {(sortBy || amenity || departureTime) && (
              <Button
                onClick={() => {
                  setSortBy(null);
                  setAmenity(null);
                  setDepartureTime(null);
                }}
              >
                <ClearAll />
                Clear filters
              </Button>
            )}

            <Button onClick={() => openSearchModal()}>
              <SearchIcon />
              Search Ride
            </Button>
            <Button onClick={() => navigate("/publish")}>
              <CreateIcon />
              Create Ride
            </Button>
            {window.innerWidth <= 1368 && (
              <Button onClick={() => setShowFilters(true)}>
                <FilterListOutlined />
                Filters
              </Button>
            )}
          </div>
        </div>
        <div>
          {(sortBy || amenity || departureTime) && (
            <FiltersSelected>
              {sortBy && (
                <Chip
                  label={
                    sortBy == "earliest"
                      ? "Earliest departure"
                      : sortBy == "price"
                      ? "Lowest price"
                      : sortBy
                  }
                  variant="outlined"
                  onDelete={() => {
                    setSortBy(null);
                  }}
                />
              )}
              {amenity && (
                <Chip
                  label={
                    amenity == "2inB"
                      ? "Max 2 in back"
                      : amenity == "smoking"
                      ? "smoking allowed"
                      : amenity
                  }
                  variant="outlined"
                  onDelete={() => {
                    setAmenity(null);
                  }}
                />
              )}

              {departureTime && (
                <Chip
                  label={
                    departureTime === "bf6"
                      ? "Before 06:00 AM"
                      : departureTime === "after6Pm"
                      ? "After 18:00"
                      : departureTime === "6-12"
                      ? "6AM - 12PM"
                      : departureTime == "12-18"
                      ? "12:00 - 18:00"
                      : departureTime
                  }
                  variant="outlined"
                  onDelete={() => {
                    setDepartureTime(null);
                  }}
                />
              )}
            </FiltersSelected>
          )}
        </div>
        <div className="content__body">
          {isLoading && (
            <div style={{ width: "100%" }}>
              <CircularLoader isLoading={isLoading} />
            </div>
          )}
          <div className="cardContainer">
            {rides?.length == 0 && !isLoading && (
              <div className="no-data">
                <NoData
                  showImage={false}
                  title={"Currently, no rides are available."}
                  message={
                    "Explore available rides by selecting a city to view all departing options or search for a specific ride."
                  }
                />
              </div>
            )}
            {rides?.length > 0 &&
              !isLoading &&
              rides.map((item) => {
                return (
                  <div className="card">
                    <Ride ride={item} isMine={false} />
                  </div>
                );
              })}
          </div>
          {rides?.length > 0 && (
            <div className="filters">
              <Filters
                handleSort={handleSort}
                sortBy={sortBy}
                handleDeparturetime={handleDeparturetime}
                departureTime={departureTime}
                handleAmenity={handleAmenity}
                amenity={amenity}
                clearAll={clearAll}
                page={"carpool"}
              />
            </div>
          )}
        </div>
        {!isLoading && pages > 1 && (
          <div className="pagination">
            <Pagination
              onChange={handleChange}
              size="large"
              page={page}
              count={pages}
              color="primary"
            />
          </div>
        )}
        {showFilters && (
          <FilterModal
            isOpen={showFilters}
            setIsOpen={setShowFilters}
            handleSort={handleSort}
            sortBy={sortBy}
            handleDeparturetime={handleDeparturetime}
            departureTime={departureTime}
            handleAmenity={handleAmenity}
            amenity={amenity}
            clearAll={clearAll}
          />
        )}
      </div>
    </StyledSilkPort>
  );
};

export default SilkPort;
const FiltersSelected = styled.div`
  display: flex;
  margin: 1rem;
  gap: 0.25rem;
`;
const StyledSilkPort = styled.div`
  min-height: 100vh;
  width: -webkit-fill-available;
  display: flex;
  gap: 1rem;
  overflow: auto;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .sider {
    background: linear-gradient(
      90deg,
      rgb(7 30 28) 0%,
      rgba(5, 71, 82, 1) 60%,
      rgb(23 104 120) 100%
    );
    color: white;
    flex: 0.2;
    min-width: 18rem;
    &__header {
      padding: 1rem;
      border-bottom: 1px solid #3b6b70;
      text-align: center;
      h2 {
        margin-bottom: 1rem;
        font-size: larger;
      }
      p {
        color: lightgray;
      }
    }
    &__content {
      @media (min-width: 1024px) {
        height: 548px;
      }
      overflow-y: auto;
      ${mixins.scrollStyleSilkPort};
      ul {
        list-style: none;
        font-size: 18px;
        li {
          margin: 1rem;
          cursor: pointer;
          min-width: 5rem;
          :hover {
            font-weight: 600;
            font-size: 20px;
          }
        }
        @media (max-width: 767px) {
          list-style: none;
          font-size: 18px;
          display: flex;
          // max-width: 372px;
          overflow-x: auto;
          padding: 0 1rem;
        }
      }
      .active {
        font-weight: 600;
        font-size: 20px;
      }
      .departure {
        cursor: default;
        font-weight: 100;
        color: darkgrey;
        :hover {
          font-weight: normal;
          font-size: 18px;
        }
      }
    }
    &.hide-scrollbar {
      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
  .content {
    flex: 0.8;
    padding-right: 1rem;
    @media (max-width: 546px) {
      padding-bottom: 6rem;
    }
    &__header {
      padding: 1rem 0;
      color: ${COLORS.secondaryColor};
      text-align: left;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      &--left {
        h2 {
          margin-bottom: 5px;
        }
        p {
          color: ${COLORS.secondaryTextColor};
        }
      }
      &--right {
        display: flex;
        justify-content: right;
        gap: 0.5rem;
        flex-wrap: wrap;
        button {
          flex: auto;
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
      }
    }
    &__body {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      min-height: 26rem;
      justify-content: space-between;
      .cardContainer {
        max-width: 920px;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        margin: 0 auto;
        padding-bottom: 2rem;
        .no-data {
          width: 100%;
          height: 100%;
        }
        @media (max-width: 1024px) {
          flex-direction: column;
          flex-wrap: nowrap;
        }
      }
      .filters {
        width: 11rem;
        height: max-content;
        border: 1px solid ${COLORS.borderColor};
        border-radius: 4px;
        padding: 0.5rem;
        @media (max-width: 1368px) {
          display: none;
        }
      }

      .card {
      }
      @media (max-width: 992px) {
        .card {
          flex-basis: calc(50% - 20px);
        }
      }

      @media (max-width: 912px) {
        .card {
          flex-basis: calc(100% - 20px);
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      @media (max-width: 546px) {
        margin-bottom: 5rem;
      }
      margin: 2rem 0;
    }
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #054752;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: #063d49;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
`;

const SearchIcon = styled(FaSearch)`
  margin-right: 0.5rem;
`;

const CreateIcon = styled(FaPlus)`
  margin-right: 0.5rem;
`;
const CarIcon = styled(FaCar)`
  margin-right: 0.5rem;
`;
