import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./App.css";

import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER } from "./redux/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./globalStyles";
import MainLayout from "./components/Layouts/MainLayout";
import AppRouter from "./AppRouter";
import GlobalContextProvider from "./contexts/globalContext";
import { socket } from "./socket";

import ReactGA from "react-ga4";
import { getCities } from "./redux/actions";
import ErrorBoundary from "./ErrorBoundary";
ReactGA.initialize("G-LY5DRBB6ZW");

function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    dispatch(getCities());
    if (token) {
      const decoded = jwt_decode(token);
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      socket.connect();
      socket.emit("userInfo", decoded);
    }
  }, []);

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <GlobalContextProvider>
        <Router>
          <MainLayout>
            <AppRouter />
          </MainLayout>
        </Router>
        <ToastContainer position="bottom-right" />
      </GlobalContextProvider>
    </ErrorBoundary>
  );
}

export default App;
