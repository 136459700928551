import { FaTicketAlt, FaSearch, FaUserFriends, FaCar } from "react-icons/fa";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { useNavigate } from "react-router-dom";

const HowItWorksForDriver = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <h2>How it works for Drivers</h2>

      <div className="timeline">
        <div className="timeline-item">
          <div className="timeline-content">
            <FaSearch className="timeline-icon" />
            <div className="timeline-number">1</div>
            <h3>Offer a ride</h3>
            <p>
              Offer a ride by entering departure and arrival points, select or
              add car details.Turn on <strong>AUTOMATIC APPROVAL</strong> to
              accept the booking requests automatically.{" "}
              <strong>Females only </strong>
              ride option is also available for the female drivers and
              passengers only.
            </p>
          </div>
          <div className="offer-ride" onClick={() => navigate("/login")}>
            <h2>Login to Offer a Ride</h2>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <FaTicketAlt className="timeline-icon" />
            <div className="timeline-number">2</div>
            <h3>Get Booking Requests</h3>
            <p>
              Get booking requests from passengers by offering a ride.If you
              have turned on the <strong>AUTOMATIC APPROVAL</strong> option, it
              will be approved without your confirmation.On your confirmation
              you will get the passanger's contact details and also can use
              GoSawari chat system.
            </p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <FaUserFriends className="timeline-icon" />
            <div className="timeline-number">3</div>
            <h3>Start the Ride</h3>
            <p>
              Collect the payment from the passengers before starting the
              ride.You can start ride from your rides page.
            </p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <FaCar className="timeline-icon" />
            <div className="timeline-number">4</div>
            <h3>Complete the ride</h3>
            <p>
              On reaching the destination you have to mark your ride as complete
              and share feedback for each passanger which will be displayed on
              their profile.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HowItWorksForDriver;

const Container = styled.div`
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    display: inline-block;
    margin-left: 2.9rem;
    color: #f5f5f5;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
    line-height: 1.06;
    font-size: 2.125rem;
    @media (max-width: 546px) {
      margin: 2rem 0;
    }
  }

  .timeline {
    border-radius: 0.5rem;
    background-color: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-around;
    margin: 20px;
    max-width: 80rem;
    padding: 0.5rem;
    margin-bottom: 2rem;
    gap: 2rem;

    @media (max-width: 740px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .timeline-item {
    max-width: 16rem;
    max-height: auto;
    text-align: center;
    position: relative;
    .offer-ride {
      background: transparent;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      border: 3px solid #221f1e;
      cursor: pointer;
      :hover {
        border: 2px solid white;
        color: darkgray;
      }
      h2 {
        font-size: 22px;
        margin-left: 0;
        :hover {
          color: darkgray;
        }
      }
    }
  }

  .timeline-content {
    padding: 10px;
    border-radius: 5px;
    text-align: left;
  }

  .timeline-icon {
    margin-bottom: 10px;
    color: ${COLORS.primaryColor};
    font-size: 24px;
  }

  .timeline-number {
    border: 2px solid #054752;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: black;
    line-height: 36px;
    text-align: center;
    position: absolute;
    top: -30px;
    font-size: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  h3 {
    margin-bottom: 1rem;
    font-weight: 600;
    width: fit-content;
  }

  p {
    line-height: 1.4;
  }
`;
