import React from "react";
import styled from "styled-components";
import {
  FaMoneyCheckAlt,
  FaRegCalendarTimes,
  FaHeartbeat,
  FaShieldAlt,
  FaExclamationTriangle,
  FaUserCheck,
} from "react-icons/fa";

const ToursTermsAndConditions = () => {
  return (
    <Wrapper>
      <Title>Tours Terms and Conditions</Title>
      <Subtitle>Please read our terms and conditions carefully</Subtitle>
      <TermsSection>
        <TermTitle>
          <FaMoneyCheckAlt /> Booking and Payment
        </TermTitle>
        <TermText>
          All bookings must be made in advance and are subject to availability.
          A non-refundable deposit is required at the time of booking. The
          balance must be paid in full at least 30 days before the tour
          departure date.
        </TermText>
      </TermsSection>
      <TermsSection>
        <TermTitle>
          <FaRegCalendarTimes /> Changes and Cancellations
        </TermTitle>
        <TermText>
          Any changes to the booking must be requested in writing. We reserve
          the right to charge an administrative fee for any changes.
          Cancellations must be made in writing and are subject to our
          cancellation policy.
        </TermText>
      </TermsSection>
      <TermsSection>
        <TermTitle>
          <FaHeartbeat /> Health and Safety
        </TermTitle>
        <TermText>
          It is the responsibility of the member to ensure they are in good
          health and fit to participate in the tour. Any medical conditions must
          be disclosed at the time of booking. We reserve the right to refuse
          participation to anyone deemed unfit.
        </TermText>
      </TermsSection>
      <TermsSection>
        <TermTitle>
          <FaShieldAlt /> Travel Insurance
        </TermTitle>
        <TermText>
          Travel insurance is strongly recommended for all members. The
          insurance should cover medical expenses, personal injury, and trip
          cancellations.
        </TermText>
      </TermsSection>
      <TermsSection>
        <TermTitle>
          <FaExclamationTriangle /> Liability
        </TermTitle>
        <TermText>
          We are not liable for any loss, damage, injury, or death resulting
          from participation in the tour, except where such loss, damage,
          injury, or death is caused by our negligence.
        </TermText>
      </TermsSection>
      <TermsSection>
        <TermTitle>
          <FaUserCheck /> Code of Conduct
        </TermTitle>
        <TermText>
          All members are expected to behave responsibly and respectfully
          towards other members and local communities. Any behavior deemed
          inappropriate may result in expulsion from the tour without a refund.
        </TermText>
      </TermsSection>
    </Wrapper>
  );
};

export default ToursTermsAndConditions;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin-bottom: 2rem;
  color: #666;
  text-align: center;
`;

const TermsSection = styled.div`
  margin-bottom: 1.5rem;
`;

const TermTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 0.5rem;
  color: #444;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: #007bff;
  }
`;

const TermText = styled.p`
  font-size: 14px;
  color: #555;
  line-height: 1.5;
`;
