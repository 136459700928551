import { Avatar } from "@material-ui/core";
import { NavigateNext, Next } from "@material-ui/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import { BOOKING_REQUEST_STATUS } from "../../Utils";
import ReviewModal from "../models/ReviewModal";

function CoTraveller({
  currentUser,
  travellers,
  iAmDriver,
  rideId,
  rideStatus,
}) {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currentTraveller, setCurrentTraveler] = useState(null);
  const navigate = useNavigate();
  const filteredTravellers = travellers?.filter(
    (tr) => tr.status === BOOKING_REQUEST_STATUS.ACCEPTED
  );
  return (
    <Container>
      <div className="body">
        <div className="cotraveller">
          <h4 className="travellerheading">CoTravellers</h4>
          {filteredTravellers?.length == 0 && (
            <p style={{ fontSize: "16px" }}>
              No co-travellers currently. Be the first one by booking your seat.
            </p>
          )}
          {filteredTravellers?.length > 0 &&
            filteredTravellers.map((traveller) => {
              return (
                <div className="passanger">
                  <div
                    className="avatarcontainer"
                    onClick={() => {
                      navigate(`/profile/${traveller.requestedBy.id}`);
                    }}
                  >
                    <Avatar
                      className="img"
                      src={`${
                        traveller.requestedBy.avatar_url ||
                        `https://ui-avatars.com/api/?name=${traveller.requestedBy.first_name}`
                      }`}
                      alt="avatar"
                    />
                    <p className="avatar">
                      {traveller.requestedBy.first_name}&nbsp;
                      {traveller.requestedBy.last_name}
                    </p>
                  </div>
                  {rideStatus === "completed" && iAmDriver ? (
                    <span
                      className="add-review"
                      onClick={() => {
                        setCurrentTraveler(traveller);
                        setShowReviewModal(true);
                      }}
                    >
                      Add Review
                    </span>
                  ) : (
                    <NavigateNext />
                  )}
                  {showReviewModal && (
                    <ReviewModal
                      handleClose={() => setShowReviewModal(false)}
                      open={showReviewModal}
                      traveller={currentTraveller}
                      rideId={rideId}
                      rideStatus={rideStatus}
                      iAmDriver={iAmDriver}
                      currentUser={currentUser}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </Container>
  );
}

export default CoTraveller;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 546px) {
    justify-content: flex-start;
  }
  .body {
    border-radius:1rem;
    width: 75%;
    @media(max-width:600px){
      width: 100%;

    }
    padding: 1rem;
    font-size: 12px;
    color: ${COLORS.primaryColor};
        .cotraveller {
      .travellerheading {
        color: ${COLORS.primaryColor};
        font-size:1rem;
      }
      p {
        color: ${COLORS.secondaryTextColor}
      }
      .passanger{
        display: flex;
        align-items: center;
        justify-content:space-between;
        cursor: pointer;
        .add-review {
          border: 1px solid;
          padding: 4px 8px;
          border-radius: 18px; 
           :hover {
               background-color: ${COLORS.lightBackgroundColor};
               border: 2px solid;

          }
        }

        .avatarcontainer{
          display: flex;
        align-items: center;
        :hover {
          text-decoration: underline;
        }
        .avatar{
          padding:1rem;
          font-size:13px;
          color:rgb(61, 72, 93);
          text-transform:capitalize;
        }
        .img{
          width:31px;
          height:30px;
          background:#054752;
        }

        }
    }
      }
    }
  }
`;
