import { useEffect, useState } from "react";
import api from "../services/api";
const useRefunds = (initialValue) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getRefundDetails() {
      try {
        setLoading(true);
        const response = await api.get("rideRequests/my-refunds");
        if (response.status == 200) {
          setData(response.data.data);
        }
      } catch (e) {
        console.log("Error", e);
      } finally {
        setLoading(false);
      }
    }
    getRefundDetails();
  }, []);

  return {
    data,
    loading,
  };
};

export default useRefunds;
