import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import CustomButton from "../UI/Button";
import CancelButton from "../UI/CancelButton";
import InputField from "../UI/InputField";
import useInput from "../../hooks/useInput";
import { toast } from "react-toastify";
import api from "../../services/api";

function Documents({ user }) {
  const [profile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.id) {
      getUserById(user?.id);
    }
  }, [user]);
  async function getUserById(userId) {
    try {
      setLoading(true);
      const response = await api.get(`user/${userId}`);
      setUserProfile(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }
  const onDeleteDocument = async (type) => {
    try {
      const response = await api.post(`user/delete-document`, {
        type,
      });
      if (response.status === 200) {
        toast.success("Document Deleted");
        getUserById(user?.id);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };
  return (
    <MainContainer>
      <Typography variant="h6" color="textPrimary">
        My Documents
      </Typography>
      {!profile?.cnic_front && !profile?.license_front && !loading && (
        <p>
          You have not uploaded any documents yet. Upload documents from your
          profile and publish ride page to get a verifid badge.
        </p>
      )}
      {profile && profile.cnic_front && profile.cnic_back && (
        <StyledCardContainer>
          <Typography variant="h6" color="textPrimary">
            CNIC
          </Typography>
          <div className="card">
            <img
              src={profile.cnic_front}
              className="doc-image"
              alt="cnic front"
            />
            <img
              src={profile.cnic_back}
              className="doc-image"
              alt="cnic back"
            />
          </div>
          <BtnContainer>
            <CustomButton
              bgColor="orangered"
              hoverColor="orange"
              label="Delete CNIC"
              onClick={() => onDeleteDocument("cnic")}
            />
          </BtnContainer>
        </StyledCardContainer>
      )}
      {profile && profile.license_front && profile.license_back && (
        <StyledCardContainer>
          <Typography variant="h6" color="textPrimary">
            License
          </Typography>

          <div className="card">
            <img
              src={profile.license_front}
              className="doc-image"
              alt="cnic front"
            />
            <img
              src={profile.license_back}
              className="doc-image"
              alt="cnic back"
            />
          </div>
          <BtnContainer>
            <CustomButton
              bgColor="orangered"
              hoverColor="orange"
              label="Delete License"
              onClick={() => onDeleteDocument("license")}
            />
          </BtnContainer>
        </StyledCardContainer>
      )}
    </MainContainer>
  );
}

export default Documents;
const MainContainer = styled.div`
  display: flex;
  height: 85vh;
  width: 100%;
  flex-direction: column;
`;
const StyledCardContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-bottom: 2rem;
  background: whitesmoke;
  width: max-content;
  padding: 1rem;
  border-radius: 8px;
  .card {
    display: flex;
    gap: 10px;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    img {
      border-radius: 8px;
      height: 150px;
      width: 300px;
    }
  }
`;

const BtnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: start;
  > div:nth-child(1) {
    margin-right: 21px;
  }
  @media (max-width: 546px) {
    margin-bottom: 6rem;
  }
`;
