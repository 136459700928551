import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Otp from "../../components/Otp";
import { otpCodeVerify } from "../../redux/actions";
import { toast } from "react-toastify";

function OtpPage() {
  const { isLoading, success, error } = useSelector((state) => state.otp);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpVerify = (otpCode, email) => {
    dispatch(otpCodeVerify(otpCode, email));
  };

  useEffect(() => {
    if (success === true) {
      toast.success("Login successful");
      navigate("/search-rides");
    }
  }, [success]);
  return (
    <OtpContainer>
      <Otp verifyOpt={otpVerify} isLoading={isLoading} otpError={error} />
    </OtpContainer>
  );
}

export default OtpPage;
const OtpContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 6rem;
`;
