import React from "react";
import FeedbackForm from "../../components/FeedbackForm";
import PageContainer from "../../components/UI/PageContainer";
import styled from "styled-components";

const AddReview = () => {
  return (
    <Container>
      <HeaderStyled>
        <h1>Rate your experience</h1>
      </HeaderStyled>
      <FeedbackForm />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const HeaderStyled = styled.div`
  align: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  background-color: #054752d1;
  height: 9rem;
  h1 {
    font-weight: 400;
    color: white;

    margin: auto;
  }
`;

export default AddReview;
