import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { toast } from "react-toastify";
import api from "../../../services/api";
import FeedbackForm from "../../FeedbackForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewModal({
  open,
  handleClose,
  traveller,
  rideId,
  rideStatus,
  iAmDriver,
  currentUser,
}) {
  const onSubmitFeedback = async (review, rating) => {
    try {
      await api.post("feedback/", {
        reviewer: currentUser?.id,
        reviewed_user: iAmDriver ? traveller.requestedBy?.id : traveller.id,
        ride_id: rideId,
        status: "pending approval",
        review_text: review,
        rating: rating,
      });
      toast.success("Review submitted successfully");
      handleClose();
    } catch (e) {
      if (e.response.data === "Record already exists") {
        toast.error("Already reviewed this user");
      }
      handleClose();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary">Share your experience</DialogTitle>
        <FeedbackForm
          traveller={traveller}
          iAmDriver={iAmDriver}
          rideId={rideId}
          rideStatus={rideStatus}
          onSubmitFeedback={onSubmitFeedback}
          doItLater={() => handleClose()}
        />
      </Dialog>
    </div>
  );
}
