import React from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const data = {
  labels,
  datasets: [
    {
      label: 'Current Rides',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgb(5, 71, 82)',
    },
    {
      label: 'Previous Rides',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const HistogramChart = () => {
  return (
    <ChartWrapper>
      <h2 className="chart-title">Rides Statistics</h2>
      <Bar data={data} options={options} />
    </ChartWrapper>
  );
};

export default HistogramChart;

const ChartWrapper = styled.div`
  padding: 1rem;
  height: auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .chart-title {
    text-align: left;
    margin-bottom: 10px;
    color: rgb(61, 72, 93);
    font-weight: 500;
    font-family: serif;
  }
`;
