import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AdminRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");
  const decoded = jwt_decode(token);
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  if (!decoded?.isAdmin) {
    return <Navigate to="/un-authorized" replace />;
  }

  return children;
};

export default AdminRoute;
