import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from '../../assets/colors';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: auto;
  padding: 1rem;
  text-align: justify;
`;

export const Header = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${COLORS.primaryColor};
`;

export const Description = styled.p`
  font-size: 1.2rem;
  text-align: center;
  color: #525454;

`;

export const Button = styled(Link)`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  border-radius: 5rem;
  background-color: #106b7a;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  transition: background-color 0.2s ease-in-out;
  animation: ${props => props.animation ? keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  ` : 'none'} 1s;
  &:hover {
    background-color: ${COLORS.primaryColor};
  }
`;
