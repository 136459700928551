import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import carD from "../../assets/images/cars/silcab_.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  // grow: {
  //   flexGrow: 1,
  // },
  ServiceSectionStyles: {
    minHeight: "40vh",
    marginTop: "1.5rem",
  },

  BoxStyles: {
    backgroundImage: `url(${carD})`,
    backgroundPosition: "center ",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "300px",
    borderRadius: theme.spacing(1),
  },
  ButtonStyles: {
    borderRadius: "5xpx",
    maxWidth: "150px",
    marginTop: "5px",
  },
  rightBox: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    height: "100%",
  },
  btn: {
    textDecoration: "none",
  },
}));

const ServiceSection = () => {
  const classes = useStyles();

  return (
    <Container
      className={classes.ServiceSectionStyles}
      justify="center"
      alignItems="center"
      maxWidth="md"
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box className={classes.BoxStyles}></Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box p={4} className={classes.rightBox}>
            <Typography
              variant="h6"
              align="left"
              color="primary"
              alignItems="center"
            >
              Driving in your car soon?
            </Typography>
            <Typography
              variant="subtitle"
              align="left"
              alignItems="center"
              color="secondary"
            >
              Let's make this your least expensive journey ever by offering the
              empty seats of your car.Females can also travel in female only
              rides.
            </Typography>
            <Link className={classes.btn} to="/publish">
              <Button
                variant="contained"
                color="primary"
                className={classes.ButtonStyles}
              >
                Offer a ride
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServiceSection;
