import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import HeroSection from "../HeroSection";
import ServicesSection from "../ServicesSection";
import PopularRides from "../PopularRides";
import ServiceSectionDetails from "../ServiceSectionDetails";
import SilkCabsFooter from "../SilkCabsFooter";
import HelpSection from "../HelpSection";
import HowItsWork from "../../pages/HowItWork";
import ReactGA from "react-ga4";
import HowItWorksForDriver from "../../pages/HowItWorkforDriver";
import styled from "styled-components";
import bgVideo from "../../assets/videos/bg.mp4";
import mobile from "../../assets/images/mobile.png";
const Home = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
  }, []);
  return (
    <Mainwrapper>
      <Container disableGutters maxWidth="xl">
        <Grid item>
          <HeroSection />
        </Grid>
        <Grid item>
          <ServicesSection />
        </Grid>
        <Grid item>
          <PopularRides />
        </Grid>
        <Grid item>
          <ServiceSectionDetails />
        </Grid>
        <Grid item>
          <ComingSoonSection />
        </Grid>
      </Container>

      <Wrapper>
        <VideoContainer>
          <Video autoPlay loop muted>
            <source src={bgVideo} type="video/mp4" />
          </Video>
          <DarkOverlay />
        </VideoContainer>

        <ContentWrapper>
          <div className="wrapper-item">
            <HowItWorksForDriver />
            <HowItsWork />
          </div>
        </ContentWrapper>
      </Wrapper>
      <HelpSection />

      <SilkCabsFooter />
    </Mainwrapper>
  );
};
export default Home;

const ComingSoonSection = () => {
  return (
    <ContainerStyled>
      <Title>Mobile App Coming Soon</Title>
      <Subtitle>
        We're working hard to bring you the best experience on mobile!
      </Subtitle>
      <Image
        src={mobile || "https://via.placeholder.com/300"}
        alt="Coming Soon"
      />
      <Message>Stay tuned for updates.</Message>
    </ContainerStyled>
  );
};

// Styled Components
const ContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const Message = styled.p`
  font-size: 1rem;
  color: #888;
  text-align: center;
`;

const Mainwrapper = styled.div``;
const Wrapper = styled.div`
  position: relative;
  width: auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  top: 0;
  left: 0;
  max-width: 100%;
`;
const MobileAppSection = styled.div`
  height: 400px;
  width: 100%;
  background: whitesmoke;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;
const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0.5, 0, 0.7);
`;
const ContentWrapper = styled.div`
  position: relative;
`;
const Playstore = styled.div`
  padding-left: 10rem;
  background-color: #f8f8f8;
  border: 1px solid red;
  width: 100%;
  height: 524px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5rem 0 0;

  @media (max-width: 768px) {
    display: flex;
    padding: 2rem;
  }
`;
