import * as types from '../constants'
const initialState = {
    isLoading: false
}

const passwordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PASSWORDRESET_REQUEST:
            return { ...state, isLoading: true }
            break;
        default:
            return state
            break;
    }
}

export default passwordResetReducer;