import * as types from "../constants";
const initialState = {
  isAuthenticated: false,
  currentUser: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
      };
    case types.LOGOUT_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: {},
      };
    case types.SET_USER_AVATAR:
      return {
        ...state, currentUser :{ ...state.currentUser, avatar_url: action.avatar_url}
      }
    default:
      return state;
  }
};

export default authReducer;
