import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  AccessTime,
  AccessTime as AccessTimeIcon,
  TimeToLeave,
} from "@material-ui/icons";

const TimePicker = ({ required, label, time, handleTimeChange, error }) => {
  const KeyboardButtonProps = {
    keyboardIcon: <AccessTime />,
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        ampm={true}
        label={label}
        value={time}
        onChange={handleTimeChange}
        minutesStep={5}
        okLabel="Select"
        variant="dialog"
        inputVariant="outlined"
        required={required}
        fullWidth
        KeyboardButtonProps={KeyboardButtonProps}
        error={error}
        helperText={error ? "Select a valid time" : ""}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
