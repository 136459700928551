import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import RefundItem from "./RefundItem";
import { Link } from "react-router-dom";
import useRefunds from "../../hooks/useRefunds";

const RefundRecord = () => {
  const { data, loading } = useRefunds([]);

  return (
    <Wrapper>
      <div className="header-section">
        <h3>Your refunds</h3>
      </div>
      <div className="content-section">
        <p>
          Your refund details will appear here. You'll get refund according to
          &nbsp;{" "}
          <Link to="/gosawari-refund-policy">
            <strong> GoSawari's</strong>
          </Link>
          &nbsp; policy within <strong>24 hours</strong>.
        </p>
      </div>
      {loading && <p>Loading...</p>}
      <div className="card-section">
        {!loading &&
          data?.length > 0 &&
          data.map((card, index) => (
            <RefundItem
              key={index}
              rideId={card.Ride.id}
              leavingFrom={card.Ride.leaving_from}
              headingTo={card.Ride.heading_to}
              amount={card.refund_amount}
              date={card.cancelled_at}
              status={card.refund_status}
              cancel_reason={card.cancel_reason}
            />
          ))}
      </div>
    </Wrapper>
  );
};

export default RefundRecord;

const Wrapper = styled.div`
  width: 100%;
  max-width: 864px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  .header-section {
    h3 {
      font-size: xx-large;
    }
  }
  .content-section {
    p {
      color: ${COLORS.secondaryTextColor};
      a {
        color: #054752;
        text-decoration: none;
      }
    }
  }
  .card-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .card-one {
      background: #f7f7f9;
      min-height: 7rem;
      padding: 1rem 2rem;
      justify-content: center;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .destination {
        display: flex;
        justify-content: space-between;
        a {
          text-decoration: none;
          color: black;
        }
        p {
          font-weight: 700;
        }
        .text-icon {
          align-items: center;
          display: flex;
          gap: 0.5rem;
          span {
            font-weight: bold;
            svg {
              margin-top: 0.3rem;
            }
          }
        }
      }
      .date {
        display: flex;
        justify-content: space-between;
        .status {
          color: green;
          text-transform: capitalize;
          font-size: small;
        }
        .pending {
          color: orange;
          text-transform: capitalize;
          font-size: small;
        }
      }
      .cancel-reason {
        color: ${COLORS.secondaryTextColor};
      }
    }
  }
`;
